.carousel {
    &.slick-dotted,
    &.slick-slider {
        margin-bottom: 10px;
    }

    &.hide {
        display: none;
    }

    .slick-list {
        width: calc(89% - 83px);
        margin: auto;
    }

    .slick-autoplay-toggle-button {
        top: 25px;
        left: 25px;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: $z-index-upper;
        background: none;
        border: none;
        padding: 5px;
        border-radius: 50%;

        .slick-pause-icon,
        .slick-play-icon {
            color: $color-text-black;

            &::before {
                position: absolute;
                top: 16px;
                left: 16px;
            }
        }

        &:focus {
            background-color: $color-background-white;
        }
    }

    .slick-arrow {
        min-height: initial;

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
        }
    }

    &.circle {
        .slick-arrow {
            height: 42px;
            width: 42px;
            border-radius: 50%;

            &:focus {
                .slick-prev-icon,
                .slick-next-icon {
                    margin: 0;
                }
            }

            .slick-prev-icon::before,
            .slick-next-icon::before {
                content: '';
                display: block;
                height: 42px;
                width: 42px;
                background-color: $color-text-grey;
            }

            .slick-prev-icon::before {
                @include mask-image('../../public/images/leftArrow.svg');
            }

            .slick-next-icon::before {
                @include mask-image('../../public/images/rightArrow.svg');
            }
        }
    }

    &.straight {
        .slick-arrow {
            height: 200px;
            width: 45px;

            .slick-prev-icon::before,
            .slick-next-icon::before {
                content: '';
                display: block;
                height: 200px;
                width: 45px;
                background-color: $color-text-grey;
            }

            .slick-prev-icon::before {
                @include mask-image('../../public/images/iconLeft.png');
            }

            .slick-next-icon::before {
                @include mask-image('../../public/images/iconRight.png');
            }
        }
    }

    .slick-dots {
        position: initial;
        height: 20px;

        li {
            &.slick-active {
                button,
                button:focus {
                    width: 18px;
                    height: 18px;

                    .slick-dot-icon::before {
                        width: 18px;
                        margin: 0 0 -1px 0px;
                    }
                }
            }

            button,
            button:focus {
                margin: 0;
                padding: 0;
                width: 12px;
                height: 12px;
                min-height: 12px;
                display: block;
                border-radius: 50%;

                .slick-dot-icon::before {
                    position: initial;
                    display: inline-block;
                    width: 12px;
                    color: $color-text-black;
                }
            }

            button:focus {
                background-color: $color-text-black;
            }
        }
    }
}

.carousel-header {
    font-size: 26px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--theme-color-3, $color-text-teal);
    padding: 0 68px;
    margin-bottom: 30px;
}

.product-carousel-item {
    color: $color-text-black;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:hover {
        text-decoration: none;
        cursor: pointer;
        .product-carousel-name {
            background: $color-background-blue;
            color: var(--theme-color-2, $color-text-teal2);
            text-shadow: 0 1px 0 $color-text-white;
            border-radius: 3px;
            text-decoration: none;
        }
    }

    &.partner {
        .product-carousel-name {
            background: transparent;
            color: black;
        }
    }

    .product-carousel-image-wrapper {
        background-color: $color-background-white;
        margin-bottom: 10px;
        width: 100%;
        max-height: 170px;
        min-height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-divider-grey;
        overflow: hidden;

        .product-carousel-image {
            @include media($breakpoint-large) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            max-height: 166px;
            text-align: center;
            overflow: hidden;
            opacity: 0.9;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: 2px solid transparent;
        }
    }

    .product-carousel-name {
        color: var(--theme-color-2, $color-text-black);
        line-height: 24px;
        font-size: 14px;
        font-weight: 700;
        display: -webkit-inline-box;
        padding: 0 4px 2px;
        height: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .product-carousel-details {
        font-size: 14px;
        color: $color-text-grey;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-transform: lowercase;
        padding: 0 4px;
        margin-bottom: 10px;
        display: flex;

        .product-carousel-details-wrapper {
            padding-left: 5px;

            .product-carousel-details-strikethrough {
                color: $color-text-black;
                font-size: 14px;
                text-decoration: line-through;
            }
            .product-carousel-details-price {
                color: var(--theme-color-3, $color-text-red);
                padding-left: 2px;
                font-size: 19px;
                font-weight: bold;
            }

            .product-details-price-text {
                display: none;
            }
        }
    }
}

.customer-review-item {
    vertical-align: top;
    display: inline-block;
    padding: 0 10px;

    .customer-review-rating {
        display: flex;
        flex-direction: row;

        .review-start {
            display: inline-block;
            vertical-align: text-bottom;
            background: url(../../public/images/ratingsStarEmpty.png) no-repeat;
            background-size: contain;
            height: 16px;
            width: 16px;

            &.fill {
                background: url(../../public/images/ratingsStarFill.png) no-repeat;
                background-size: contain;
            }
        }
    }

    .customer-review-info {
        font-size: 14px;
        font-weight: 600;
        color: $color-customer-review-info;
        margin-top: 10px;
    }

    .customer-review-name {
        padding-left: 10px;
    }

    .customer-review-text {
        color: $color-customer-review-text;
        font-size: 14px;
        margin-top: 15px;
    }
}

.customer-reviews-carousel-wrapper {
    margin-top: 20px;
    height: 220px;
}

.product-carousel-wrapper {
    &:has(.product-carousel-details-strikethrough) {
        padding-bottom: 20px;
    }
}

.product-carousel-wrapper,
.full-product-carousel {
    background: inherit;
}

.footer-product-carousel {
    background-color: $color-background-white;
    padding-top: 5px;
}
