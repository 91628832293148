.search-result-page {
    background-color: $color-background-white;
    overflow: auto;
    .active {
        background-color: $color-background-dark-grey;
    }
    .header-search-input {
        height: 34px;
        width: 80%;
        border: 0px;
        padding-left: 10px;
        font-size: 14px;
    }
    .header-search-button {
        height: 34px;
        width: 34px;
        min-height: 34px;
        vertical-align: middle;
        background-color: $color-button-background;
        border: 0px;
        color: $color-text-white;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        padding: 3px 6px 3px 6px;
    }

    margin-top: 20px;
}

.category-search-results {
    background-color: $color-background-white;
    margin-top: 0px;
    .active {
        background-color: $color-background-dark-grey;
    }
    .header-search-input {
        height: 34px;
        width: 80%;
        padding-left: 10px;
        font-size: 14px;
        border: 2px solid;
        border-radius: 4px 0 0 4px;
        min-height: 34px;
        margin: 0;
        border-color: var(--theme-color-3, $color-button-background);

        @include media($breakpoint-small) {
            width: calc(100% - 34px);
        }
    }
    .header-search-button {
        height: 100%;
        width: 34px;
        min-height: 34px;
        vertical-align: middle;
        background-color: transparent;
        border: 0px;
        color: $color-text-white;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        padding: 3px 6px 3px 6px;
    }
    .search-button-img {
        padding: 3px;
        height: 97%;
    }
}

.search-result-header {
    @include row();
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: $color-background-light-grey4;
    color: $color-text-darker-grey;
    font-size: 10px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    @include media($breakpoint-small) {
        display: block;
    }

    @include media($breakpoint-large) {
        &::after {
            display: none;
        }
    }

    .search-result-header-col {
        @include media($breakpoint-large) {
            &:last-child,
            &:nth-child(2) {
                width: 22% !important;
            }
        }

        @include media($breakpoint-small) {
            @include span-columns(30);
            margin-bottom: 10px;
        }

        .search-result-header-subtitle {
            font-size: 12px;
            color: $color-text-darker-grey;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    .search-select {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 14px;
        color: $color-lightish-grey2;
        line-height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;
        border-radius: 4px;
        border: 1px solid $color-lightish-grey;
        height: 40px;
        width: 100%;
        .Select__control {
            border: 0px;
        }
    }
}

.mobile-search-select {
    @include media($breakpoint-large) {
        display: none;
    }
    height: 34px;
    width: 100%;
    border: 1px solid $color-text-grey;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: $color-text-grey;
    background: url(../../public/images/downarrow.svg) no-repeat right $color-text-white;
    background-size: 10% 25%;
}

.mobile-expand-filter {
    position: relative;
    @include expand-arrow-mask(var(--theme-color-2, white));
    @include media($breakpoint-large) {
        display: none;
    }
}
.search-result-fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.mobile-hide-header-col {
    @include media($breakpoint-small) {
        display: none;
    }
}

.search-result-results-wrapper {
    display: flex;
    align-items: stretch;
}

.search-result-aggregations-region {
    @include span-columns(6);
    overflow: hidden;
    padding: 10px;
    background-color: $color-background-light-grey4;
    padding-top: 4px;
    line-height: 24px;
    @include media($breakpoint-small) {
        display: none;
    }
    .aggregation-header {
        font-size: 13px;
        margin-left: 4px;
        padding-bottom: 3px;
        color: $color-text-grey;
        font-weight: bold;
    }
    .aggregation-wrapper {
        border-bottom: 6px solid $color-background-white;
        padding-top: 10px;
        padding-bottom: 20px;

        .aggregation-title {
            color: var(--theme-color-3, $color-text-teal);
            font-weight: bold;
            font-size: 16px;
        }
        .mobile-filter-options {
            padding: 0px;

            .aggregation-filter-wrapper {
                list-style-type: none;

                .aggregation-key {
                    display: block;
                    padding: 2px 4px;
                    border-radius: 3px;
                    margin: 0;
                    font-size: 14px;
                    color: $color-text-black;
                    cursor: pointer;
                    .aggregation-count {
                        color: var(--theme-color-3, $color-text-teal);
                        padding-left: 4px;
                        font-weight: bold;
                        font-size: 13px;
                    }
                    &:hover {
                        background-color: $color-background-blue;
                        color: $color-text-teal;
                        text-decoration: none;
                    }
                    &.partner {
                        &:hover {
                            background-color: transparent;
                            color: black;
                        }
                    }
                }
            }
        }
    }
}

.mobile-search-filters {
    display: none;
    @include media($breakpoint-small) {
        display: block;
        .search-result-aggregations-region {
            display: block;
            padding: 0px;
            @include span-columns(30);
            .aggregation-wrapper {
                border-bottom: 1px solid $color-text-light-grey;
                padding-top: 5px;
                padding-bottom: 5px;

                .aggregation-title {
                    @include display(flex);
                    @include justify-content(space-between);
                    @include align-items(center);
                    font-size: 16px;
                    line-height: 21px;

                    @include media($breakpoint-small) {
                        line-height: 30px;
                    }
                }

                .aggregation-filter-wrapper {
                    list-style-type: none;

                    .aggregation-key {
                        color: var(--theme-color-3, $color-text-teal);
                        line-height: 21px;
                        font-weight: 400;
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
            }

            .aggregation-current-wrapper {
                padding: 0px;
                border-bottom: none;

                .aggregation-current-item-name,
                .aggregation-current-item-value {
                    font-size: 14px;
                }
            }
        }

        .mobile-expand-filter {
            width: 15px;
            height: 15px;
        }

        .mobile-filter-options {
            display: none;
            padding: 0px;

            &.expanded {
                display: block;
            }
        }
    }
}
.search-result-results-list {
    @include media($breakpoint-large) {
        @include span-columns(24);
        display: flex;
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
        display: flex;
    }
    background-color: $color-background-white;
    flex-wrap: wrap;
    padding: 0;
    height: fit-content;
}
.search-result-item {
    position: relative;
    height: auto;

    @include media($breakpoint-large) {
        @include span-columns(8 of 32);
        @include omega(4n);
        display: inline-block;
    }
    @include media($breakpoint-small) {
        @include span-columns(15);
        display: inline-block;
        margin: 0px;
    }
    @include media($breakpoint-header-small) {
        @include span-columns(15);
        @include omega();
    }

    a {
        display: inline-block;
        height: 100%;
    }

    .search-result-border-wrapper {
        margin-left: 10px;
        margin-right: 10px;
        border-bottom: 1px solid $color-divider-grey;
        height: 100%;
        padding-top: 10px;
        display: flex;
        flex-direction: column;

        .result-item-text-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &.partner {
        &:hover {
            .result-item-image-wrapper {
                background: transparent;
            }
        }
    }

    &:hover {
        .result-item-image-wrapper {
            border-radius: 3px;
            .result-list-image {
                opacity: 0.88;
            }
        }
    }
}

.search-result-item:nth-last-child(-n + 4) {
    .search-result-border-wrapper {
        @include media($breakpoint-header-large) {
            border-bottom: 0px;
        }
    }
}
.search-result-item:nth-last-child(-n + 2) {
    .search-result-border-wrapper {
        @include media($breakpoint-header-small) {
            border-bottom: 0px;
        }
        @include media($breakpoint-small) {
            border-bottom: 0px;
        }
    }
}

.search-result-list-item {
    position: relative;
    overflow: auto;
    padding-bottom: 20px;
    border-bottom: solid 1px $color-divider-grey;
    .result-item-image-wrapper {
        @include span-columns(6);
        .result-list-image {
            height: 100px;
            width: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-top: 10px;
        }
    }
    .result-item-text-wrapper {
        @include span-columns(8);
    }
    .result-list-item-middle {
        @include span-columns(16);
        padding-top: 10px;

        .result-item-name {
            font-size: 16px;
            color: $color-text-black;
            font-weight: bold;
            display: block;
        }
        .result-item-sku {
            color: $color-text-grey;
            font-size: 12px;
            display: block;
            .green {
                color: $color-text-green;
            }
        }
        .result-item-description {
            font-size: 14px;
            line-height: 21px;
            color: $color-text-black;
            padding-top: 10px;
        }
    }
}
.result-item-image-wrapper {
    text-align: center;

    .result-list-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result-list-image {
        height: 190px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}
.result-item-text-wrapper {
    padding: 5px 10px;
    margin-top: 5px;
    .result-item-name {
        font-size: 14px;
        padding-bottom: 2px;
        font-weight: 600;
        line-height: 21px;
        color: $color-text-black;
        display: block;
    }
    .result-item-name:hover {
        @include media($breakpoint-large) {
            text-decoration: none;
            color: $color-text-black;
        }
    }
    .result-item-sku {
        color: $color-text-grey;
        font-size: 12px;
        padding-top: 4px;
        letter-spacing: 0.03em;
        line-height: 16px;
        display: block;
        .green {
            color: $color-text-green;
        }
        &:hover {
            color: $color-text-grey;
        }
    }
    .result-item-sku:hover {
        @include media($breakpoint-large) {
            text-decoration: none;
            color: $color-highlight-hover-orange;
        }
    }
    .result-item-row {
        color: $color-text-grey;
        display: inline-block;
        width: 100%;
        line-height: 24px;
        padding: 0;
        letter-spacing: -0.02em;
        font-size: 14px;

        .strikethrough {
            font-size: 16px;
            color: $color-text-black;
            text-decoration: line-through;
        }
        .red {
            padding-left: 2px;
            font-size: 19px;
            font-weight: bold;
            color: var(--theme-color-3, $color-text-red);
        }
        .red:hover {
            text-decoration: none;
        }
        .black {
            color: $color-text-black;
            text-transform: lowercase;
            font-size: 15px;
            padding-left: 2px;
        }
    }

    .result-item-row-rating {
        display: inline-block;
        height: 25px;
        padding-top: 4px;

        @include media($breakpoint-large) {
            height: 18px;
            padding-top: 2px;
        }

        @include media($breakpoint-smaller) {
            display: block;
        }
    }

    .result-item-row-review-count {
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        margin-left: 8px;

        @include media($breakpoint-large) {
            height: 18px;
            padding-top: 1px;
        }

        @include media($breakpoint-smaller) {
            display: block;
            margin-left: 0px;
        }
    }

    .vertical > .result-item-row-rating,
    .vertical > .result-item-row-review-count {
        display: block;
        margin-left: 0px;
    }

    .result-item-value {
        color: $color-text-black;
        width: 40%;
        display: inline-block;
        text-align: right;
        font-size: 14px;
    }
    .result-item-bold-value {
        color: $color-text-red;
        font-weight: bold;
        width: 40%;
        display: inline-block;
        text-align: right;
        font-size: 18px;
    }
}

.search-result-footer {
    background-color: $color-background-light-grey4;
    margin-bottom: 20px;
    margin-top: 6px;
    padding: 10px;
    .search-result-inline-pagerow {
        display: inline-block;
    }
    .search-result-footer-pagerow {
        width: 364px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include media($breakpoint-small) {
            width: auto;
        }
    }
    .search-result-footer-subtitle {
        padding-right: 10px;
        display: inline-block;
        font-size: 12px;
        color: $color-text-grey;
        font-weight: bold;

        @include media($breakpoint-small) {
            margin-bottom: 10px;
            min-width: 240px;
        }
    }
}

.orange-button {
    background-color: var(--theme-color-3, $color-button-background) !important;
    color: $color-text-white;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    display: block;

    .site-phone {
        color: $color-text-white;
        a {
            color: $color-text-white;
        }
    }
}

.orange-button:hover {
    @include media($breakpoint-large) {
        background-color: var(--theme-color-1, $color-highlight-orange);
    }
}

.orange-button {
    &:hover {
        text-decoration: none;
    }
}

.aggregation-current-item {
    margin: 0;
    padding: 5px 5px 0px 5px;
    border-radius: 3px;
    line-height: 21px;
    &:hover {
        background: $color-text-white;
    }
}

.aggregation-remove {
    margin-right: 8px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background: var(--theme-color-3, #9ddbe2);
    position: relative;
    overflow: hidden;
    @include checkmark-mask(var(--theme-color-2, $color-text-teal));
}

.aggregation-current-item-name {
    vertical-align: top;
    color: $color-text-grey;
}

.aggregation-current-item-value {
    vertical-align: top;
    color: var(--wl-black, $color-text-teal2);
}

.aggregation-current-wrapper {
    border-bottom: 6px solid $color-background-white;
    padding-bottom: 20px;
    padding-top: 10px;
}

$banner-height: 34px;
$banner-width: 60px;

.result-item-banner {
    @include transform(rotate(90deg));
    position: absolute;
    top: 11px;
    opacity: 0.7;
    width: $banner-width;
    height: $banner-height;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);

    &:hover {
        text-decoration: none;
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0px;
        border: $banner-height / 2 solid;
        z-index: -1;
        right: -7px;
        border-left-width: 0px;
        border-right-width: 7px;
        border-color: inherit;
        border-right-color: transparent;
    }
}

.result-item-banner-text {
    text-align: left;
    font-size: 8px;
    font-family: 'Open Sans', sans-serif;
    color: $color-text-white;
    text-transform: uppercase;
    padding-left: 5px;
}

.aggregation-show-more {
    cursor: pointer;
}

.search-result-none-found {
    height: 100px;
    text-align: center;
    margin-top: 100px;
}

.search-result-product-count {
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    color: var(--theme-color-3, $color-text-light-teal);
    margin-left: 0px;
    padding-left: 8px;
    font-weight: normal;

    .search-result-category-link {
        background-color: var(--theme-color-1, $color-background-blue);
        border-radius: 4px;
        color: var(--theme-color-2, $color-text-light-teal);
        display: inline-block;
        margin-left: 12px;
        margin-right: -4px;
        margin-top: -7px;
        letter-spacing: 0;
        padding: 5px 10px;
        font-size: 13px;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background-color: var(--theme-color-1-fade, $color-text-light-teal);
            color: $color-text-white;
            text-decoration: none;
        }
    }
}

@include media($breakpoint-small) {
    .search-result-results-list {
        @include display(flex);
        @include flex-direction(column);
    }

    .search-result-item {
        width: 100%;
        height: auto;
    }

    .search-result-border-wrapper {
        @include display(flex);
        @include align-items(center);
        @include justify-content(space-between);

        > a {
            @include span-columns(10);
        }

        padding-bottom: 10px;

        .result-item-image-wrapper {
            .result-list-image {
                height: 120px;
            }
        }
    }

    .result-item-text-wrapper {
        @include span-columns(20);
    }

    .result-item-name {
        width: 100%;
    }

    .search-result-list-item {
        .row {
            @include display(flex);
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(center);
            @include outer-container();
            .result-item-image-wrapper {
                @include span-columns(10);
                margin-right: 0px;
            }
            .result-list-item-mobile-info {
                @include span-columns(20);
                .result-list-item-middle {
                    width: 100%;
                    display: inline-block;
                    padding-left: 10px;
                    .result-item-description {
                        display: none;
                    }
                }
                .result-item-text-wrapper {
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    }
}
