.partner {
    #bc-frame {
        border-color: var(--theme-color-1);

        .bc-frame-header,
        .bc-frame-header-landscape {
            background: var(--theme-color-1);
            &:after {
                border-top-color: var(--theme-color-1, #008393);
            }
        }
    }

    #bc-prechat-form-container {
        .bc-help-message {
            color: var(--theme-color-1);
        }
    }
}

.extra-chat-wrapper {
    display: none;

    @media only screen and (max-width: 767px) {
        & {
            position: fixed;
            z-index: $z-index-upperest;
            bottom: 10px;
            left: 0;
            right: 0;
            top: auto;
            display: block;
        }
    }

    a.extra-livechat {
        width: calc(100% - 110px);
        margin-left: 20px;
        color: #ffffff;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 39px;
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .bc-minimize-container,
    .bcFloat {
        display: none !important;
    }
}
