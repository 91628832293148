.product-summary-header {
    position: fixed;
    @include media($breakpoint-header-large) {
        top: 120px;
    }
    @include media($breakpoint-header-small) {
        top: 138px;
    }
    z-index: 100;
    width: 100%;
    background-color: $color-background-white;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-header-divider;
    display: none;
    .product-header-wrapper {
        @include span-columns(22);
        .product-header-image {
            display: inline-block;
            height: 50px;
        }
        .product-header-content-wrapper {
            padding-left: 10px;
            display: inline-block;
        }
        .product-header-item {
            display: inline;
            font-size: 14px;
            color: $color-text-grey;
            .product-header-price {
                font-size: 14px;
                color: $color-text-maroon;
                font-weight: bold;
            }
        }
    }
    .product-header-buy-button {
        @include span-columns(4);
        padding: 8px 0px;
        border-radius: 4px;
        border: 0px;
        background-color: $color-highlight-light-orange;
        color: $color-text-white;
        margin-top: 15px;
        cursor: pointer;

        &:hover {
            background-color: $color-highlight-orange;
        }
    }
    .product-header-save-button {
        @include span-columns(4);
        padding: 7px 0px;
        border-radius: 4px;
        background-color: $color-background-white;
        border: 1px solid $color-divider-grey;
        margin-top: 15px;
        cursor: pointer;

        &:hover {
            border-color: $color-text-light-grey2;
            background-color: $color-divider-grey;
        }
    }
}
.product-summary-header.visible {
    @include media($breakpoint-large) {
        display: block;
    }
}

.product-page {
    padding-top: 10px;
    padding-bottom: 30px;

    .product-description-region {
        padding-left: 16px;
        @include media($breakpoint-large) {
            @include span-columns(20);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        .product-sku {
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: $color-text-grey;
            line-height: 24px;
            letter-spacing: 0.02em;
        }
        .product-description {
            font-family: 'Open Sans', sans-serif;
            line-height: 26px;
            font-size: 15px;
            margin-right: 20px;
            padding-bottom: 10px;

            a {
                color: var(--theme-color-2, $color-text-teal);
                text-decoration: none;
                border-bottom: 1px dotted var(--theme-color-2, $color-text-teal);
                font-weight: bold;

                &:hover {
                    border-bottom: 1px solid;
                    background: $color-background-light-grey2;
                }
            }
        }
    }
    .product-images-wrapper {
        margin-bottom: 15px;
        @include media($breakpoint-large) {
            @include span-columns(10);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        .product-sub-image-wrapper {
            display: inline-block;
            width: 70px;
            padding: 5px;
            border: 2px solid $color-text-white;
            margin: 0;
            text-align: center;
            &:hover {
                border: 2px solid $color-divider-grey;
                border-radius: 5px;
            }
        }
        .product-sub-image {
            height: 60px;
            cursor: pointer;
            overflow: hidden;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .product-image-border {
        border: 5px solid $color-divider-grey;
        border-radius: 4px;
        padding: 10px;
    }
    .product-image-wrapper {
        -webkit-transition: all 450ms cubic-bezier(0.42, 0, 0.58, 1);
        -moz-transition: all 450ms cubic-bezier(0.42, 0, 0.58, 1);
        -o-transition: all 450ms cubic-bezier(0.42, 0, 0.58, 1);
        transition: all 450ms cubic-bezier(0.42, 0, 0.58, 1);
        @include media($breakpoint-large) {
            height: 380px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        @include media($breakpoint-small) {
            height: 200px;
        }
        text-align: center;
        overflow: hidden;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .product-header {
        font-family: 'Open Sans', sans-serif;
        color: $color-text-teal;
        font-size: 28px;
        font-weight: normal;
        margin-bottom: 5px;

        .hide-on-mobile {
            display: inline;
            font-size: 28px;
            font-weight: normal;
        }

        .partner & {
            color: $color-text-black;
        }

        @include media($breakpoint-small) {
            font-size: 20px;
        }

        .product-header-subtext {
            padding-left: 5px;
            color: $color-text-black;
            display: inline-block;
            font-size: 14px;
        }
    }
    @include media($breakpoint-large) {
        .product-header-reviews {
            display: flex;
        }
    }
    .product-subheader {
        line-height: 24px;
        font-size: 16px;
        margin-top: 20px;
        color: var(--theme-color-3, $color-text-teal3);
        font-weight: bold;
        text-transform: uppercase;
    }

    .product-subheader-fees {
        color: var(--theme-color-3, $color-text-red);
        margin-bottom: 5px;
    }

    .product-region {
        @include row();
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .product-region.border {
        border-top: 2px solid $color-divider-grey;
    }
    .price-calculator-region {
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }

        .row {
            padding-top: 2px;
            &:first-child {
                margin-bottom: 6px;
            }
        }

        .price-calculator-label {
            @include span-columns(20);
            display: inline-block;
            padding-top: 12px;
            padding-bottom: 5px;
            margin-bottom: 5px;
            letter-spacing: 0.02em;
            color: $color-text-darkish-grey;
            font-size: 12px;
            line-height: 20px;
            height: 40px;
        }

        .price-calculator-label-warning {
            display: block;
            clear: both;
        }

        .price-calculator-select-label {
            @include span-columns(10);
            display: inline-block;
            padding-top: 12px;
            margin-bottom: 5px;
            letter-spacing: 0.02em;
            color: $color-text-darkish-grey;
            font-size: 12px;
            line-height: 20px;
            height: 40px;
        }
        .price-calculator-input-label {
            @include span-columns(20);
            display: inline-block;
            padding-top: 12px;
            margin-bottom: 5px;
            vertical-align: middle;
            letter-spacing: 0.02em;
            color: $color-text-darkish-grey;
            font-size: 12px;
            line-height: 20px;
            height: 40px;
        }
        .price-calculator-help-icon {
            min-height: 20px !important;
            width: 20px;
            height: 20px;
            margin-left: 2%;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 50%;
            border: none;
            padding: unset;

            svg {
                circle {
                    fill: var(--theme-color-3, $color-text-teal);
                }
            }
        }

        .price-calculator-select {
            @include span-columns(20);
            font-size: 14px;
            padding-bottom: 5px;
            .Select__placeholder {
                color: $color-text-black;
            }
        }

        .price-calculator-content {
            @include span-columns(10);
            font-size: 12px;
            padding-right: 16px;
            padding-top: 12px;
            height: 36px;
            margin-bottom: 5px;
            text-align: right;
            padding-bottom: 5px;
            font-size: 14px;
        }

        .price-calculator-input {
            @include span-columns(10);
            font-size: 14px;
            height: 36px;
            padding-bottom: 5px;
            margin-bottom: 5px;
            padding-top: 5px;
            text-align: right;
            outline: 0;
        }

        .price-calculator-total-wrapper {
            margin-top: 20px;
            margin-bottom: 15px;
            background-color: $color-background-light-grey4;
            padding: 0px 10px;
            padding-bottom: 2px;
            border-radius: 4px;
        }

        .price-calculator-label-total {
            @include span-columns(10);
            font-size: 18px;
            padding-top: 10px;
            font-weight: bold;
            height: 36px;
            margin-bottom: 5px;
            letter-spacing: 0.025em;
            padding-right: 6px;
        }

        .price-calculator-update {
            @include span-columns(5);
            padding-top: 5px;
            font-size: 14px;
            color: $color-highlight-light-orange;
            text-align: center;
            cursor: pointer;
        }

        .price-calculator-content-total {
            @include span-columns(20);
            display: inline-block;
            font-size: 18px;
            padding-top: 10px;
            padding-right: 6px;
            font-weight: bold;
            letter-spacing: 0.025em;
            text-align: right;
            height: 36px;
            margin-bottom: 5px;
        }
    }
    .button-region {
        text-align: center;
        @include media($breakpoint-large) {
            @include span-columns(9);
            margin-left: 50px;
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        .product-button {
            width: 90%;
            margin-right: 5%;
            margin-left: 5%;
            margin-bottom: 12px;

            color: $color-text-black;
        }
        margin-bottom: 20px;
    }
    .social-region {
        @include media($breakpoint-large) {
            @include span-columns(6);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        @include outer-container;
        .social-image {
            cursor: pointer;
            @include span-columns(6);
            height: 40px;
            vertical-align: top;
        }
    }

    .product-price-table-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(20);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
    }
    .product-pricing-alert {
        @include media($breakpoint-large) {
            @include span-columns(10);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        font-family: 'Open Sans', sans-serif;
        background-color: $color-background-orange;
        color: $color-text-white;
        text-align: center;
        padding: 10px 40px;
        border-radius: 5px;
        font-size: 18px;
        line-height: 27px;
    }
    .product-info-column {
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }

        a {
            color: $color-highlight-light-orange;
            font-weight: bold;
            :hover {
                text-decoration: underline;
            }
        }
    }
    .product-ordering-info-alert {
        font-family: 'Open Sans', sans-serif;
        background-color: $color-background-orange;
        color: $color-text-white;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        font-size: 14px;
    }
    .product-fine-print-header {
        font-size: 16px;
        font-weight: bold;
        display: inline;
    }
    .product-fine-print {
        font-size: 14px;
        line-height: 21px;
        padding-bottom: 18px;
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;

        h1 {
            font-size: 16px;
            padding-right: 5px;
            letter-spacing: -0.02em;
            font-weight: bold;
            text-transform: uppercase;
            display: inline;
        }

        .lowercase-header {
            text-transform: lowercase;
            font-weight: bold;
        }

        p:first-of-type {
            margin-top: 0px;
        }

        p a {
            text-decoration: underline;
        }

        .red {
            color: var(--theme-color-1, $color-text-red);
        }

        .free {
            font-weight: bold;
        }
        .product-option-wrapper {
            margin-bottom: 0px;
        }
    }

    .product-button {
        font-family: 'Open Sans', sans-serif;
        background-color: $color-background-white;
        border: 1px solid $color-divider-grey;
        border-radius: 5px;
        padding: 8px;
        min-height: 36px;
        font-size: 14px;
        cursor: pointer;
        display: block;
        text-align: center;
        @include media($breakpoint-small) {
            height: 40px;
            font-size: 16px;
        }
    }
    .product-button:hover {
        @include media($breakpoint-large) {
            background-color: $color-background-dark-grey;
        }

        color: $color-text-black;
        text-decoration: none;
    }
    .product-button:visited {
        color: $color-text-black;
    }
    .product-button-orange {
        font-family: 'Open Sans', sans-serif;
        background-color: var(--theme-color-3, $color-button-background);
        border: 1px solid var(--theme-color-3, $color-button-background);
        color: $color-text-white;
        border-radius: 5px;
        min-height: 36px;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 8px 20px;
        width: 100%;
        margin: 0 0 12px 0;
        font-size: 16px;
        font-weight: 600;

        @include media($breakpoint-small) {
            height: 40px;
            font-size: 16px;
        }
    }
    .product-button-orange:hover {
        @include media($breakpoint-large) {
            background-color: var(--theme-color-3-fade, $color-highlight-orange);
            border: 1px solid var(--theme-color-3-fade, $color-highlight-orange);
        }
    }
    .margin-bottom {
        margin-bottom: 18px;
        max-width: 320px;
    }
    a.product-button.margin-bottom.button {
        border-radius: 3px;
        padding: 8px 20px;
        max-width: 212px;
    }
}

.product-price-table {
    width: 100%;
    min-width: 620px;

    .pricing-table-header {
        font-size: 14px;
        letter-spacing: 0.04em;
        font-weight: normal;
        color: $color-text-grey;
        text-align: left;
        padding: 8px 5px;

        &:first-child {
            margin-left: 0;
            display: flex;
            justify-content: space-between;
        }
        span {
            margin: auto 0px;
        }
    }
    .price-table-addon-header-wrapper {
        padding-top: 30px;
    }
    .price-table-addon-header {
        width: 100%;
        background-color: var(--theme-color-3-tint, $color-background-blue);
        color: var(--theme-color-3, $color-text-teal);
        font-weight: bold;
        font-size: 16px;
        padding: 10px;
    }
    .pricing-table-header:not(:first-child) {
        text-align: center;
    }

    .pricing-table-row {
        th {
            text-align: start;
            font-weight: normal;
        }
    }

    .pricing-table-row:first-child {
        padding-left: 10px;
        margin-left: 0;
    }

    .pricing-table-row:nth-child(odd) {
        background-color: $color-background-light-grey4;
    }
    .pricing-table-row:nth-child(even) {
        background-color: $color-background-white;
    }
    .pricing-table-entry {
        position: relative;
        padding: 9px 5px;
        font-size: 14px;
        .pricing-table-strikeout {
            text-decoration: line-through;
        }
        .pricing-table-sale {
            color: $color-text-red;
            font-weight: bold;
        }
    }

    .pricing-table-entry-text {
        position: absolute;
        left: 0px;
        top: 0px;
        text-align: center;
        line-height: 34px;
    }

    .pricing-table-entry-text.free {
        text-transform: uppercase;
        color: $color-highlight-light-orange;
        font-weight: bold;
        font-size: 18px;
        padding-top: 1px;
        letter-spacing: 0.015em;
    }
    .free {
        text-transform: uppercase;
        color: $color-highlight-light-orange;
        font-weight: bold;
        font-size: 18px;
        padding-top: 1px;
        letter-spacing: 0.015em;
    }

    .pricing-table-entry:not(:first-child) {
        text-align: center;

        &.left-align {
            text-align: left;
            padding-left: 16px;
        }
    }
    .text-orange {
        color: $color-background-orange;
        font-weight: bold;
    }
}

.green-wrapper {
    margin-bottom: 80px;
    overflow: auto;
    line-height: 21px;
    .green-left-column {
        @include span-columns(10);
        padding: 3px;
    }
    .green-right-column {
        @include span-columns(20);
        padding: 3px;
    }
    > .green-left-column,
    > .green-right-column {
        @include media($breakpoint-small) {
            @include span-columns(30);
            padding: 0;

            .button {
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }

    .green-text-label {
        font-size: 14px;
        text-align: right;
        font-style: italic;
    }
    .green-text-text {
        font-size: 14px;
        color: $color-text-green;
    }
    .button {
        display: block;
        width: 100%;
        background-color: $color-background-white;
        border: 2px solid $color-divider-grey;
        margin: 5px;
        text-align: center;
        color: $color-text-black;
    }
    .button:hover {
        @include media($breakpoint-large) {
            text-decoration: none;
            background-color: $color-background-dark-grey;
        }
    }
}

.product-color-grid {
    overflow: auto;
    margin-top: 10px;
    .product-color-column {
        @include media($breakpoint-large) {
            @include span-columns(5);
            @include omega;
        }
        @include media($breakpoint-small) {
            @include span-columns(15);
            @include omega;
        }
        height: 40px;
        margin-bottom: 20px;

        .product-color-label-wrapper {
            @include calc(width, '100% - 45px');
            display: inline-block;
            vertical-align: middle;
        }
        .product-color-label {
            font-size: 13px;
            vertical-align: middle;
            letter-spacing: -0.02em;
        }
    }
    .product-color-column.narrow {
        @include media($breakpoint-large) {
            width: 25%;
            height: 50px;
            padding-top: 5px;
            padding-left: 5px;
            padding-right: 20px;
        }
    }
    .product-color-column.selectable {
        padding-top: 6px;
        border: 1px solid $color-text-white;
    }
    .product-color-column {
        &.selectable {
            &:hover {
                @include media($breakpoint-large) {
                    background-color: $color-background-teal;
                }
                border-radius: 3px;
                border: 1px solid var(--theme-color-3, $color-text-light-teal);
                .product-color-label {
                    text-shadow: 0px 2px 0px $color-text-white;
                    color: var(--theme-color-3);
                }
            }
        }
    }
    .selectable {
        cursor: pointer;
    }
}

.product-color-circle-image {
    border-radius: 50%;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    .product-circle-checkmark {
        display: none;
        margin: auto;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        vertical-align: middle;
        padding: 1px;
        border: 5px solid $color-highlight-orange;
    }
    .product-circle-checkmark.selected {
        display: block;
    }
    &.border {
        border: 1px solid $color-divider-grey;
    }
}

.product-color-circle {
    border-radius: 50%;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    .product-circle-checkmark {
        display: none;
        margin: auto;
        margin-top: 3px;
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
    .product-circle-checkmark.borderless {
        margin-top: 8px;
    }
    .product-circle-checkmark.missing-color {
        display: block;
        margin-top: 7px;
    }
    .product-circle-checkmark.selected {
        display: block;
    }
}
.product-color-circle.border {
    border: 1px solid $color-divider-grey;
}
.product-color-circle.selected {
    border: 5px solid var(--theme-color-3, $color-highlight-orange);
}
.product-color-circle.teal {
    background-color: var(--theme-color-3, $color-text-teal);
}

$banner-height: 50px;
$banner-width: 100px;

.product-image-banner {
    position: absolute;
    left: 10px;
    opacity: 0.9;
    width: $banner-width;
    height: $banner-height;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);

    &:hover {
        text-decoration: none;
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0px;
        border: $banner-height / 2 solid;
        z-index: -1;
        right: -10px;
        border-left-width: 0px;
        border-right-width: 10px;
        border-color: inherit;
        border-right-color: transparent;
    }
}

.product-image-banner-text {
    text-align: left;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: $color-text-white;
    text-transform: uppercase;
    padding-left: 5px;
}

.free-shipping-text {
    text-transform: uppercase;
    color: var(--theme-color-3, $color-highlight-light-orange);
    font-weight: bold;
    text-align: left;
}

.product-options-container {
    div {
        margin-bottom: 40px;
        line-height: 24px;
    }
    div.product-subheader {
        margin-bottom: 0;
        font-size: 20px;
    }
    div.product-subheader-fees {
        font-weight: bold;
        font-size: 16px;
    }
    div.product-fineprint {
        margin-bottom: 5px;
    }
}

.product-price-table-wrapper {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    @include media($breakpoint-small) {
        width: 100%;
    }
}

.product-description-image {
    max-width: calc(100vw - 20px);
}

.product-admin-thumbnail {
    height: 100px;
}
.product-admin-image {
    height: 200px;
}

.pricing-fine-print {
    margin-left: 0;
    margin-top: 30px;
    padding-left: 10px;
}

.product-information-tabs-container {
    margin-bottom: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.product-information-tabs-wrapper {
    margin-bottom: 45px;
    border-bottom: 2px solid $color-background-grey;

    @include media($breakpoint-small) {
        margin-bottom: 15px;
    }
}

.product-information-tabs {
    @include display(flex);

    @include media($breakpoint-small) {
        @include justify-content(space-between);
    }
}

.product-information-tab {
    width: 22%;
    background-color: var(--theme-color-2, $color-background-grey);
    border-left: 1px solid $color-background-white;
    border-right: 1px solid $color-background-white;
    border-top: 2px solid $color-background-grey;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    position: relative;

    @include media($breakpoint-header-small) {
        font-size: 14px;
    }

    @include media($breakpoint-small) {
        width: 32%;
        font-size: 12px;
        padding: 10px;
    }
}

.product-information-tab-below {
    background-color: $color-background-white;
    bottom: -2px;
    height: 2px;
    left: 0px;
    position: absolute;
    width: 100%;
}

.product-information-tab-link {
}

.related-products-wrapper {
    border-bottom: 10px solid $color-background-light-grey4;
    padding-bottom: 15px;
    margin-bottom: 30px;
    @include media($breakpoint-smaller) {
        display: none;
    }
}

.related-products {
    @include outer-container();
    margin-top: 20px;
}

.related-products-title {
    color: var(--theme-color-3, $color-text-teal);
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: normal;
}

.related-products-carousel {
    @include span-columns(20 of 30);
    @include media($breakpoint-header-small) {
        display: none;
    }

    @include media($breakpoint-small) {
        display: none;
    }

    &.mobile {
        @include media($breakpoint-header-large) {
            display: none;
        }

        @include media($breakpoint-header-small) {
            display: block;
        }
    }
}

.related-products-carousel-mobile {
    margin-bottom: 15px;
}

.mobile-product-carousel {
    @include media($breakpoint-large) {
        display: none;
    }
}

.related-products-category {
    @include media($breakpoint-large) {
        @include span-columns(9 of 30);
        @include shift(1);
    }
    @include media($breakpoint-small) {
        @include span-columns(30 of 30);
    }
    padding: 0 0 20px 0;
    border-radius: 3px;
    border: 1px solid var(--theme-color-1, $color-text-teal);
}

.related-products-category-title {
    background: var(--theme-color-1, $color-text-teal);
    color: $color-text-teal;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    padding: 12px 5px;
    text-align: center;

    @include media($breakpoint-header-small) {
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
    span {
        font-size: 18px;
        color: $color-text-white;
    }
}

.related-products-category-image {
    margin: 20px;
    text-align: center;

    img {
        width: 100%;
        max-width: 300px;
    }
}

.related-products-category-button {
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    color: var(--theme-color-2, $color-text-white);
    text-transform: uppercase;
    width: 40%;
    margin-left: 30%;

    span {
        letter-spacing: 0.02em;
    }
    .orange-button {
        background: var(--theme-color-2);
    }
}

.sub-images-wrapper {
    text-align: left;
    padding-top: 12px;
}

.Select_menu,
.price-calculator-select.is-open.is-focused,
.price-calculator-select:hover,
.product-page .price-calculator-region input.price-calculator-input:hover,
.product-page .price-calculator-region input.price-calculator-input:focus {
    -webkit-box-shadow: 0px 5px 5px 0px var(--wl-grey, rgba(50, 50, 50, 0.2));
    -moz-box-shadow: 0px 5px 5px 0px var(--wl-grey, rgba(50, 50, 50, 0.2));
    box-shadow: 0px 5px 5px 0px var(--wl-grey, rgba(50, 50, 50, 0.2));
}

.price-calculator-select.is-open.is-focused {
    border: 1px solid var(--theme-color-3, $color-text-light-teal);
    border-bottom: 0;
}

.product-page .price-calculator-region .price-calculator-select,
.product-page .price-calculator-region .price-calculator-select input,
.product-page .price-calculator-region .price-calculator-select {
    padding: 0;
    margin-bottom: 0;
}
.product-page .price-calculator-region .price-calculator-select {
    line-height: 37px;
}

.container-contact {
    margin-top: 20px;
    text-align: center;
    width: auto;

    .contact-small {
        font-size: 1.4em;
        text-align: center;
        color: $color-text-grey;
        width: 100%;

        .partner-content {
            padding: 10px 0;
            display: inline-block;
            white-space: pre;

            .partner-phone {
                font-size: 20px;
                padding-right: 12px;
                margin-right: 12px;
                line-height: 1;
                border-right: 2px solid;
                color: var(--theme-color-3);
                border-color: var(--theme-color-1);
                .ext-sign {
                    font-size: 0.7em;
                }
            }
            .partner-email {
                font-size: 14px;
                color: var(--theme-color-3);
            }
        }
    }

    .contact-phone {
        font-size: 2.5em;
        text-align: center;
        color: $color-highlight-orange;
        width: 100%;
        font-weight: bold;
    }

    &.partner {
        display: block;
        margin-top: 2px;

        .contact-small {
            font-size: 12px;
        }
    }
}
.swipe-left {
    color: $color-text-teal;
    max-width: 120px;
    display: flex;
}
.swipe-left-img {
    margin-right: 10px;
    height: auto;
    min-width: 35px;
    background-color: $color-text-teal;
    -webkit-mask: url('../../public/images/swipe-left.svg') no-repeat center;
    mask: url('../../public/images/swipe-left.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
}
