.input-with-tooltip-wrapper {
    width: fit-content;
    position: relative;
    flex: 1 1 100%;
    .input-with-tooltip {
        width: 100%;
    }

    .input-tooltip {
        position: absolute;
        top: calc(100% + 5px);
        padding: 10px 5px;
        border-radius: 5px;
        background-color: $color-background-lighter-grey;
        border: 2px solid $color-background-grey;
        z-index: $z-index-upperest;

        font-size: 10px;

        @include box-shadow();
    }
}
