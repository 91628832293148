.product-information-tabs-wrapper {
    border-color: $color-lightish-grey;
}
.product-information-tab {
    margin-right: 5px;
    border-radius: 0;
    border-top-width: 6px;
}
.product-information-tab {
    font-weight: normal;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-color: $color-text-white;
    border-bottom: none;
    background: $color-background-grey;
    @include gradient-grey;
    color: $color-text-black;
    transition: all 0.2s ease;

    &:hover {
        color: $color-text-black;
        background: $color-text-light-teal;
        @include gradient-blue;
    }
    &.active,
    &.active:hover {
        color: $color-text-teal2;
        border-color: $color-lightish-grey !important;
        border-width: 2px;
        background: $color-text-white !important;
        cursor: default;
    }

    &:focus-visible {
        outline: none;
    }
}
.partner .product-information-tab:hover {
    @include gradient-grey;
}

.product-information-tab-heading,
.product-information-tab-button {
    width: 100%;
    height: 100%;
}

.product-information-tab-heading {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-weight: 400;

    .product-information-tab-button {
        border: none;
        background: none;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 20px;
        font-family: 'Open Sans', sans-serif;
        position: relative;
        text-align: center;
    }
}
