.container {
    @include outer-container;
    height: 100%;
    margin: auto;
    position: relative;
    background: inherit;
    width: 98%;
    max-width: 93.75rem;
}

.breadcrumb-list {
    padding: 0px;
    display: inline-block;
    margin-left: -4px;
    margin-top: 7px;
    margin-bottom: 0px;
    @include media($breakpoint-small) {
        display: none;
    }
}
.breadcrumb-list-item {
    display: inline-block;
}
.breadcrumb {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    display: inline-block;
    color: var(--theme-color-3, $color-text-grey);
    padding-right: 5px;
}
div.breadcrumb {
    padding: 0 4px;
}
a.breadcrumb {
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 13px;
}
@include media($breakpoint-large) {
    a.breadcrumb:hover {
        text-decoration: none;
        background: var(--theme-color-3-tint, $color-background-blue);
        color: var(--theme-color-3, $color-text-teal);
    }
}

.recent-items-wrapper {
    @include media($breakpoint-large) {
        position: absolute;
        right: 0px;
        display: inline-block;
        margin-right: 10px;
        z-index: $z-index-up;
        .recent-items-button {
            float: right;
            cursor: pointer;
            width: 216px;
            z-index: 2;
            border: 1px solid $color-lightish-grey;
            background: $color-text-white;
            color: $color-text-grey;
            text-align: center;
            padding: 10px;
            margin-top: 7px;
            font-size: 13px;
            @include media($breakpoint-large) {
                &:hover {
                    border-bottom: 0;
                }
            }
        }
        .recent-items-downarrow {
            width: 16px;
            margin-left: 5px;
        }
        .recent-items-button.open {
            position: relative;
            background-color: $color-background-white;
            border-bottom: none;
        }
        .modal-recent-items {
            background-color: $color-background-white;
            border: 1px solid $color-lightish-grey;
            margin-top: 45px;
            -webkit-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
            box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            position: relative;
            .modal-recent-item {
                padding: 6px 10px;
                cursor: pointer;
                display: block;
                color: $color-text-black;
            }
            .modal-recent-item:hover {
                background-color: var(--wl-white, $color-background-teal);
                color: var(--theme-color-2, $color-text-teal);

                .partner & {
                    color: $color-text-black;
                    font-weight: bold;
                }
            }
            .modal-recent-item:nth-child(n + 2) {
                border-top: 1px solid $color-divider-grey;
            }
            .modal-recent-image-wrapper {
                width: 40px;
                height: 40px;
                display: flex;
                text-align: center;
                float: left;
                justify-content: center;
                align-items: center;
                .modal-recent-image {
                    max-height: 40px;
                    max-width: 40px;
                }
            }
            .modal-recent-name {
                @include calc(width, '100% - 40px');
                display: inline-block;
                padding-left: 10px;
                font-size: 14px;
                font-family: 'Open Sans', sans-serif;
                vertical-align: top;
                line-height: 40px;
            }
        }
    }

    @include media($breakpoint-small) {
        .recent-items-button {
            display: inline-block;
            padding: 8px;
            border: 2px solid $color-divider-grey;
            background-color: $color-background-grey;
            cursor: pointer;
            font-size: 14px;
            max-width: 320px;
            width: 100%;
            z-index: 2;
        }
        .recent-items-button.open {
            position: relative;
            background-color: $color-background-white;
            border-bottom: none;
        }
        .recent-items-downarrow {
            width: 16px;
            margin-left: 5px;
            float: right;
            margin-top: 5px;
        }
        .modal-recent-items {
            width: 100%;
            background-color: $color-background-white;
            // margin-top: 32px;
            z-index: 1;
            position: relative;
            border: 2px solid $color-divider-grey;
            display: inline-block;
            .modal-recent-item {
                padding: 6px 10px;
                cursor: pointer;
                display: block;
                color: $color-text-black;

                @include display(flex);
                @include justify-content(space-between);
                @include align-items(center);
            }
            .modal-recent-item:hover {
                background-color: var(--theme-color-2-fade, $color-background-teal);
                color: var(--theme-color-2, $color-text-teal);

                .partner & {
                    color: $color-text-black;
                    font-weight: bold;
                }
            }
            .modal-recent-item:nth-child(n + 2) {
                border-top: 1px solid $color-divider-grey;
            }
            .modal-recent-image-wrapper {
                width: 40px;
                height: 40px;
                display: flex;
                text-align: center;
                float: left;
                justify-content: center;
                align-items: center;
                .modal-recent-image {
                    max-height: 40px;
                    max-width: 40px;
                }
            }
            .modal-recent-name {
                @include calc(width, '100% - 40px');
                display: inline-block;
                padding-left: 10px;
                font-size: 14px;
                font-family: 'Open Sans', sans-serif;
                vertical-align: top;
                // line-height: 40px;
            }
        }
    }
}

.inline-sidearrow {
    padding-left: 10px;
    display: inline-block;
    height: 12px;
    padding-bottom: 2px;
    vertical-align: middle;
}

.button {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    border-radius: 4px;
    padding: 8px 10px;
    cursor: pointer;
    text-decoration: none;
}

.button-spinner {
    display: inline-block;
    height: 21px;
    width: 21px;
    margin-left: 5px;

    .sk-fading-circle {
        height: 21px;
        width: 21px;
    }
    .sk-fading-circle .sk-circle:before {
        background-color: $color-text-white;
        width: 15%;
        height: 25%;
    }
}

.estimator-spinner {
    display: inline-block;
    height: 14px;
    width: 14px;
    .sk-fading-circle {
        height: 14px;
        width: 14px;
    }
    .sk-fading-circle .sk-circle:before {
        width: 15%;
        height: 25%;
    }
}

.row {
    @include row();
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin-right: 10px;
}

.shipping-checkbox-wrapper {
    border: none;

    .shipping-checkbox-option {
        display: inline-block;
        &:hover {
            color: $color-highlight-light-orange;
        }
    }
    .shipping-checkbox-option:not(:first-child) {
        margin-left: 20px;
    }

    .shipping-checkbox-label {
        display: inline-block;
        font-size: 13px;
        vertical-align: middle;
        padding-left: 5px;
        color: $color-text-grey;
        cursor: pointer;
    }
    input[type='radio'] {
        accent-color: var(--theme-color-3, $color-button-background);
        scale: (1.5);
        min-height: auto;
    }

    @include media($breakpoint-small) {
        .shipping-checkbox-option {
            width: 100%;
            margin-bottom: 5px;
            clear: both;
        }
        .shipping-checkbox-option:not(:first-child) {
            margin-left: 0px;
        }
    }
}

.lock-scroll {
    overflow: hidden;
}

.transition-initial {
    transition: initial !important;
}

@include media($breakpoint-small) {
    .hide-on-mobile {
        display: none;
    }
}
@include media($breakpoint-large) {
    .display-on-mobile {
        display: none !important;
    }
}

.display-on-desktop {
    display: none;
}

@include media($breakpoint-larger) {
    .display-on-desktop {
        display: block;
    }
    .hide-on-desktop {
        display: none !important;
    }
}
