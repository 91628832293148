.cookie-banner {
    .hide {
        display: none !important;
    }

    .cookie-settings-button {
        position: fixed;
        bottom: 50px;
        left: 50px;
        display: flex;
        background: transparent;
        z-index: $z-index-upperest;

        @include media($breakpoint-small) {
            left: 20px;
        }

        .cookie-icon {
            cursor: pointer;
            height: 40px;
            width: 40px;
            mask-image: url('../../public/images/cookies.svg');
            -webkit-mask-image: url('../../public/images/cookies.svg');
            mask-position: center;
            -webkit-mask-position: center;
            mask-size: contain;
            -webkit-mask-size: contain;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            background-color: var(--theme-color-3, $color-background-button-primary);
        }

        .cookie-icon-title {
            background-color: $color-background-grey;
            margin: auto 12px;
            font-size: 18px;
            font-weight: bold;
            color: var(--theme-color-3, $color-background-button-primary);
            padding: 2px 10px;
            border-radius: 5px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -17px;
                border: 10px solid transparent;
                border-right: 10px solid $color-background-grey;
                top: 5px;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin: auto 0px;
        width: 100%;

        .button {
            width: 200px;
            height: 50px;
            color: $color-text-white;
            border-radius: 5px;
            box-shadow: 0px 4px 10px $color-shadow;
            border: none;
            margin: 0px 30px;

            &.primary {
                background-color: var(--theme-color-3, $color-background-button-primary);
            }

            &.secondary {
                background-color: $color-background-darker-grey;
            }
        }
    }

    .close-button {
        width: 25px;
        height: 25px;
        mask-image: url(../../public/images/close.svg);
        -webkit-mask-image: url(../../public/images/close.svg);
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: cover;
        -webkit-mask-size: cover;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        background-color: $color-background-darker-grey;
        cursor: pointer;
    }

    .cookie-bottom-panel-wrapper {
        width: 100%;
        height: 140px;
        position: fixed;
        bottom: 0px;
        background-color: $color-background-light-grey2;
        z-index: $z-index-upperest;

        .cookie-bottom-panel {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            font-size: 20px;
            padding: 20px 5%;

            .close-button {
                position: absolute;
                top: 0px;
                right: 20px;
                transform: translateY(-50%);
            }

            .text-content {
                display: flex;
                justify-content: space-between;
                margin: auto 0px;

                a {
                    text-decoration: underline;
                }

                .icon {
                    margin: auto 20px;
                    min-width: 30px;
                    min-height: 30px;
                    mask-image: url('../../public/images/icon-info.svg');
                    -webkit-mask-image: url('../../public/images/icon-info.svg');
                    mask-position: center;
                    -webkit-mask-position: center;
                    mask-size: contain;
                    -webkit-mask-size: contain;
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;
                    background-color: var(--theme-color-3, $color-background-button-primary);
                }
            }

            .button-container {
                width: 500px;
            }
        }
    }

    .cookie-details-panel-wrapper {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: $z-index-upperest;

        .cookie-details-panel {
            width: 100%;
            height: 100%;
            position: relative;
            width: 600px;
            background-color: $color-header-background;
            border-right: 1px solid $color-lightish-grey;
            box-shadow: 4px 0px 10px $color-shadow;
            display: flex;
            flex-direction: column;

            .cookie-details-header {
                display: flex;
                justify-content: space-between;
                padding: 0px 35px;
                height: 100px;
                border-bottom: 1px solid $color-header-divider;

                .close-button {
                    margin: auto 0px;
                }

                .logo {
                    height: 100%;
                    width: 300px;
                    padding: 10px;

                    .logo-img {
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                        object-position: left;
                    }
                }
            }

            .cookie-details-content {
                padding: 25px 40px;
                font-size: 16px;
                flex-grow: 1;
                flex-shrink: 1;
                overflow-y: auto;

                .cookie-details-preferences {
                    .cookie-preferences-header {
                        font-size: 25px;
                        font-weight: bold;
                        margin: 10px 0px;
                    }

                    .cookie-preferences-table {
                        border: 1px solid $color-lightish-grey;

                        &:last-child {
                            border-bottom: none;
                        }

                        .preferences-table-item {
                            border-bottom: 1px solid $color-lightish-grey;

                            .table-item-header {
                                font-weight: bold;
                                padding: 20px 10px;
                                display: flex;
                                justify-content: space-between;

                                .icon {
                                    height: 40px;
                                    min-width: 40px;
                                    fill: $color-text-black;
                                    cursor: pointer;
                                    background-repeat: no-repeat;
                                    background-position: center;

                                    &.plus {
                                        background-image: url('../../public/images/plus.svg');
                                    }

                                    &.minus {
                                        background-image: url('../../public/images/minus.svg');
                                    }
                                }

                                .title {
                                    display: block;
                                    width: 100%;
                                    margin: auto;
                                    font-size: 20px;
                                }

                                .necessary-text {
                                    min-width: 150px;
                                    display: inline-block;
                                    margin: auto;
                                    text-align: center;
                                    color: var(--theme-color-3, $color-text-teal);
                                }
                            }

                            .table-item-details {
                                padding: 25px;
                            }
                        }
                    }
                }
            }

            .button-container {
                margin-top: auto;
                padding: 20px 10px;
                border-top: 1px solid $color-lightish-grey;
            }
        }
    }
    @include media($breakpoint-bottom-banner-mobile) {
        .cookie-bottom-panel-wrapper {
            height: auto;

            .cookie-bottom-panel {
                flex-direction: column;
                padding: 20px;

                .text-content {
                    margin-bottom: 20px;
                }
                .button-container {
                    flex-direction: column;
                    margin: 0px;
                    width: 100%;
                    justify-content: space-between;
                    height: 120px;

                    .button {
                        width: 100%;
                        margin: 0px;
                    }
                }
            }
        }
    }

    @include media($breakpoint-small) {
        .cookie-details-panel-wrapper {
            .cookie-details-panel {
                width: 100%;
            }

            .table-item-details,
            .cookie-details-content,
            .cookie-details-header {
                padding: 10px 20px;
            }

            .necessary-text {
                min-width: initial;
            }

            .button-container {
                flex-direction: column;
                margin: 0px;
                width: 100%;
                justify-content: space-between;
                min-height: 150px;

                .button {
                    width: 100%;
                    margin: 0px;
                }
            }
        }
    }
}
