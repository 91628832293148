.policy {
    .coupons-title {
        font-weight: bold;
    }

    .bullet-title {
        color: var(--theme-color-2, $color-text-teal3);
        font-weight: regular;
    }

    .img-container {
        display: flex;
        flex-direction: column;
        padding: 0;
        li {
            list-style-type: none;

            .image {
                width: 100%;
            }
        }
    }

    .header-text {
        margin-top: 12px;
        margin-bottom: 12px;
        line-height: 21px !important;
    }

    .fine-print {
        font-size: 12px;
        margin-top: 60px;
    }

    .second-title {
        color: $color-text-teal3 !important;
        margin-top: 28px !important;
    }

    .sub-title {
        margin-top: 0;
        font-size: 16px;
        margin-bottom: 16px;
    }

    .bullet-list {
        padding-left: 20px;

        .bullet {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}
