.critical-shipping-page {
    .critical-shipping-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: $color-background-teal-darker;
        color: $color-text-white;

        h1,
        h2,
        p {
            text-align: center;
        }

        h1 {
            font-size: 3rem;
            margin-bottom: 0;
        }

        h2 {
            font-size: 2rem;
            margin: 0;
        }

        p {
            margin: 0.2em;
            color: white;
            font-weight: bold;
            font-size: 1rem;

            a {
                color: white;
            }

            &:last-child {
                margin-bottom: 1em;
            }
        }
    }

    .critical-shipping-content {
        padding: 2em 3em;
        max-width: 1000px;
        margin: auto;

        .critical-shipping-message {
            text-align: center;
            font-size: 3em;
        }

        .critical-shipping-errors {
            background-color: red;
            padding: 0.1em 1em;
            color: white;
            font-size: 1.5em;
            margin-bottom: 1em;
        }

        .critical-shipping-vendor-info {
            display: flex;
            justify-content: space-between;

            .inputRow {
                min-width: 45%;
                input {
                    flex-grow: 1;
                }
            }

            @include media($breakpoint-small) {
                flex-direction: column;
            }
        }

        .critical-shipping-pagination {
            color: $color-text-red;
            font-size: 1.5em;
        }

        .critical-shipping-order {
            .critical-shipping-rep-info {
                p {
                    margin: 0.5em 0;
                    font-size: 1.2em;

                    .label {
                        margin-right: 0.5em;
                    }
                }
            }

            .critical-shipping-question-panel {
                display: flex;
                flex-direction: column;

                .critical-shipping-question {
                    .critical-shipping-question-text {
                        background: $color-background-dark-grey;
                        font-weight: bold;
                        font-size: 1.4em;
                        border-radius: 25px;
                        padding: 0.5em;
                        text-align: center;

                        .text-red {
                            color: $color-text-red;
                        }
                    }

                    .critical-shipping-question-answers {
                        display: flex;
                        justify-content: center;

                        .inputRow {
                            &:first-child {
                                margin-right: 5em;
                            }

                            input {
                                display: inline-block;
                                width: 2em;
                            }
                        }
                    }

                    .inputRow {
                        margin-bottom: 2em;
                        input {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }

    .critical-shipping-buttons {
        margin-bottom: 3em;

        button {
            display: block;
            margin: auto;
            border-radius: 1.5em;
            height: 3em;
            font-size: 2em;
            outline: none;
            border: none;
            padding: 0 3em;
            margin-bottom: 0.1em;

            &.next {
                background-color: $color-background-teal-darker;
                color: $color-text-white;

                &:hover {
                    background-color: $color-background-teal-dark;
                }
            }

            &.submit {
                background-color: $color-button-golden;

                &:hover {
                    background-color: $color-highlight-light-orange;
                }
            }

            &.back {
                background: none;
                font-size: initial;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .inputRow {
        display: flex;
        &.textArea {
            label {
                margin-top: 0;
            }

            textarea {
                flex-grow: 1;
                resize: vertical;

                &[disabled] {
                    background-color: $color-background-dark-grey;
                }
            }

            @include media($breakpoint-small) {
                flex-direction: column;
            }
        }

        label {
            color: $color-text-blue-darken;
            font-weight: bold;
            padding: 0 0.2em;
            font-size: 1.4em;
            display: inline-block;
            height: min-content;
            margin: auto 0;
        }

        input {
            border: none;
            border-bottom: 1px solid $color-background-darker-grey;
        }
    }
}
