.login {
    .mainHeader {
        font-size: 32px;
        color: $color-text-darker-grey;
        text-align: center;
        font-family: 'Merriweather, sans-serif';
        margin-top: 34px;
        margin-bottom: 30px;
    }
    .columnWrapper {
        display: flex;
        @include media($breakpoint-small) {
            flex-direction: column;
        }
    }
    .login-column-wrapper {
        width: 100%;
        height: 100%;

        .login-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 0;
            background-color: $color-background-light-grey;
            border: 1px solid $color-background-grey;
            padding-top: 22px;
            padding-left: 90px;
            padding-right: 90px;
            width: 100%;
            height: 100%;

            @include media($breakpoint-small) {
                padding-left: 20px;
                padding-right: 20px;
            }
            .special-row {
                display: flex;
                justify-content: space-between;
                width: 80%;
                margin-left: 20%;
            }
            .separator {
                text-align: center;
                position: relative;
                color: $color-text-darker-grey;
                width: 100%;
                margin-bottom: 30px;
                margin-top: 10px;

                span {
                    background: $color-background-light-grey;
                    display: inline-block;
                    padding: 0 18px;
                    position: relative;
                    font-weight: bold;
                    font-size: 18px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $color-text-darker-grey;
                }
            }
        }
    }
    .registerColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 0;
        background-color: $color-background-lighter-grey;
        border: 1px solid $color-background-grey;
        padding-top: 22px;
        padding-left: 90px;
        padding-right: 90px;
        @include media($breakpoint-small) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .productColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;
        background-color: $color-background-lighter-grey;
        border: 1px solid $color-background-grey;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .registerForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .signInSubheader {
        font-size: 20px;
        margin-bottom: 66px;
        &.sso {
            margin-bottom: 10px;
        }
    }
    .registerSubheader {
        margin-bottom: 3px;
    }
    .registerSubheaderMandatory {
        margin-bottom: 16px;
    }
    .inputRow {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .inputLabel {
        flex: 0 0 90px;
        padding-right: 10px;
        text-align: right;
        margin-bottom: 4px;
    }
    .requiredInputLabel {
        flex: 0 0 140px;
        padding-right: 10px;
        text-align: right;
        margin-bottom: 4px;
    }
    .input {
        flex: 1 1 100%;
        min-height: 32px;
        height: 32px;
    }
    .checkboxRow {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;
    }
    .checkboxRowNoProduct {
        margin-bottom: 12px;
    }
    .inputCheckbox {
        margin: 10px;
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    .forgotPassword {
        width: 100%;
        text-align: right;
        margin-bottom: 62px;
    }
    .questions {
        font-size: 20px;
        margin-top: 16px;
        margin-bottom: 6px;
        text-align: center;
    }
    .productLoginQuestions {
        margin-top: 6px;
        margin-bottom: 28px;
    }
    .privacyPolicy {
        margin-bottom: 25px;
    }
    .whyCreateWrapper {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
    .whyWrapper {
        overflow: auto;
        background-color: $color-background-lighter-grey;
        @include media($breakpoint-large) {
            margin-left: 5px;
            margin-right: 5px;
        }
        flex: 1 0 50%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
    .whyHeader {
        margin-top: 20px;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: $color-text-darker-grey;
    }
    .whyCheckColumn {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
    }
    .whyColumnWrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include media($breakpoint-small) {
            flex-direction: column;
            margin-bottom: 30px;
        }
    }
    .whyColumn {
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 160px;
    }
    .whyText {
        font-size: 20px;
        font-family: 'OpenSans, sans-serif';
        color: $color-text-darker-grey;
    }
    .whyBold {
        font-size: 30px;
        font-weight: bold;
        font-family: 'OpenSans, sans-serif';
        color: $color-text-darker-grey;
    }
    .whyCheckRow {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 20px;
    }
    .whyCheckImage {
        width: 32px;
        height: 32px;
    }
    link {
        color: $color-text-teal;
        cursor: pointer;
    }
    link:hover {
        text-decoration: underline;
    }
    .registerLink {
        margin-bottom: 10px;
    }
    .leftColum {
        @include media($breakpoint-large) {
            margin-right: 11px;
        }
        flex: 1 1 100%;
        display: flex;
    }
    .rightColumn {
        @include media($breakpoint-large) {
            margin-left: 11px;
        }
        flex: 1 1 100%;
        display: flex;
    }
    .terms-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .terms {
        font-size: 12px;
        text-align: center;
        margin-bottom: 6px;
    }
    .productImage {
        max-width: 100%;
        max-height: 400px;
    }
    .login-wrapper {
        @include display(table);
        border-spacing: 20px;
        width: 100%;
        background-color: $color-background-white;
        margin-top: 10px;
        padding-bottom: 125px;
        border-bottom: 1px solid $color-divider-grey;
    }
    .login-row {
        margin-top: 20px;
        @include media($breakpoint-large) {
            @include row();
            height: 40px;
            display: flex;
            align-items: center;
        }
        &.right {
            overflow: auto;
        }
    }
    .login-link {
        color: $color-highlight-orange;
    }
    .left-wrapper {
        @include media($breakpoint-small) {
            width: 100%;
            overflow: auto;
            margin-bottom: 20px;
        }
        @include media($breakpoint-large) {
            @include span-columns(15, table);
            @include omega();
        }
        background-color: $color-background-yellow;
        padding: 20px;

        .login-input {
            @include media($breakpoint-large) {
                @include span-columns(20);
                display: inline-block;
            }
            @include media($breakpoint-small) {
                width: 100%;
            }
            border: 1px solid $color-text-light-teal;
            border-radius: 3px;
            padding: 10px;
            font-size: 14px;
        }
        .login-input.full-width {
            width: 100%;
        }
        .login-teal-link {
            font-weight: bold;
            font-size: 16px;
            color: $color-text-teal;
            cursor: pointer;
        }
        .login-button {
            background-color: $color-background-yellow;
            color: $color-text-light-teal;
            border: 1px solid $color-text-light-teal;
            @include media($breakpoint-header-large) {
                margin-top: 20px;
            }
            @include media($breakpoint-header-small) {
                margin-top: 40px;
            }
            @include media($breakpoint-small) {
                margin-top: 30px;
            }
        }
        .login-button.nomargin {
            margin-top: 0;
        }
        @include media($breakpoint-large) {
            .login-button:hover {
                background-color: $color-text-light-teal;
                color: $color-text-white;
                text-decoration: none;
            }
        }
        .login-forgot-password {
            @include media($breakpoint-large) {
                @includes span-columns(10);
                display: inline-block;
            }
            padding-left: 10px;
            font-size: 14px;
            color: $color-highlight-orange;
            margin-top: 10px;
            cursor: pointer;
        }
        .login-checkbox-row {
            @include row();
            margin-top: 10px;
            .login-checkbox-label {
                display: inline-block;
                color: $color-text-grey;
                font-size: 14px;
            }
        }
        .login-register-row {
            @include media($breakpoint-small) {
                margin-bottom: 20px;
            }
        }
        .login-flex {
            margin-top: 20px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            @include media($breakpoint-small) {
                flex-direction: column;
            }
        }
    }
    .right-wrapper {
        @include media($breakpoint-small) {
            width: 100%;
        }
        @include media($breakpoint-large) {
            @include span-columns(15, table);
        }
        background-color: $color-background-grey;
        padding: 20px;
        .right-button {
            display: inline-block;
            background-color: $color-background-white;
            color: $color-text-grey;
            border: 1px solid $color-background-darker-grey;
            margin-top: 20px;
        }
        .right-button:hover {
            @include media($breakpoint-large) {
                text-decoration: none;
                border-color: $color-text-light-teal;
                background-color: $color-text-light-teal;
                color: $color-text-white;
            }
        }
        .login-number {
            font-family: 'Open Sans', sans-serif;
            background-color: $color-background-darker-grey;
            color: $color-text-white;
            padding: 10px;
            width: 38px;
            text-align: center;
            display: inline-block;
            border-radius: 3px;
        }
        .login-register-text {
            @include media($breakpoint-large) {
                @include span-columns(27);
            }
            @include media($breakpoint-small) {
                width: calc(100% - 40px);
                vertical-align: middle;
            }
            font-family: 'Open Sans', sans-serif;
            display: inline-block;
            color: $color-text-light-teal;
            padding-left: 10px;
        }
        .login-register-big-text {
            font-family: 'Georgia', serif;
            color: $color-text-grey;
            font-size: 18px;
            font-style: italic;
            margin-top: 20px;
        }
    }
}
.account-page {
    margin-bottom: 125px;
    overflow: auto;
}
.account-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    color: var(--theme-color-3, $color-text-teal);
}
.account-sub-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: var(--theme-color-3, $color-text-light-teal);
    padding-bottom: 20px;
}
.account-header-grey {
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    color: $color-text-grey;
}

.account-tab-row {
    padding-left: 0px;
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    .account-tab-button {
        @include media($breakpoint-large) {
            padding: 7px 20px;
        }
        @include media($breakpoint-small) {
            padding: 7px;
            @include span-columns(30);
        }
        display: inline-block;
        font-size: 14px;
        border: 1px solid $color-divider-grey;
        cursor: pointer;
        background-color: var(--wl-grey);
        a:hover {
            @include media($breakpoint-large) {
                text-decoration: none;
            }
        }
        &:hover {
            @include media($breakpoint-large) {
                text-decoration: none;
                background-color: $color-divider-grey;
                .partner & {
                    background-color: var(--wl-grey);
                }
            }
        }
        &.active {
            color: var(--theme-color-3, $color-text-light-teal);
            background-color: var(--wl-white, $color-background-teal);

            &:hover {
                @include media($breakpoint-large) {
                    text-decoration: none;
                    color: var(--theme-color-3, $color-text-white);
                    background-color: $color-text-teal;

                    .partner & {
                        background-color: var(--wl-white);
                    }
                }
            }
        }
    }
}
.account-tab-wrapper {
    display: inline-block;
    margin-top: 20px;
    @include span-columns(30);
}
.contact-section {
    @include media($breakpoint-large) {
        @include span-columns(15);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
        margin-top: 20px;
    }
}
.profile-section {
    background-color: $color-background-grey;
    padding: 20px;
    margin-bottom: 20px;
    min-height: 143px;
    overflow: auto;

    @include media($breakpoint-small) {
        padding: 10px;
    }
    .profile-section-title-row {
        padding-bottom: 10px;
        .profile-section-title {
            font-family: 'Open Sans', sans-serif;
            display: inline-block;
            color: var(--theme-color-3, $color-text-light-teal);
            font-size: 20px;
            padding-right: 5px;

            @include media($breakpoint-small) {
                @include span-columns(30);
                margin-bottom: 5px;
            }
        }
        .profile-section-button {
            color: var(--wl-input-border, $color-highlight-light-orange);
            font-size: 14px;
            display: inline-block;
            padding-right: 5px;
            cursor: pointer;
        }
        .profile-section-button:hover {
            @include media($breakpoint-large) {
                text-decoration: underline;
            }
        }
        .profile-section-button:nth-child(n + 3) {
            border-left: 1px solid $color-text-black;
            padding-left: 5px;
        }
    }
    .profile-contact-label {
        width: 60px;
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        color: $color-text-grey;
    }
    .profile-contact-content {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
    }
    .profile-contact-password-button {
        float: right;
        border: 1px solid $color-divider-grey;
        background-color: $color-background-white;
        border-radius: 3px;
        padding: 6px 15px;
        cursor: pointer;
    }
    .profile-contact-password-button:hover {
        @include media($breakpoint-large) {
            background-color: $color-divider-grey;
        }
    }
    .profile-payment-column {
        @include span-columns(10);
        overflow: auto;
        .profile-payment-header {
            font-size: 14px;
            line-height: 21px;
            color: $color-text-grey;
        }
        .profile-payment-content {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .profile-address-content {
        font-size: 14px;
        line-height: 21px;
    }
    .profile-payment-column:nth-child(2n + 1) {
        @include span-columns(20);
    }
}
.profile-payment-column {
    @include media($breakpoint-large) {
        @include span-columns(15);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
}
.profile-sort-header {
    background-color: $color-background-grey;
    width: 100%;
    padding: 10px;
    min-height: 40px;
    margin-bottom: 10px;
    .profile-sort-header-label {
        margin-left: 20px;
        display: inline-block;
        font-size: 14px;
        color: $color-text-grey;
        font-weight: bold;
    }
    .profile-sort-select {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        width: 200px;
    }
}
.saved-item {
    @include media($breakpoint-large) {
        @include span-columns(6);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
        margin-bottom: 20px;
    }
    margin-top: 20px;
    margin-bottom: 20px;
    height: 380px;
    position: relative;

    &:last-of-type {
        margin-bottom: 40px;
    }
}
.comparison-list-item {
    margin-bottom: 20px;
    position: relative;
}
.saved-item-close-button {
    width: 20px;
    right: 5px;
    top: 5px;
    cursor: pointer;
    background: var(--theme-color-2, #9ddbe2);
    position: relative;
    overflow: hidden;

    @include close-mask(var(--theme-color-3, $color-text-teal));
}
.saved-item-border-wrapper {
    border-top: 2px solid $color-divider-grey;
    height: 100%;
}
.saved-item-image-wrapper {
    padding-top: 10px;
    text-align: center;
    .saved-item-image {
        margin: auto;
        height: 190px;
        margin: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.saved-item-text-wrapper {
    padding: 10px;
    line-height: 21px;
    .saved-item-name {
        font-size: 14px;
        color: $color-text-black;
        display: block;
        min-height: 42px;
    }
    .comparison-item-name {
        font-size: 14px;
        color: $color-text-black;
        display: block;
    }
    .saved-item-name:hover {
        @include media($breakpoint-large) {
            text-decoration: none;
            color: var(--theme-color-3, $color-highlight-light-orange);
        }
    }
    .saved-item-sku {
        color: $color-text-grey;
        font-size: 10px;
        display: block;
    }
    .saved-item-sku:hover {
        @include media($breakpoint-large) {
            text-decoration: none;
            color: var(--theme-color-3, $color-highlight-light-orange);
        }
    }
    .saved-item-row {
        color: $color-text-grey;
        display: inline-block;
        font-size: 14px;
    }
    .saved-item-value {
        color: $color-text-grey;
        display: inline-block;
        font-size: 14px;
    }
    .saved-item-bold-value,
    .saved-item-bold-value-red {
        padding-left: 5px;
        display: inline-block;
        font-size: 16px;
        color: $color-text-black;
    }

    .saved-item-bold-value-red {
        font-weight: bold;
        font-size: 18px;
        color: var(--theme-color-3, $color-text-red);
    }
}
.saved-item-buy-button {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--theme-color-3, $color-highlight-light-orange);
    color: var(--wl-white, $color-text-white);
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    display: block;
    width: 95%;
    text-align: center;
    margin: 10px 2.5% 0px 2.5%;
    text-decoration: none;

    &[disabled],
    &.disabled {
        background-color: $color-background-darker-grey;
        pointer-events: none;
    }
}
.saved-item-buy-button:hover {
    @include media($breakpoint-large) {
        text-decoration: none;
        background-color: var(--theme-color-3-fade, $color-highlight-orange);
    }
}
.saved-item-save-button {
    font-family: 'Open Sans', sans-serif;
    background-color: $color-background-white;
    color: $color-text-black;
    border: 1px solid $color-divider-grey;
    border-radius: 4px;
    margin: 5px 2.5% 0px 2.5%;
    padding: 10px;
    display: block;
    width: 95%;
    text-align: center;
    text-decoration: none;
}

.account-manager-wrapper {
    @include media($breakpoint-large) {
        @include span-columns(15);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    background-color: $color-background-grey;
    padding: 20px;
    margin-bottom: 20px;
    .account-manager-content-wrapper {
        @include span-columns(20);
        .account-manager-header {
            color: $color-text-light-teal;
            font-size: 24px;
            padding-bottom: 20px;
        }
        .account-manager-body {
            font-size: 14px;
            padding-bottom: 20px;
        }
    }
    .account-manager-image-wrapper {
        @include span-columns(10);
        text-align: center;
        .account-manager-image {
            height: 110px;
        }
    }
}
.account-button {
    @include media($breakpoint-large) {
        width: 30%;
    }
    @include media($breakpoint-small) {
        width: 45%;
    }
    padding: 6px;
    font-size: 14px;
    background-color: $color-background-white;
    border: 1px solid $color-divider-grey;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
}

.order-wrapper {
    padding: 10px;
    margin-bottom: 20px;
    overflow: auto;
    border-bottom: 1px solid $color-divider-grey;

    .order-image-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(5);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
    }
    .order-content-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(25);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        .order-header-wrapper {
            @include media($breakpoint-large) {
                @include span-columns(20);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            padding-bottom: 10px;

            .order-header-text {
                color: var(--theme-color-3, $color-text-light-teal);
                font-size: 14px;
            }
            .order-header-subtext {
                color: $color-text-grey;
                font-size: 14px;
            }
        }
        .order-button-wrapper {
            @include media($breakpoint-large) {
                @include span-columns(10);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
                margin-bottom: 20px;
            }
            .order-button {
                background-color: $color-background-white;
                border: 1px solid $color-divider-grey;
                padding: 6px;
                margin-left: 5%;
                cursor: pointer;

                @include media($breakpoint-small) {
                    margin-left: 0px;
                    width: 100%;
                }
            }
            .order-button:hover {
                @include media($breakpoint-large) {
                    background-color: $color-divider-grey;
                }
            }
        }
        .order-content-column {
            @include media($breakpoint-large) {
                @include span-columns(8);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            @include row;
            .order-content-label {
                @include span-columns(15);
                color: $color-text-grey;
                font-size: 14px;
                line-height: 21px;
                margin: 0px;
            }
            .order-content {
                @include span-columns(15);
                margin: 0px;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .order-reorder-column {
            @include shift(4);
            @include span-columns(10);

            @include media($breakpoint-small) {
                @include shift(0);
                @include span-columns(30);
            }

            .order-reorder-message {
                margin: 0px 10px;
                padding: 10px;
                font-size: 14px;
                line-height: 21px;
                background-color: var(--wl-grey, $color-background-blue);
                @include media($breakpoint-small) {
                    margin: 0px;
                    margin-top: 10px;
                }

                .order-reorder-header {
                    color: var(--wl-black, $color-text-teal);
                    font-size: 16px;
                }
            }
        }
    }
}

.comparison-table {
    width: 100%;
    margin-bottom: 30px;
    .comparison-spacer {
        width: 20%;
    }
    .comparison-element {
        vertical-align: top;
        width: 20%;
        padding: 10px;
        border-right: 1px solid $color-divider-grey;
        border-bottom: 1px solid $color-divider-grey;
        .comparison-list-header {
            font-size: 14px;
            font-weight: bold;

            background-color: $color-background-grey;
            padding: 10px;
        }
        .comparison-list-name-list {
            padding-left: 10px;
            padding-right: 10px;
        }
        .comparison-list-name {
            font-size: 14px;
            padding: 4px;
            cursor: pointer;
        }
        .comparison-list-name:hover {
            @include media($breakpoint-large) {
                background-color: $color-background-teal;
                color: $color-text-teal;
            }
        }
        .comparison-list-name.active {
            color: $color-text-light-teal;
        }
        .comparison-list-button {
            display: block;
            background-color: $color-background-grey;
            border: 1px solid $color-divider-grey;
            border-radius: 3px;
            font-size: 14px;
            width: 90%;
            padding: 6px;
            margin: 10px auto;
        }
        .comparison-row-label {
            text-align: right;
            font-size: 14px;
            color: $color-text-grey;
        }
        .comparison-row-text {
            font-size: 16px;
            text-align: center;
        }
        .comparison-row-check-wrapper {
            text-align: center;
        }
        .comparison-row-check {
            height: 30px;
            padding: 4px;
        }
    }
    .comparison-element.no-padding {
        padding: 0px;
    }
}

.turnto-login {
    .login-wrapper {
        border-bottom: none;
        padding-bottom: 0px;
        margin-top: 0px;

        @include display(flex);
        @include flex-direction(column);

        .left-wrapper,
        .right-wrapper {
            width: 100%;
            margin-bottom: none;
        }
    }
}

.login-error-text {
    color: $color-text-red;
    margin-top: 0px;
    margin-bottom: 0px;
}
