/*! BUTTON STYLE STOPGAP - NEED TO FIX THIS AT SOMEPOINT */
button,
.button,
a.button,
a.orange-button,
a.product-button,
button.modal-button-white,
button.modal-button-blue,
.customize-page .customize-content-wrapper .customize-summary-column .summary-cart-button,
.cart-checkout-item-added-summary .cart-checkout-button,
.cart-checkout-wrapper .cart-checkout-button,
.checkout-shipping-address .checkout-address-button-column .checkout-address-button,
.checkout-new-wrapper .checkout-new-button,
.checkout-shipping-wrapper .checkout-shipping-button,
.customize-page .customize-content-wrapper .customize-options-column .customize-contact-wrapper .customize-contact-button,
.checkout-card-button,
.checkout-card-edit-button,
.order-complete-continue-shopping {
    cursor: pointer;
    outline: none;
    min-height: 42px;
    padding: 8px 15px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    font-family: 'OpenSans', 'Open Sans', sans-serif;
}
/*! --- fancy ORANGE buttons ------------------------------------------------------------------------------------------------------------------------ */
a.orange-button,
.customize-page .customize-content-wrapper .customize-summary-column .summary-cart-button,
.cart-checkout-item-added-summary .cart-checkout-button,
.cart-checkout-wrapper .cart-checkout-button,
.checkout-shipping-address .checkout-address-button-column .checkout-address-button,
.checkout-new-wrapper .checkout-new-button,
.checkout-shipping-wrapper .checkout-shipping-button,
.checkout-card-button {
    background: var(--theme-color-3, #db4011);
    color: var(--wl-white, #fff);
    background: var(--theme-color-3, -moz-linear-gradient(top, #db4011 0%, #dd6427 100%));
    background: var(--theme-color-3, -webkit-linear-gradient(top, #db4011 0%, #dd6427 100%));
    background: var(--theme-color-3, linear-gradient(to bottom, #db4011 0%, #dd6427 100%));
    -webkit-transform-style: preserve-3d;
    /*preserve 3d supposedly fixes a flicker*/
}
a.orange-button:hover,
.customize-page .customize-content-wrapper .customize-summary-column .summary-cart-button:hover,
.checkout-shipping-wrapper .checkout-shipping-button:hover,
.checkout-new-wrapper .checkout-new-button:hover {
    @include media($breakpoint-large) {
        background: var(--theme-color-3, $color-button-background);
        border: 1px solid var(--theme-color-3, $color-button-background-third);
        text-shadow: 0 2px 1px var(--theme-color-3, $color-button-background-third);
        background: -moz-linear-gradient(top, var(--theme-color-3, $color-button-background) 100%, var(--theme-color-3, $color-button-background-secondary) 0%);
        background: -webkit-linear-gradient(
            top,
            var(--theme-color-3-fade, $color-button-background) 100%,
            var(--theme-color-3-fade, $color-button-background-secondary) 0%
        );
        background: linear-gradient(
            to bottom,
            var(--theme-color-3-fade, $color-button-background) 100%,
            var(--theme-color-3-fade, $color-button-background-secondary) 0%
        );
        -webkit-transform-style: preserve-3d;
    }
}
/*! --- end fancy orange button */
/*! --- WHITE buttons ------------------------------------------------------------------------------------------------------------------------ */
a.product-button,
button.product-button,
.product-page .product-button,
.edit-modal-wrapper .modal-button-white,
button.modal-button-white,
.checkout-shipping-address .checkout-address-button-column .checkout-address-edit-button,
.checkout-card-edit-button,
.checkout-confirm-button,
.order-complete-continue-shopping,
button.product-button,
.product-page .product-button {
    border: 1px solid $color-border-grey;
    font-size: 15px;
    padding: 8px 20px;
    background: #fff;
}
a.product-button:hover,
button.product-button:hover,
.product-page .product-button:hover,
.edit-modal-wrapper .modal-button-white:hover,
button.modal-button-white:hover,
.checkout-shipping-address .checkout-address-button-column .checkout-address-edit-button:hover,
.checkout-card-edit-button:hover,
.checkout-confirm-button:hover,
.order-complete-continue-shopping:hover {
    @include media($breakpoint-large) {
        background: #e5e5e5;
        border: 1px solid #adadad;
        color: #000;
        text-shadow: 0 2px 1px #fff;
    }
}
/*! --- solid blue buttons------------------------------------------------------------------------------------------------------------------------ */
button.modal-button-blue,
.edit-modal-wrapper .modal-button-blue,
.customize-page .customize-content-wrapper .customize-options-column .customize-contact-wrapper .customize-contact-button {
    border: 1px solid var(--theme-color-3, #061d40);
    background-color: var(--theme-color-3, #37527a);
    color: var(--wl-white, #fff);
}
button.modal-button-blue:hover,
.edit-modal-wrapper .modal-button-blue:hover,
.customize-page .customize-content-wrapper .customize-options-column .customize-contact-wrapper .customize-contact-button:hover {
    @include media($breakpoint-large) {
        background-color: var(--theme-color-3-fade, #203c66);
        text-shadow: 0 2px 1px var(--theme-color-3-fade, #3e5577);
        text-decoration: none;
    }
}
/*! --- buttons on blue learn more forms on home page ------------------------------------------------------------------------------------------------------------------------ */
.home .home-learnmore-wrapper .home-learnmore-button {
    background: none;
}
.home .home-learnmore-wrapper .home-learnmore-button:hover {
    @include media($breakpoint-large) {
        border-color: #006166;
        text-shadow: 0 2px 1px #006166;
        letter-spacing: 0.025em;
    }
}
/*! --- footer buttons ------------------------------------------------------------------------------------------------------------------------ */
.footer-support-action-buttons a.orange-button {
    margin-bottom: 8px;
}
/*global backgrounds--------------------------------------------------------------------------------------------------------------*/
.modal-fade-screen.open {
    background-color: rgba(90, 90, 90, 0.8);
}
/* light blue background */
.home-learnmore-column,
.customize-page .customize-content-wrapper .customize-options-column .customize-contact-wrapper {
    background: -moz-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
    background: -webkit-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
    background: -o-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
    background: -ms-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
    background: linear-gradient(180deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
    background: var(--theme-color-2-fade, $color-background-blue);
}

.button {
    cursor: pointer;
    outline: none;
    min-height: 42px;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    font-family: 'OpenSans', 'Open Sans', sans-serif;
    position: relative;

    .button-icon {
        width: 15px;
        height: 15px;
        vertical-align: middle;
        display: inline-block;
        background-color: $color-black;
        @include mask-image-without-url();
    }

    &.full-width {
        width: 100% !important;
    }

    &.secondary {
        background-color: $color-secondary;
        border: 1px solid $color-secondary-dark;
        color: $color-secondary-darker;

        &:hover,
        &:active,
        &.active {
            background: $color-secondary-dark;
        }

        .button-icon {
            background-color: $color-secondary-darker;
        }
    }

    &.primary {
        background: $color-primary;
        border: 1px solid $color-primary-dark;
        color: $color-white;

        &:hover,
        &:active,
        &.active {
            background: $color-primary-dark;
        }

        .button-icon {
            background-color: $color-white;
        }
    }

    &.link {
        border-width: 2px;
        border-style: solid;
        background-color: $color-white;
        display: block;
        width: fit-content;

        &.secondary {
            border-color: $color-secondary-darker;
            color: $color-secondary-darker;
        }

        &.primary {
            border-color: $color-primary;
            color: $color-primary;
        }

        &:hover,
        &:active,
        &.active {
            background-color: $color-white;
        }

        &:has(.link-icon) {
            padding-right: 30px;
        }

        .link-icon {
            margin-left: 10px;
            text-decoration: none;
            position: absolute;
            right: 5px;
            font-size: 25px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.search-result-button {
        margin-right: 4px;

        .search-result-arrow-image {
            height: 15px;
        }
    }
}
