.sorry-search {
    @include outer-container;
}

.sorry-carousel-container {
    margin-bottom: 20px;
}

.sorry-search-header {
    @include span-columns(12 of 12);
    margin-bottom: 45px;
    margin-top: 10px;
}

.sorry-search-header-title {
    font-size: 34px;
    color: var(--theme-color-3, $color-text-teal);
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
}

.sorry-search-content {
    margin-bottom: 30px;
    @include span-columns(12 of 12);
    @include display(flex);

    @include media($breakpoint-small) {
        display: block;
    }
}

.sorry-search-try {
    @include span-columns(6 of 12);
    background-color: var(--wl-grey, $color-background-yellow);
    padding: 20px;
    position: relative;
    min-height: 150px;
    display: flex;
    flex-direction: column;

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
        margin-bottom: 20px;
    }
}

.sorry-search-try-title {
    font-size: 24px;
    color: var(--theme-color-3, $color-text-light-teal);
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
}

.sorry-search-try-suggestions {
    margin-bottom: 60px;
}

.sorry-search-help {
    @include span-columns(6 of 12);
    background-color: var(--wl-grey, $color-background-teal);
    padding: 20px;

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
        margin-bottom: 20px;
    }
}

.sorry-search-help-title {
    font-size: 24px;
    color: var(--theme-color-3, $color-text-light-teal);
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
}

.sorry-search-help-text {
    line-height: 21px;
    font-size: 14px;
}

.sorry-search-help-buttons {
    @include display(flex);
    @include flex-wrap(wrap);
}

.sorry-search-help-button {
    font-size: 14px;
    color: $color-text-white;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--theme-color-3, $color-background-dark-blue);
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    text-align: center;
    width: 250px;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        background-color: var(--theme-color-3-fade, $color-background-dark-blue);
    }

    & > span {
        color: white;
    }
}

.sorry-search-popular {
    @include span-columns(12 of 12);
    margin-bottom: 30px;
}

.sorry-search-popular-container {
    @include span-columns(6 of 12);

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
        margin-bottom: 20px;
    }
}

.sorry-search-popular-header {
    font-size: 24px;
    color: $color-text-grey;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    line-height: 60px;
    margin-top: 30px;
    border-bottom: 2px solid $color-background-dark-grey;
    margin-bottom: 20px;
}

.sorry-search-popular-list {
    @include display(flex);
    @include flex-wrap(wrap);
    @include justify-content(space-between);
}

.sorry-search-popular-list-item {
    width: 150px;
    font-size: 14px;
    color: var(--theme-color-2, $color-text-teal);
    font-weight: 500;
    margin-bottom: 10px;
}
