@import 'neat';

$column: 25px;
$gutter: 20px;
$grid-columns: 30;
$max-width: 1320px;

$breakpoint-header-large: new-breakpoint(min-width 1093px 30);
$breakpoint-header-small: new-breakpoint(max-width 1092px min-width 769px 30);

$breakpoint-larger: new-breakpoint(min-width 1025px 30);
$breakpoint-large: new-breakpoint(min-width 769px 30);
$breakpoint-small: new-breakpoint(max-width 768px 30);
$breakpoint-smaller: new-breakpoint(max-width 480px 30);
$breakpoint-tiny: new-breakpoint(max-width 320px 30);

$breakpoint-bottom-banner-mobile: new-breakpoint(max-width 1025px 30);
