.subset {
    margin-top: 15px;
    .subset-herobanner-wrapper {
        @include row();
        padding-top: 20px;
        padding-bottom: 40px;
        .subset-image-carousel-wrapper {
            @include media($breakpoint-large) {
                @include span-columns(20);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
        }
        .subset-small-image-wrapper {
            @include media($breakpoint-large) {
                @include span-columns(10);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            .subset-small-banner-image {
                @include media($breakpoint-large) {
                    width: 100%;
                    height: 48%;
                }
                @include media($breakpoint-small) {
                    width: 50%;
                }
            }
            .subset-small-banner-image:first-child {
                @include media($breakpoint-large) {
                    margin-bottom: 4%;
                }
            }
        }
        .subset-banner-image {
            width: 100%;
        }
    }
}
