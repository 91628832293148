.policy {
    margin-bottom: 110px;
    p {
        line-height: 28px;
    }
    h1,
    h3 {
        color: var(--theme-color-3, $color-text-teal);
    }
    .half {
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    .row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .title {
        font-size: 34px;
        color: var(--theme-color-3, $color-text-teal);
        font-family: 'Open Sans', sans-serif;
        font-weight: lighter;
        margin: 0px;

        @include media($breakpoint-small) {
            font-size: 26px;
        }
    }
    .text-region {
        overflow: auto;
        .subtitle {
            font-size: 18px;
            color: var(--theme-color-2, $color-text-light-teal);
            font-weight: normal;
            margin-bottom: 0px;
            font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
        }
        .subtitle.dark {
            color: var(--theme-color-3, $color-text-teal);
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
        }
        .subtitle.large {
            font-size: 28px;
        }
        .text {
            font-size: 14px;
            line-height: 24px;
            .teal {
                color: var(--wl-input-border, $color-text-teal);
                font-weight: bold;
            }
            strong {
                text-transform: uppercase;
            }
        }
        .m-t-0 {
            margin-top: 0;
        }
    }
    .callout {
        position: relative;
        padding: 20px;
        margin-bottom: 20px;

        &.no-padding-bottom {
            padding-bottom: 0;
        }
        &.partner {
            background: $color-background-light-grey;

            .button {
                background: var(--theme-color-3, $color-background-light-grey);
                border-color: var(--theme-color-3);
                color: var(--wl-white);
            }
        }
        .callout-title {
            font-size: 26px;
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            color: var(--theme-color-3, $color-text-light-teal);
            margin: 0px;
        }
        .callout-error {
            color: $color-text-red;
        }
        .callout-text {
            font-size: 14px;
            line-height: 21px;
            &.callout-text-italic {
                font-style: italic;
            }
        }
        .text {
            font-size: 14px;
            line-height: 21px;
            .teal {
                color: var(--theme-color-2, $color-text-teal);
                font-weight: bold;
            }
            strong {
                text-transform: uppercase;
            }
        }
    }
    .yellow-background {
        background-color: var(--wl-grey, $color-background-yellow);
        border-radius: 8px;
        .button {
            margin-top: 10px;
            border: 1px solid;
            border-color: var(--theme-color-3, $color-text-light-teal);
            color: var(--theme-color-3, $color-text-light-teal);
            background-color: var(--wl-grey, $color-background-yellow);

            &:hover {
                background: var(--theme-color-3, darken($color-text-light-teal, 10%));
            }
        }
        .button:hover {
            @include media($breakpoint-large) {
                color: var(--wl-white, $color-text-white);
                background-color: var(--theme-color-3, $color-text-light-teal);
            }
            text-decoration: none;
        }
        a.button {
            display: inline-block;
        }
        .red {
            color: var(--theme-color-2, $color-text-red);
        }
        .green {
            color: var(--theme-color-1, $color-text-green);
        }
    }
    .grey-background {
        border-radius: 8px;
        background: none;
        border: 1px solid $color-lightish-grey;
        .large-content {
            margin-bottom: 70px;
        }
        a.orange-button {
            width: 150px;
            min-width: 210px;
        }
        iframe {
            border: 2px solid $color-background-grey !important; /* imporant because competing with third party scripts styles */
            padding: 5px;
            margin-bottom: 20px;
        }
    }
    .teal-background {
        background-color: var(--theme-color-3, $color-background-teal);
    }
    .edit-input-row {
        .edit-input {
            margin-top: 10px;
            border: 1px solid var(--wl-input-border, $color-text-light-teal);
            font-size: 14px;
            border-radius: 3px;
            padding: 8px;
            margin: 5px 0px;
            width: 50%;
            outline: none;
        }
        .edit-input-area {
            @include calc(width, '100% - 20px');
            margin-top: 10px;
            border: 1px solid var(--wl-input-border, $color-text-light-teal);
            font-size: 14px;
            border-radius: 3px;
            padding: 8px;
            margin: 5px 0px;
            outline: none;
        }
    }
    .edit-input-row.flex {
        display: flex;
        .edit-input-required {
            margin-top: 10px;
        }
    }

    .edit-input-required {
        color: $color-text-red;
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    .edit-required-text {
        font-size: 10px;
        color: $color-text-grey;
        display: inline-block;
        padding-right: 10px;
    }
    .edit-required-label {
        position: absolute;
        bottom: 30px;
        right: 20px;
    }

    .edit-dropdown {
        height: 42px;
        background-color: white;
        color: $color-text-light-grey;
    }

    &.no-margin {
        margin-bottom: 0px;
    }
}

.contact-us-title {
    margin-bottom: 30px !important;
}

.edit-input {
    border: 1px solid var(--wl-input-border, $color-text-light-teal);
    font-size: 14px;
    border-radius: 3px;
    padding: 8px;
    margin: 5px 0px;
    width: 50%;
    outline: none;
}
.edit-dropdown {
    height: 42px;
    background-color: white;
    color: $color-text-light-grey;

    &.selected {
        color: $color-text-black;
    }
}
