.forgot-password {
    text-align: right;
    margin-bottom: 62px;
    color: var(--theme-color-3, $color-background-teal-darkest-forgot-password);
    background-color: transparent;
    border-color: transparent;
    align-self: flex-end;
    font-size: 14px;
    padding: 0;
    min-height: 0;
    cursor: pointer;

    &.sso {
        margin-bottom: 15px;
    }
}

.privacy-policy {
    color: var(--theme-color-3, $color-background-teal-darkest);
}

.login-button {
    width: 100%;
    height: 60px;
    background: var(--theme-color-3, linear-gradient(to top, $color-button-golden, $color-button-golden-lighter 90%, #fbecc8));
    border: solid 1px var(--theme-color-3, $color-button-golden-darken);
    margin-bottom: 20px;
    font-weight: bold;
    cursor: pointer;
    color: var(--wl-white, black);
    &:hover {
        background: var(--theme-color-3-fade, linear-gradient(to top, $color-button-golden, $color-button-golden-lighter 90%, #fbecc8));
        border: solid 1px var(--theme-color-3-fade, $color-button-golden-darken);
    }
    &[disabled] {
        background: $color-background-dark-grey;
        border-color: $color-background-darker-grey;
        color: $color-text-black;
        cursor: not-allowed;
    }
}

.sso-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    border: 2px solid var(--theme-color-3, $color-background-teal-darkest);
    border-radius: 20px;
    .sso-login-link {
        color: var(--theme-color-3, $color-background-teal-darkest);
        font-weight: bolder;
        font-size: 24px;
    }
}

.register-link {
    color: var(--theme-color-3, $color-background-teal-darkest);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.create-account {
    text-align: left;
    margin-bottom: 62px !important;
    background-color: transparent;
    border-color: transparent;
    align-self: flex-end;
    font-size: 14px;
    padding: 0;
    min-height: 0;
    color: var(--theme-color-3, $color-background-teal-darkest);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
