.category-page {
    .category-header-wrapper {
        margin-top: 30px;
        .category-header-title {
            color: var(--theme-color-3, $color-text-teal);
            display: inline-block;
            font-size: 40px;
            font-weight: 400;
            letter-spacing: -0.038em;
            margin: 0;

            &.hide {
                display: none;
            }

            @include media($breakpoint-small) {
                display: block !important;
            }
        }

        .category-header-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include media($breakpoint-small) {
                display: none !important;
            }

            &.hide {
                display: none;
            }

            .category-header-background {
                clip-path: polygon(35% 0, 100% 0, 100% 100%, 15% 100%);
                background: $color-background-grey;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .category-header-first-column {
                width: 35%;
                position: relative;
                display: inline-block;
                z-index: 2;
                font-style: italic;
                padding-right: 10%;

                @include media($breakpoint-larger) {
                    width: 20%;
                    padding-right: 0;
                }

                &.partner {
                    margin-top: 10px;

                    p {
                        text-align: center;
                        margin: 0;
                    }

                    .category-header-first-column-title {
                        font-size: 31pt;
                        font-style: normal;
                        color: $color-text-darker-grey;
                        font-family: 'Bebas Neue', serif;
                    }

                    .category-header-starts {
                        padding: 0 10px;
                        margin-bottom: 10px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .category-header-second-column {
                width: 65%;
                display: inline-block;
                position: relative;
                z-index: 2;
                padding: 50px;
                padding-right: 100px;
                padding-left: 50px;

                @include media($breakpoint-larger) {
                    width: 80%;
                    padding-right: 200px;
                    padding-left: 300px;
                }

                .category-header-text {
                    display: inline-block;
                    font-weight: normal;
                    color: var(--theme-color-3, $color-text-teal);
                    margin: 0px;
                }
            }
        }
    }

    .category-pills-container {
        margin-top: 40px;

        @include media($breakpoint-small) {
            margin-top: 20px;
        }

        .category-pills-header {
            color: var(--theme-color-3, $color-text-teal);
            width: 100%;
            text-align: center;
            margin: 0;
        }

        .category-pills-list-wrapper {
            text-align: center;
            @include media($breakpoint-small) {
                overflow-x: auto;
            }

            .category-pills-list {
                display: inline-block;
                list-style: none;
                width: 70%;
                margin: auto;
                text-align: center;
                padding: 0;

                @include media($breakpoint-small) {
                    width: max-content;
                }

                .category-pill-name-wrapper {
                    padding: 8px;
                    display: inline-block;

                    .category-pill-name-wrapper-link {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .category-description {
        font-family: 'Open Sans', sans-serif;
        line-height: 28px;
        font-size: 16px;
        padding-bottom: 5px;
        @include span-columns(30);

        p {
            margin: 0;

            &:not(:first-child) {
                margin-top: 12px;
            }
            a {
                color: $color-text-teal;
                text-decoration: none;
                border-bottom: 1px dotted $color-text-teal;
                font-weight: bold;
                &:hover {
                    border-bottom: 1px solid $color-text-teal;
                    background: $color-background-light-grey2;
                }
            }
        }

        @include media($breakpoint-small) {
            display: none;
        }
    }
    .category-long-description-wrapper {
        margin-top: 13px;
        padding: 0 6px;
        margin-bottom: 30px;

        p {
            margin-top: 0px;
            margin-bottom: 14px;
            line-height: 28px;
            font-size: 15px;
        }
        a {
            color: var(--theme-color-3, $color-text-teal);
            text-decoration: none;
            border-bottom: 1px dotted $color-text-teal;
            font-weight: bold;
            &:hover {
                border-bottom: 1px solid $color-text-teal;
                background: $color-background-light-grey2;
            }
        }

        .category-long-description {
            margin-bottom: 15px;

            &.truncate {
                *:nth-child(n + 3) {
                    display: none;
                }
            }

            @include media($breakpoint-larger) {
                &.truncate {
                    * {
                        display: block !important;
                    }
                }
            }
        }

        @include media($breakpoint-small) {
            margin: 10px 0;
        }
    }
    .category-content-wrapper {
        @include span-columns(30);
        line-height: 24px;
    }
    .category-body-wrapper {
        display: none;
        @include media($breakpoint-large) {
            @include span-columns(24);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        margin-right: 0;
        float: right;
    }
    .category-body-wrapper.no-children {
        width: 100%;
    }
    .category-images-wrapper {
        .category-small-image-wrapper {
            display: block;
            width: 100%;
            .category-small-banner-image {
                width: 49%;
                display: inline-block;
                &:first-child {
                    margin-right: 2%;
                }
                &:hover {
                    opacity: 0.92;
                    border: 0;
                }
            }
        }
        .category-banner-image {
            width: 100%;
            height: 100%;
        }
    }
    .category-images-wrapper.no-children {
        @include span-columns(30);
        @include media($breakpoint-large) {
            .category-small-image-wrapper {
                @include span-columns(10);
                .category-small-banner-image {
                    width: 100%;
                    display: block;
                    &:first-child {
                        margin-bottom: 3.5%;
                    }
                }
            }
        }
    }
    .header-search {
        font-family: 'Open Sans', sans-serif;
        margin-top: 5px;
        border: 1px solid;
        border-radius: 3px;
        border-color: var(--theme-color-2, $color-button-background);
        background: var(--theme-color-2);
        position: relative;

        @include media($breakpoint-small) {
            display: inline-block;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 5px;
            padding-right: 10px;
        }
    }
}

.category-read-more {
    border: 1px solid $color-text-grey;
    border-radius: 3px;
    padding: 8px 20px;
    font-weight: normal;
    font-size: 15px;
    color: var(--theme-color-2, $color-text-grey);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        background: $color-text-grey;
        color: $color-text-white;
        border: 1px solid $color-text-darkish-grey;
        padding: 8px 22px;
    }

    @include media($breakpoint-small) {
        font-size: 12px;
    }
}
