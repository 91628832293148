@mixin apply-left-arrow-color($color) {
    border-top: 15px solid $color;
    border-left: 10px solid transparent;
    border-bottom: 15px solid $color;
    border-right: 10px solid $color;
}

@mixin apply-right-arrow-color($color) {
    border-top: 15px solid transparent;
    border-left: 10px solid $color;
    border-bottom: 15px solid transparent;
}

.checkout {
    padding-bottom: 100px;
}
.checkout-tabs {
    overflow: auto;
    margin-bottom: 30px;

    @include media($breakpoint-small) {
        display: none;
    }
}

.checkout-tab-container {
    &:first-child {
        .checkout-tab-wrapper {
            .checkout-tab-left {
                left: -10px;
            }
        }
    }
}

.checkout-tab-wrapper {
    position: relative;
    @include span-columns(7.5);
    left: 10px;
    min-width: 180px;
}

.checkout-tab-left {
    position: absolute;
    height: 100%;
    width: 10px;
    z-index: 2;
    top: 0;
    left: 0px;
    transition: none !important;

    @include apply-left-arrow-color(var(--wl-header-grey, $color-background-seafoam-shade));

    &.disabled {
        @include apply-left-arrow-color(var(--wl-header-grey, $color-background-grey));
    }
    &.active {
        @include apply-left-arrow-color(var(--wl-input-border, $color-background-teal-0));
    }
}

.checkout-tab-right {
    position: absolute;
    height: 100%;
    width: 10px;
    z-index: 2;
    top: 0;
    right: -10px;
    transition: none !important;

    @include apply-right-arrow-color(var(--wl-header-grey, $color-background-seafoam-shade));

    &.disabled {
        @include apply-right-arrow-color(var(--wl-header-grey, $color-background-grey));
    }
    &.active {
        @include apply-right-arrow-color(var(--wl-input-border, $color-background-teal-0));
    }
}

.checkout-tab {
    width: 100%;
    text-align: center;
    display: inline-block;
    background-color: var(--wl-header-grey, $color-background-seafoam-shade);
    color: var(--wl-input-border, $color-text-teal);
    border-top: 1px solid $color-background-seafoam-shade-border;
    border-bottom: 1px solid $color-background-seafoam-shade-border;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    transition: none !important;

    &.active {
        background-color: var(--wl-input-border, $color-background-teal-0);
        color: var(--wl-white, $color-text-white);
        border-top: 1px solid var(--theme-color-1, $color-text-teal);
        border-bottom: 1px solid var(--theme-color-1, $color-text-teal);
    }
    &.disabled {
        color: $color-text-teal;
    }
    &.partner {
        border: 0;
    }
}
.checkout-tab.disabled {
    background-color: var(--wl-header-grey, $color-background-grey);
    color: var(--wl-input-border, $color-text-grey);
    cursor: default;

    border-top: 1px solid $color-background-grey;
    border-bottom: 1px solid $color-background-grey;
}
.checkout-header {
    font-family: 'Open Sans', sans-serif;
    color: var(--wl-black, $color-text-teal);
    background-color: var(--wl-header-grey, $color-background-teal);
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    overflow: auto;

    @include media($breakpoint-small) {
        margin-top: 10px;
    }
    .half {
        @include span-columns(15);
        .checkout-coupon-input {
            color: $color-text-grey;
            vertical-align: middle;
            height: 35px;
            border: 1px solid var(--wl-input-border, $color-text-light-teal);
            border-radius: 3px;
            padding-left: 5px;
            font-size: 14px;
            margin: 0;
            @include media($breakpoint-small) {
                width: 50%;
                margin-right: 50%;
            }

            @include media($breakpoint-smaller) {
                width: 100%;
            }
        }
        .checkout-coupon-message {
            font-size: 14px;
            padding-left: 10px;
            display: inline-block;
            vertical-align: middle;
            color: var(--wl-black, $color-text-light-teal);
            &.error {
                color: $color-text-red;
            }

            @include media($breakpoint-small) {
                padding-left: 0px;
                margin-top: 10px;
            }
        }

        @include media($breakpoint-small) {
            @include span-columns(30);

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    @include media($breakpoint-small) {
        font-size: 24px;
        margin-bottom: 0px;
        padding-left: 10px;
    }
}
.checkout-shipping-address {
    @include media($breakpoint-large) {
        @include span-columns(10);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 24px;
    .checkout-address-text {
        font-size: 14px;
        margin-left: 10px;
    }
    .checkout-address-button-column {
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        margin-top: 10px;
        .checkout-address-button {
            font-family: 'Open Sans', sans-serif;
            color: var(--wl-white, $color-text-white);
            border: 0px;
            text-align: center;
            padding: 6px 0px;
            margin: 10px 0px;
            border-radius: 3px;
            font-size: 16px;
            cursor: pointer;
        }
        .checkout-address-edit-button {
            @include span-columns(15);
            font-family: 'Open Sans', sans-serif;
            text-align: center;
            background-color: $color-background-white;
            color: $color-text-black;
            border: 1px solid $color-divider-grey;
            padding: 6px 0px;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                border-color: $color-text-light-grey2;
                background-color: $color-divider-grey;
            }
        }
    }
}
.checkout-shipping-wrapper {
    @include media($breakpoint-large) {
        @include span-columns(8);
        margin-bottom: 50px;
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    margin-top: 20px;

    input[type='radio'] {
        min-height: auto;
    }
}

.checkout-shipping-spinner {
    display: block;
    margin-left: 20px;
    margin-bottom: 20px;
}
.checkout-shipping-option-label {
    display: inline-block;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 12px;
    font-size: 14px;
    margin-top: 0;
    line-height: 24px;
}
.checkout-shipping-button {
    @include media($breakpoint-large) {
        @include span-columns(20);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-top: 20px;
    color: var(--wl-white, $color-text-white);
    border: 0px;
    border-radius: 3px;
    padding: 6px 0px;
    cursor: pointer;
}

.plus-icon {
    width: 14px;
    height: 14px;
    fill: var(--theme-color-3, $color-text-blue-darken);
}

.shipping-callout {
    margin-bottom: 20px;
    font-family: Open Sans, sans-serif;
    margin-top: 20px;
    background-color: var(--wl-grey, $color-bg-blue-lighten);
    color: var(--wl-balck, $color-text-black);
    text-align: center;
    border-radius: 3px;
    padding: 10px;
    font-size: 16px;
    line-height: 26px;
    max-width: 30%;

    @include media($breakpoint-small) {
        max-width: 100%;
    }
}

.checkout-shipping-callout {
    @include media($breakpoint-large) {
        @include span-columns(10);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    background-color: $color-background-seafoam-shade;
    color: $color-text-black;
    text-align: center;
    border-radius: 3px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 16px;
    line-height: 26px;
}
.checkout-payment-header {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: var(--theme-color-3, $color-text-teal);
    font-family: 'Open Sans', sans-serif;

    @include media($breakpoint-small) {
        font-size: 24px;
    }
}

.checkout-shipping-header {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 30px;
    color: $color-text-grey;
    font-family: 'Open Sans', sans-serif;
}

.checkout-cards-wrapper {
    border-bottom: 1px solid $color-divider-grey;
}
.checkout-card-wrapper {
    @include media($breakpoint-large) {
        @include span-columns(15);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    @include omega();
    background-color: $color-text-white;
    border: 1px solid $color-lightish-grey;
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 20px;
    .checkout-check-paragraph {
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: 0;
        .teal {
            font-weight: bold;
            color: var(--theme-color-3, $color-text-teal);
        }
    }
    .checkout-check-content {
        font-size: 14px;
        margin-top: 0;
        line-height: 24px;
        margin-bottom: 10px;
    }
}

.checkout-value {
    display: inline;
    font-size: 24px;
    padding-left: 5px;
    color: var(--wl-black, $color-text-orange);
    font-weight: bold;
    text-align: flex-end;
}

.checkout-check-paragraph-link {
    color: var(--theme-color-3, $color-text-blue-darken);
}

.checkout-check-wrapper {
    @include span-columns(30);
    @include omega();
    background-color: $color-background-grey;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;

    .checkout-check-paragraph {
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 10px;
        .teal {
            font-weight: bold;
            color: var(--theme-color-3, $color-text-teal);
        }
    }
    .checkout-check-content {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
    }
}

.checkout-check-column {
    @include media($breakpoint-large) {
        @include span-columns(15 of 30);
    }

    @include media($breakpoint-small) {
        @include span-columns(30);

        &:first-of-type {
            margin-bottom: 15px;
        }
    }
}

.checkout-check-error {
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: $color-text-red;
}

.checkout-card-column {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;

    @include media($breakpoint-small) {
        width: 100%;

        &:first-of-type {
            margin-bottom: 10px;
        }
    }
}

.checkout-card-row {
    @include span-columns(30);
    @include row();
    .checkout-card-header {
        font-size: 14px;
        line-height: 24px;
        @include span-columns(30);
        color: $color-text-grey;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .checkout-card-header-title {
        @include span-columns(30);
    }

    .checkout-card-content {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        margin-top: 0;
        @include span-columns(30);
    }
}

.checkout-card-button {
    @include media($breakpoint-large) {
        @include span-columns(10);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    color: $color-text-white;
    border: 0px;
    border-radius: 3px;
    padding: 6px 0px;
    font-size: 14px;
    cursor: pointer;
}

.checkout-card-edit-button {
    @include span-columns(5);
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    background-color: $color-background-white;
    color: $color-text-black;
    border: 1px solid $color-divider-grey;
    border-radius: 3px;
    padding: 6px 0px;
    font-size: 14px;
    cursor: pointer;

    @include media($breakpoint-small) {
        @include span-columns(30);
        margin-top: 10px;
    }
}

.checkout-new-wrapper {
    @include row();
    margin-top: 20px;
    margin-bottom: 30px;

    @include media($breakpoint-small) {
        margin-top: 0px;
    }
}

.checkout-check-wrapper,
.checkout-new-wrapper {
    .checkout-new-half {
        position: relative;
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        background-color: $color-background-grey;
        padding: 20px;

        .SA__more_review {
            padding-right: 0px;

            @include media($breakpoint-large) {
                margin-top: 220px;
            }
        }
    }
    .checkout-new-full {
        @include span-columns(30);
        background-color: $color-background-grey;
        padding: 20px;
        position: relative;
    }
    .checkout-new-column {
        @include media($breakpoint-large) {
            @include span-columns(15);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        &.checkbox-row {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 10px;
        }
        &.checkbox-label {
            margin-bottom: 2px;
            margin-left: 5px;
        }
        &.custom-payment-flow-description {
            min-height: 42px;
        }
    }
    .checkout-new-header {
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        color: $color-text-darker-grey;

        @include media($breakpoint-small) {
            font-size: 20px;
        }
    }
    .checkout-new-error {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $color-text-red;
    }
    .checkout-new-input {
        border: 1px solid var(--wl-input-border, $color-text-light-teal);
        border-radius: 3px;
        padding: 8px;
        margin: 5px 0px;
        @include calc(width, '100% - 20px');
        display: inline-block;
    }
    .checkout-new-input-no-padding {
        height: 39px;
    }
    .checkout-new-select-input {
        border-radius: 3px;
        margin: 5px 0px;
        @include calc(width, '100% - 20px');
        display: inline-block;
        font-size: 14px;
        height: 39px;
        vertical-align: middle;
        .Select__control {
            border: 1px solid var(--wl-input-border, $color-text-light-teal);
            border-radius: 3px;
            height: 39px;

            .Select__value-container {
                height: 39px;
                padding-left: 8px;
                line-height: 39px;
                position: static;

                .Select__input,
                .Select__single-value,
                .Select__placeholder {
                    height: 39px;
                    padding-left: 8px;
                    line-height: 39px;
                }
            }
        }
    }
    .checkout-expirate-date-wrapper {
        display: inline-block;
        vertical-align: middle;
        @include calc(width, '67% - 20px');
        .checkout-new-select-input.half {
            @include span-columns(15);
        }
    }
    .checkout-new-select-input.has-value {
        .Select__placeholder {
            color: $color-text-black;
        }
    }
    .checkout-new-select-input.has-value.is-focused {
        .Select__placeholder {
            color: $color-text-grey;
        }
    }

    .checkout-new-required {
        color: $color-text-red;
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .checkout-new-required-text {
        font-size: 10px;
        color: $color-text-grey;
        display: inline-block;
        padding-right: 10px;
    }
    .checkout-new-required-label {
        position: absolute;
        bottom: 20px;
        right: 20px;

        @include media($breakpoint-small) {
            bottom: 60px;
        }
    }
    .checkout-new-button {
        @include media($breakpoint-large) {
            @include span-columns(12);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
            margin-top: 45px;
        }
        font-family: 'Open Sans', sans-serif;
        color: $color-text-white;
        border: 0px;
        padding: 6px 0px;
        font-size: 16px;
        border-radius: 3px;
        margin-top: 10px;
        cursor: pointer;
    }
}

.checkout-confirm-text {
    font-size: 14px;
    margin-top: 0;
    line-height: 24px;
    margin-bottom: 10px;
}
.checkout-confirm-text.teal {
    color: var(--theme-color-3, $color-text-light-teal);
    font-weight: bold;
    text-transform: uppercase;
}
.checkout-confirm-column {
    padding-bottom: 15px;
    padding-left: 20px;
    padding-top: 15px;
    position: relative;

    @include flex-grow(1);
    width: 50%;

    .checkout-confirm-shipping {
        font-size: 14px;
        line-height: 21px;
        color: $color-text-light-teal;
    }

    &:first-child {
        border-right: 1px solid $color-background-grey;
    }

    @include media($breakpoint-smaller) {
        @include span-columns(30);
        padding-left: 0px;
        &:first-child {
            border-right: none;
            border-bottom: 1px solid $color-background-grey;
        }
    }
}

.checkout-confirm-column-wrapper {
    border-bottom: 5px solid $color-background-grey;
    border-top: 5px solid $color-background-grey;
    @include display(flex);

    @include media($breakpoint-smaller) {
        @include flex-direction(column);
        border-bottom: none;
    }
}

.checkout-confirm-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px !important;
    margin-top: 20px;
    background-color: $color-background-white;
    color: $color-text-darker-grey;
    border: 1px solid $color-divider-grey;
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
    margin-right: 5px;

    @include media($breakpoint-small) {
        margin-top: 0px;
        @include span-columns(30);

        &:last-child {
            margin-top: 5px;
        }
    }
}

.order-complete-total-wrapper {
    width: 100%;
    text-align: right;
    padding-right: 30px;
    margin-bottom: 45px;
}

.order-complete-total-title {
    color: $color-highlight-orange;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
}

.order-complete-total-value {
    text-align: right;
    margin-left: 15px;
    font-size: 24px;
    color: $color-highlight-light-orange;
    font-weight: bold;
}

.order-complete-continue-shopping {
    color: $color-text-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid $color-background-grey;
    margin-bottom: 30px;
    width: 200px;
    display: block;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    @include media($breakpoint-small) {
        margin-top: 20px;
    }
}

.order-complete-header {
    background-color: var(--wl-grey, $color-background-grey);
    color: var(--theme-color-3, $color-text-teal);
    font-size: 30px;
    font-family: 'Open Sans', sans-serif;
    padding: 15px;

    @include media($breakpoint-small) {
        font-size: 24px;
    }
}

.order-complete-body {
    @include outer-container();
    padding-top: 20px;
    margin-bottom: 30px;
}

.order-complete-follow-up-text {
    @include span-columns(6 of 12);
    font-size: 14px;
    line-height: 21px;
    padding-left: 20px;
    padding-right: 20px;

    p {
        margin-top: 0;
    }

    @include media($breakpoint-small, 12) {
        @include span-columns(12 of 12);
    }
}

.order-complete-feedback-wrapper {
    @include span-columns(6 of 12);
    background: var(--theme-color-2-fade, $color-background-blue);
    background: var(--theme-color-2-fade, -moz-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%));
    background: var(--theme-color-2-fade, -webkit-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%));
    background: var(--theme-color-2-fade, -o-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%));
    background: var(--theme-color-2-fade, -ms-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%));
    background: var(--theme-color-2-fade, linear-gradient(180deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%));
    padding: 20px;

    @include media($breakpoint-small, 12) {
        @include span-columns(12 of 12);
    }
}

.order-complete-feedback-header {
    color: var(--theme-color-2, $color-text-light-teal);
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
}

.order-complete-feedback-message {
    font-size: 14px;
    line-height: 21px;
    color: $color-text-darker-grey;
}
.order-complete-feedback-error {
    font-size: 14px;
    line-height: 21px;
    color: $color-text-red;
}

.order-complete-feedback-icons-wrapper {
    margin-bottom: 10px;
}

.order-complete-feedback-icon-option-pair {
    display: inline-block;
}

.order-complete-feedback-icon-option {
    width: 75px;
    padding: 10px;
    display: inline-block;
    cursor: pointer;

    &:hover,
    &.selected-icon-option {
        background-color: $color-background-white;

        .order-complete-feedback-icon-option-title {
            color: $color-highlight-light-orange;
        }
    }

    @include media($breakpoint-smaller) {
        width: 60px;
    }
}

.order-complete-feedback-icon {
}

.order-complete-feedback-icon-option-title {
    color: $color-text-teal;
    font-weight: bold;
    text-align: center;
}

.order-complete-feedback-field {
    width: 100%;
    border: 1px solid $color-text-teal;
    resize: none;
    padding: 5px;
    height: 75px;
    margin-bottom: 15px;
}

.order-complete-feedback-send {
    background-color: $color-highlight-light-orange;
    border: 1px solid $color-highlight-light-orange;
    color: $color-text-white;
    width: 150px;
    text-align: center;
    cursor: pointer;
    float: right;
    &:hover {
        text-decoration: none;
        @include media($breakpoint-large) {
            background-color: $color-highlight-orange;
            border: 1px solid $color-highlight-orange;
        }
    }

    @include media($breakpoint-smaller) {
        width: 100%;
    }
}
.checkout-need-terms {
    color: $color-text-red;
}

.checkout-card-button,
.checkout-address-button,
.checkout-shipping-button,
.checkout-new-button {
    background-color: var(--theme-color-3, $color-button-background);
    &:hover {
        background-color: var(--theme-color-3-fade, $color-highlight-orange);
    }
}

.checkout-confirm-button {
    &:hover {
        border-color: $color-text-light-grey2;
        background-color: $color-divider-grey;
    }
}

.checkout-confirm-information-section {
    border-top: 5px solid $color-background-grey;

    @include display(flex);
    @include align-items(stretch);
    @include justify-content(space-between);

    @include media($breakpoint-small) {
        border-top: none;
    }

    @include media($breakpoint-smaller) {
        @include flex-direction(column);
    }
}

.checkout-confirm-content {
    margin-bottom: 75px;

    @include media($breakpoint-small) {
        margin-bottom: 100px;
    }

    @include media($breakpoint-smaller) {
        margin-bottom: 30px;
    }
}

.checkout-confirm-buttons {
    position: absolute;
    bottom: 30px;
    left: 20px;

    @include media($breakpoint-small) {
        margin-right: 20px;
    }

    @include media($breakpoint-smaller) {
        position: relative;
        bottom: 0px;
        left: 0px;
        @include span-columns(30);
    }
}

.cart-checkout-pending-text {
    color: $color-text-grey;
    margin-bottom: 15px;
    text-align: right;
}

.tax-exempt-disclaimer-row {
    text-align: right;
    margin-bottom: 20px;
}

.checkout-confirm-total-row,
.tax-exempt-row {
    @include span-columns(9);
    @include shift(21);

    .error {
        color: $color-text-red;
        text-align: right;
        display: inline-block;
        width: 100%;
        font-size: 16px;
    }

    .checkout-confirm-total-label,
    .checkout-confirm-tax-exempt-label {
        @include span-columns(15);
        text-align: right;
        display: inline;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: var(--wl-black, $color-text-orange);
        &.bold {
            font-weight: bold;
            line-height: 29px;
        }
    }
    .checkout-confirm-tax-exempt-label {
        font-weight: bold;
    }
    .checkout-confirm-total,
    .checkout-confirm-tax-exempt-checkbox {
        @include span-columns(15);
        display: inline;
        font-size: 24px;
        padding-left: 5px;
        color: var(--wl-black, $color-text-orange);
        font-weight: bold;
        text-align: right;
    }
    .checkout-confirm-tax-exempt-checkbox {
        line-height: 10px;
    }

    @include media($breakpoint-small) {
        @include span-columns(30);
        @include shift(0);

        margin-bottom: 20px;

        .checkout-confirm-total-label {
            text-align: left;
            padding-left: 10px;
        }
    }
}

.cart-checkout-confirm-total-label,
.order-complete-total-title {
    display: inline;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-right: 30px;
    color: $color-text-grey;
}

.cart-checkout-wrapper {
    .checkbox {
        display: inline-block;
        min-height: initial;
        width: 20px;
        height: 20px;

        @include media($breakpoint-small) {
            width: 15px;
            height: 15px;
        }
    }
}

.checkout-confirm-agree-wrapper {
    margin-top: 20px;
    @include span-columns(30);
    @include outer-container();
    .cart-checkout-button {
        display: inline-block;
    }

    .cart-terms {
        @include span-columns(20);

        @include media($breakpoint-small) {
            @include span-columns(30);
            text-align: left;
        }

        float: none;
        display: inline-block;
        margin-left: 0px;
        text-align: right;
        margin-bottom: 15px;
    }

    .full-cart-terms {
        @include media($breakpoint-small) {
            display: none;
        }
    }

    .mobile-cart-terms {
        @include media($breakpoint-large) {
            display: none;
        }
    }
}

.upload-art-spinner {
    width: 20px;
    height: 20px;
    margin: auto;
    .sk-fading-circle {
        height: 14px;
        width: 14px;
    }
    .sk-fading-circle .sk-circle:before {
        width: 15%;
        height: 25%;
    }
}
