.modal-fade-screen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -100000;
}

.modal-container {
    font-family: 'Open Sans', sans-serif;
    z-index: $z-index-header;

    @include media($breakpoint-small) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }

    &.large {
        .centered-modal {
            width: 900px;

            @include media($breakpoint-small) {
                width: 100%;
            }
            .modal-body {
                width: 100%;
            }
        }
    }
}

.dropdown-container {
    text-align: left;
}
.modal-fade-screen.open {
    z-index: 99999;
    background-color: rgba(90, 90, 90, 0.8);
}

.modal {
    position: relative;
    z-index: 100000;
    top: -4px;
    font-family: 'Open Sans', sans-serif;
}

.centered-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 14px 1px rgba(51, 51, 51, 0.71);

    @include media($breakpoint-small) {
        position: absolute;
        top: 0px;
        height: auto;
        -webkit-transform: initial;
        transform: translate(0, 90px);
        max-height: initial;
        width: 80%;
        left: 10%;
    }

    @include media($breakpoint-smaller) {
        width: 100%;
        left: 0px;
    }
}

.modal-link {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    color: $color-text-black;

    &:hover {
        color: $color-text-teal;
        background-color: $color-background-teal;
        text-decoration: none;
    }

    .partner &:hover {
        text-decoration: underline;
        color: $color-text-black;
        background-color: initial;
    }
}

.modal-category-wrapper {
    font-family: 'Open Sans', sans-serif;
    z-index: 100000;
    padding: 20px;
    position: absolute;
    max-width: 1298px;
    left: 11px;
    right: 11px;
    width: calc(100% - 22px);
    background-color: var(--theme-color-1, $color-text-teal);

    &.align-right {
        left: 11px;
        right: 11px;
    }

    &.dropdown-item-small {
        right: 0;
        left: auto;
        width: 130%;
        min-width: 241px;
        padding: 0;

        .dropdown-your-account-wrapper {
            margin-top: 0;
        }
    }

    .modal-category-group {
        overflow: auto;
        border-bottom: 2px solid $color-background-grey;
        background-color: $color-background-white;
        padding: 10px;

        h1 {
            font-weight: normal;
        }
    }

    .dropdown-your-account-wrapper .modal-category-group {
        line-height: 30px;
        padding: 0;
    }

    .modal-category-column {
        @include span-columns(5);
        @include omega(6n);
    }

    .modal-category-title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 5px;
        margin-top: 10px;
        display: block;

        &:hover {
            @include media($breakpoint-large) {
                text-decoration: none;
                color: $color-highlight-light-orange;
            }
        }
    }
    .modal-text-maroon {
        color: $color-text-maroon;
    }

    .partner & .modal-text-maroon {
        color: var(--theme-color-3);
        &:hover {
            color: var(--theme-color-3);
            text-decoration: underline;
        }
    }

    .modal-text-light-teal {
        color: $color-text-light-teal;
    }

    .modal-category-subtitle {
        font-size: 14px;
        padding-bottom: 5px;
        display: block;
        color: $color-text-black;

        &.bold {
            font-weight: bold;
        }

        &:hover {
            @include media($breakpoint-large) {
                text-decoration: none;
                color: $color-highlight-light-orange;
            }
        }
    }

    .partner & .modal-category-subtitle {
        &:hover {
            @include media($breakpoint-large) {
                text-decoration: underline;
                color: $color-text-black;
            }
        }
    }

    .modal-supercategory-group {
        width: 100%;
        overflow: auto;
        padding-bottom: 10px;
        background-color: $color-background-white;
        padding: 10px;
    }

    .modal-supercategory-column {
        @include span-columns(15);
    }

    .modal-supercategory-subcolumn {
        @include span-columns(10);
    }
}

.dropdown-your-account-container {
    position: absolute;
    text-align: left;
    line-height: 30px;
    @include media($breakpoint-header-small) {
        width: 16.548%;
    }
    @include media($breakpoint-header-large) {
        width: 16.57%;
    }
    margin-left: -1px;
    margin-top: -6px;
}
.dropdown-your-account-wrapper {
    margin-top: 5px;
    background-color: $color-background-white;
    border-left: 1px solid $color-header-divider;
    border-right: 1px solid $color-header-divider;
    border-bottom: 1px solid $color-header-divider;
    font-family: 'Open Sans', sans-serif;
}
.dropdown-need-help-wrapper {
    position: fixed;
    z-index: -1;
    margin-left: -8px;
    margin-top: 8px;
    background-color: $color-background-white;
    border: 2px solid $color-divider-grey;
    font-family: 'Open Sans', sans-serif;
}

.autocomplete-wrapper {
    position: absolute;
    top: -100% !important;
    @include media($breakpoint-small) {
        position: absolute;
    }
    background-color: $color-background-white;
    border: 1px solid $color-divider-grey;
    margin-left: -10px;
    font-family: 'Open Sans', sans-serif;
    z-index: 1;
    .autocomplete-result-row {
        padding: 2px 10px;
        cursor: pointer;
    }
    .autocomplete-result-row:hover {
        background-color: $color-background-teal;
        color: $color-text-light-teal;

        .partner & {
            color: $color-text-black;
            background-color: $color-background-light-grey4;
            font-weight: bold;
        }
    }
    .autocomplete-result {
        color: $color-text-black;
        @include display(flex);
        @include align-items(center);
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .autocomplete-result:hover {
        text-decoration: none;
    }

    .autocomplete-result-thumb {
        height: 40px;
        width: 40px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .autocomplete-result-text {
        margin-left: 10px;
    }

    .autocomplete-result-section-title {
        line-height: 40px;
        margin-left: 10px;
        font-size: 16px;
        color: var(--theme-color-3, $color-text-teal3);
        margin-right: 10px;
    }
}

.autocomplete-result-row-selected {
    background-color: $color-background-teal;
    color: $color-text-light-teal;
}

.modal-error {
    font-size: 14px;
    color: $color-text-red;
    &.narrow {
        max-width: 400px;
    }
}

.edit-modal-wrapper {
    background-color: $color-background-white;
    @include media($breakpoint-small) {
    }
    @include media($breakpoint-large) {
        min-width: 400px;
    }

    .modal-wide {
        width: 620px;
        @include media($breakpoint-small) {
            width: 100%;
        }

        .modal-wide-content-wrapper {
            margin-top: 20px;
        }
        .modal-wide-column {
            @include span-columns(15);
            @include media($breakpoint-small) {
                @include span-columns(30);
                margin-bottom: 15px;
            }
        }
        .modal-wide-column-offset {
            @include shift(15);
            @include media($breakpoint-small) {
                @include shift(0);
            }
        }
    }
    .modal-title {
        padding: 10px 20px;
        font-size: 16px;
        color: var(--theme-color-2, $color-text-teal);
        background-color: var(--theme-color-1, $color-background-teal);

        h2 {
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            display: inline;
        }

        .modal-close {
            float: right;
            min-height: 20px !important;
            width: 20px;
            height: 20px;
            border: none;

            @include mask-image('../../public/images/close.svg');
            background-color: $color-text-teal;
            background-image: none;
        }
    }

    .modal-subtitle {
        color: $color-text-light-grey;
        font-weight: bold;
    }

    .modal-body-wrapper {
        padding: 10px 20px 20px 20px;
        overflow: auto;
    }

    .modal-body {
        max-height: 500px;
        width: 510px;
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
    }

    .modal-input {
        border: 1px solid $color-divider-grey;
        font-size: 14px;
        border-radius: 3px;
        padding: 8px;
        margin: 5px 0px;
        width: 100%;
        display: block;
        outline: none;
        &.checkbox-label {
            @include calc('width', '100% - 20px');
            vertical-align: middle;
            display: inline-block;
        }

        &.checkout-new-input-no-padding {
            height: 39px;
        }
    }

    .modal-checkbox {
        vertical-align: middle;
        padding: 10px;
    }

    .modal-select-input {
        border-radius: 3px;
        margin: 5px 0px;
        width: 100%;
        height: 39px;
        display: block;
        font-size: 14px;
        outline: none;
        .Select__control {
            border: 1px solid $color-divider-grey;
            border-radius: 3px;
            height: 39px;
            .Select__value-container {
                height: 34px;
                padding-left: 8px;
                line-height: 34px;
                position: static;

                .Select__input,
                .Select__single-value,
                .Select__placeholder {
                    height: 34px;
                    padding-left: 8px;
                    line-height: 34px;
                }

                .Select__placeholder {
                    color: $color-text-grey;
                }
            }
        }
        &.checkbox-label {
            @include calc('width', '100% - 20px');
            vertical-align: middle;
            display: inline-block;
        }
        &.half {
            @include span-columns(15);
        }
    }
    .modal-select-input.grey-border {
        border: 1px solid $color-divider-grey;
    }
    .modal-select-input.has-value {
        .Select__placeholder {
            color: $color-text-black;
        }
    }
    .modal-select-input.has-value.is-focused {
        .Select-placeholder {
            color: $color-text-grey;
        }
    }
    .modal-button-offset {
        @include shift(10);
        @include media($breakpoint-small) {
            @include shift(0);
        }
    }
    .modal-button-float {
        float: right;
        @include media($breakpoint-small) {
            float: none;

            .button {
                margin-left: 0px;
                margin-top: 10px;
            }
        }
    }
    .modal-button-wrapper {
        @include span-columns(10);
        margin-top: 10px;

        @include media($breakpoint-small) {
            @include span-columns(30);
        }
    }
    .modal-button-wrapper-half {
        @include span-columns(15);
        margin-top: 10px;

        @include media($breakpoint-small) {
            @include span-columns(30);
        }
    }
    .modal-button-blue {
        color: $color-text-white;
        background-color: $color-background-dark-blue;
        border: 1px solid $color-background-dark-blue;

        &:hover {
            @include media($breakpoint-large) {
                background-color: $color-background-darker-blue;
                border-color: $color-background-darker-blue;
            }
        }
    }
    .modal-button-orange {
        color: var(--wl-white, $color-text-white);
        background-color: var(--theme-color-3, $color-highlight-light-orange);
        border: 1px solid var(--theme-color-3, $color-highlight-light-orange);

        &:hover {
            @include media($breakpoint-large) {
                background-color: var(--theme-color-3-fade, $color-highlight-orange);
                border-color: var(--theme-color-3-fade, $color-highlight-orange);
            }
        }

        &.disabled {
            background-color: $color-background-dark-grey;
            border-color: $color-background-darker-grey;
            color: $color-text-black;
            cursor: not-allowed;
        }
    }
    .modal-button-white {
        color: $color-text-black;
        background-color: $color-background-white;
        border: 1px solid $color-divider-grey;

        &:hover {
            @include media($breakpoint-large) {
                border-color: $color-text-light-grey2;
                background-color: $color-divider-grey;
            }
        }
    }

    .modal-button-primary,
    .modal-button-blue,
    .modal-button-orange,
    .modal-button-white {
        @include media($breakpoint-small) {
            width: 100%;
        }
    }

    .modal-button-margin-left {
        margin-left: 10px;
    }
    .modal-button-margin-top {
        margin-top: 10px;
    }
    .full-width {
        width: 100%;
    }
    .modal-close-button {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 10px;
        display: block;
        cursor: pointer;
        overflow: hidden;
        @include close-mask(var(--theme-color-2, $color-text-teal));
    }

    .modal-image-wrapper {
        padding: 5px 10px;

        .modal-image {
            width: 150px;
            height: 150px;
            border: none;
            object-fit: contain;
        }
    }
}

.mobile-search-page-bar {
    @include media($breakpoint-large) {
        display: none;
    }
}

.modal-checkbox-with-feild {
    @include display(flex);
    @include align-items(center);
}

.hover-modal {
    position: absolute;
    z-index: 10000;
    -webkit-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--wl-grey, $color-text-light-teal);
    border-radius: 3px;
    line-height: 24px;
    max-width: 400px;

    .hover-modal-text {
        font-size: 14px;
    }
    &.hover-modal-sales-tax {
        margin-top: 20px;
        @include media($breakpoint-small) {
            right: 60px;
        }
        @include media($breakpoint-large) {
            right: 10px;
        }
    }
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 120ms ease-in;
}

.sample-modal-chat {
    @include outer-container();
    border-bottom: 1px solid $color-divider-dark-grey;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.sample-modal-chat-lady {
    @include media($breakpoint-large) {
        @include span-columns(4.5 of 12);
    }
    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
        margin-bottom: 10px;
        text-align: center;
    }
    cursor: pointer;
}

.sample-modal-chat-text {
    @include media($breakpoint-large) {
        @include span-columns(7.5 of 12);
    }
    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
    }
    line-height: 22px;
}

.sample-modal-chat-text-teal {
    color: $color-text-teal;
    font-weight: bold;
}

.sample-modal-text {
    margin-bottom: 7px;
    line-height: 22px;
}
