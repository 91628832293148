.faq-wrapper {
    background-color: $color-background-grey;
    overflow: auto;
    .faq-questions-column {
        @include span-columns(10);

        .faq-question-button {
            display: block;
            width: 100%;
            border-top: 1px solid $color-background-grey;
            border-bottom: 1px solid $color-background-grey;
            border-left: 0px;
            border-right: 0px;
            border-radius: 0px;
            background-color: $color-background-white;
            text-align: left;
            padding: 5px;
            outline: none;
            font-size: 14px;
            line-height: 21px;
            cursor: pointer;
        }
        .faq-current-question-button {
            display: block;
            width: 100%;
            color: $color-highlight-light-orange;
            background-color: $color-background-grey;
            border-top: 1px solid $color-background-grey;
            border-bottom: 1px solid $color-background-grey;
            border-left: 0px;
            border-right: 0px;
            border-radius: 0px;
            text-align: left;
            padding: 5px;
            font-size: 14px;
            line-height: 21px;
            outline: none;
            cursor: pointer;
        }

        .faq-question-button:hover {
            @include media($breakpoint-large) {
                color: $color-highlight-light-orange;
            }
        }
    }
    .faq-answers-column {
        @include span-columns(20);
        padding: 5px;
        font-size: 14px;
        line-height: 21px;
    }
}

.faq-title {
    margin-bottom: 45px !important;
}

.faq-selected-container {
    @include outer-container;
    border-bottom: 1px solid $color-background-dark-grey;
    margin-bottom: 30px;
}

.faq-selected-image {
    border: 1px solid #999;
    padding: 10px;
}

.faq-selected-wrapper {
    @include span-columns(8 of 12);

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
    }

    img {
        border: 1px solid #999;
        padding: 10px;
    }
}

.faq-selected-need-help {
    @include span-columns(4 of 12);

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
    }
}

.faq-selected-title {
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: var(--theme-color-3, $color-text-light-teal);
    margin: 0px;
}

.faq-selected-text {
    margin-bottom: 30px;
    color: $color-text-darker-grey;
    span,
    p {
        font-size: 16px;
        color: $color-text-black;
        line-height: 21px;
    }

    a {
        font-weight: bold;
        border-bottom: 1px dotted;
        font-size: 15px;
        letter-spacing: 0.02em;
        color: var(--theme-color-2, $color-highlight-light-orange);
        padding: 1px 2px;
        &:hover {
            text-decoration: none;
            border-bottom: 1px solid;
            color: var(--theme-color-2, $color-text-teal2);
            background: var(--theme-color-2-fade, $color-background-grey);
        }
    }
}

.faq-section {
    padding: 0px !important;
    min-height: 0px !important;
    margin-bottom: 20px;
}

.faq-section-title {
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: var(--theme-color-2, $color-text-light-teal);
    margin: 0px;
    margin-bottom: 10px;
    padding-left: 5px;
}

.faq-selected-subsection-title {
    font-size: 16px;
    font-weight: bold;
    color: $color-text-light-teal;
}

.faq-sections-container {
    @include outer-container;
}

.faq-section-column {
    @include span-columns(4 of 12);

    @include media($breakpoint-small) {
        @include span-columns(12 of 12);
    }
}

.faq-section-question {
    color: $color-text-darker-grey;
    padding: 8px 5px;
    border-radius: 3px;
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background: var(--wl-white, $color-background-blue);
        color: var(--theme-color-3, $color-text-teal);
    }
}

.faq-section-question-link {
    &:hover {
        text-decoration: none !important;
    }
}

.faq-need-help {
    background-color: $color-background-yellow-orange;
    padding: 20px;
    margin-bottom: 30px;
    border: 2px solid #fff;
    border-radius: 3px;
    -webkit-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -7px 7px 6px 0px rgba(0, 0, 0, 0.1);

    &.partner {
        background-color: $color-background-light-grey;
    }
}

.faq-need-help-title {
    text-transform: uppercase;
    color: var(--theme-color-1, $color-background-teal-dark);
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
}

.faq-need-help-text {
    margin: 0;
    margin-bottom: 10px;
    color: $color-text-black;
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
}

.faq-need-help-buttons {
    @include outer-container;
}

.faq-need-help-button {
    @include span-columns(6 of 12);
    color: var(--wl-white, $color-background-dark-blue);
    background-color: var(--theme-color-3, $color-background-dark-blue);
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--theme-color-3, $color-background-darker-blue);

    &:hover {
        background-color: var(--theme-color-3-fade, $color-background-darker-blue);
        text-decoration: none;
    }
}

.faq-need-help-button-link:hover {
    text-decoration: none;
}

.faq-need-help-button-text {
    color: var(--wl-white, $color-text-white);
    font-size: 14px;
    text-align: center;
}
