.cart {
    margin-bottom: 100px !important;

    @include media($breakpoint-larger) {
        .cart-items-wrapper {
            padding-right: 10px;
            margin-right: -10px;
            max-height: $max-heigth-cart-items-section;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: $color-lightish-grey $color-background-grey;

            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-track {
                background: $color-background-grey;
                border-right: 1px solid $color-background-white;
                border-left: 1px solid $color-background-white;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-lightish-grey;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-button:single-button {
                height: 16px;
            }
        }
    }
}

.cart-header {
    font-family: 'Open Sans', sans-serif;
    color: var(--theme-color-3, $color-text-teal);
    font-size: 36px;
    font-weight: lighter;
    margin-bottom: 30px;

    @include media($breakpoint-small) {
        font-size: 28px;
    }
}
.cart-item-wrapper {
    overflow: auto;
    border-top: 5px solid $color-background-grey;

    @include media($breakpoint-small) {
        border-top: none;
    }

    margin-bottom: 20px;
    padding-top: 30px;
    .cart-item-thumbnail-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(5);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
            margin-bottom: 20px;
        }
        @include omega();
        text-align: center;
        .cart-item-thumbnail {
            height: 150px;
            margin: auto;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &.disabled {
                filter: grayscale(100);
                -webkit-filter: grayscale(100);
            }
        }

        .cart-item-button-wrapper {
            margin-top: 30px;
            width: 100%;
            .cart-item-button {
                width: 100%;
                background-color: $color-background-white;
                border: 1px solid $color-divider-dark-grey;
                padding-top: 8px;
                padding-bottom: 8px;
                margin: 5px 0px;
                cursor: pointer;
                font-size: 14px;
                &:hover {
                    border-color: $color-text-light-grey2;
                    background-color: $color-divider-grey;
                }
            }
        }
    }

    .cart-item-content-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(25);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        .cart-item-header-wrapper {
            @include row();
            border-bottom: 1px solid $color-background-grey;
            padding-bottom: 10px;
            .cart-item-header {
                @include span-columns(21);

                @include media($breakpoint-small) {
                    @include span-columns(30);
                }
                .cart-item-title {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 30px;
                    color: var(--theme-color-3, $color-text-light-teal);
                    font-weight: lighter;
                    text-decoration: none;
                    @include media($breakpoint-small) {
                        font-size: 24px;
                    }
                    &.disabled {
                        color: $color-text-grey;
                    }
                }
                .cart-item-subtitle {
                    font-size: 14px;
                    letter-spacing: 0.02em;
                    color: $color-text-grey;
                }
            }
        }
        .cart-item-body-wrapper {
            @include media($breakpoint-large) {
                @include span-columns(21);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            padding-top: 10px;
            vertical-align: baseline;
            line-height: 22px;
            .cart-body-row {
                @include row();
                margin-bottom: 15px;
                .cart-body-title {
                    @include span-columns(8);
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    color: $color-text-grey;

                    @include media($breakpoint-small) {
                        @include span-columns(15);
                    }
                }
                .cart-body-none {
                    @include span-columns(22);
                    text-transform: uppercase;
                    letter-spacing: 0.02em;
                    color: $color-text-darkish-grey;
                    font-size: 11px;
                    @include media($breakpoint-small) {
                        @include span-columns(15);
                    }
                }
                .cart-body-text {
                    @include span-columns(22);
                    font-size: 14px;
                    @include media($breakpoint-small) {
                        @include span-columns(15);
                    }
                    .cart-body-text-item {
                        display: block;
                    }
                }
                .cart-body-text-teal {
                    @include span-columns(22);
                    color: var(--wl-black, $color-text-light-teal);
                    font-style: italic;
                    font-size: 14px;
                }
            }
        }
        .cart-item-calculator {
            @include media($breakpoint-large) {
                @include span-columns(9);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            background-color: $color-background-grey;
            padding: 10px;
            line-height: 22px;
            vertical-align: baseline;
            border-radius: 3px;
            background: $color-header-background;
            border: 1px solid $color-background-grey;
            border-top: 0;
            .cart-calculator-row {
                @include row();
                margin-bottom: 15px;
                .cart-calculator-title {
                    @include span-columns(15);
                    font-family: 'Open Sans', sans-serif;
                    display: inline-block;
                    text-transform: uppercase;
                    letter-spacing: 0.02em;
                    color: $color-text-darkish-grey;
                    font-size: 11px;
                    text-align: right;

                    &.teal {
                        color: var(--theme-color-2, $color-background-teal-darkest);
                    }
                    svg {
                        circle {
                            fill: var(--theme-color-3, $color-text-teal);
                        }
                    }
                }
                .cart-calculator-text {
                    @include span-columns(15);
                    display: inline-block;
                    font-size: 14px;
                    text-align: right;

                    &.teal {
                        color: var(--theme-color-2, $color-background-teal-darkest);
                        font-weight: bold;
                    }
                }
                .cart-calculator-total {
                    @include span-columns(15);
                    display: inline-block;
                    font-weight: bold;
                    font-size: 16px;
                    text-align: right;
                    color: var(--wl-black, $color-text-orange);
                }
            }
        }
    }
}

.cart-item-info-panel {
    @include media($breakpoint-large) {
        @include span-columns(9);
    }
    @include media($breakpoint-small) {
        @include span-columns(30);
    }
    background-color: $color-background-grey;
    padding: 10px 10px 20px 10px;
    vertical-align: baseline;
    border-radius: 3px;
    border: 1px solid $color-background-grey;
    border-top: 0;

    .cart-info-panel-title {
        padding: 0px;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
    }
    .cart-info-panel-row {
        margin: 0px;
        padding: 0px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
    }
}

.cart-checkout {
    margin-bottom: 60px;
}

.cart-checkout-wrapper {
    border-top: 5px solid $color-background-grey;
    overflow: auto;
    padding: 20px 0px;
    @include outer-container();
    @include media($breakpoint-large) {
        margin-bottom: 30px;
    }

    .cart-checkout-row-wrapper {
        @include media($breakpoint-large) {
            @include span-columns(30);
        }
    }
    .cart-checkout-spacer {
        @include media($breakpoint-large) {
            @include span-columns(18);
            padding-left: 30px;
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
            text-align: center;
        }
        font-size: 26px;
        color: $color-text-light-teal;
        overflow: auto;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .cart-checkout-button {
        @include media($breakpoint-large) {
            float: right;
        }
        padding-top: 14px;
        padding-bottom: 14px;
        &:hover {
            background-color: var(--theme-color-3-fade, $color-highlight-orange);
        }
    }
    .cart-checkout-total-row {
        @include media($breakpoint-large) {
            @include span-columns(7);
            @include shift(23 of 30);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
        }
        display: block;
        float: right;
        clear: both;
        text-align: right;
        padding-top: 6px;
        padding-right: 10px;
        margin-bottom: 15px;
    }
    .cart-checkout-label {
        display: inline;
        font-size: 14px;
        color: $color-text-grey;
        margin-right: 60px;
        font-family: 'Open Sans', sans-serif;
    }
    .cart-checkout-total,
    .order-complete-total-value {
        display: inline;
        font-size: 24px;
        padding-left: 5px;
        color: $color-highlight-light-orange;
        font-weight: bold;
    }
}

.cart-checkout-wrapper.no-margin {
    margin-bottom: 0px;
}
.cart-checkout-mobile-button {
    @include media($breakpoint-large) {
        display: none;
    }
    @include media($breakpoint-small) {
        @include row();
        margin-bottom: 10px;
    }
    background-color: $color-highlight-light-orange;
    color: $color-text-white;
    text-align: center;
    text-decoration: none;
    border: 0px;
    border-radius: 3px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
}
.cart-terms {
    margin-top: 10px;
    padding: 5px;
    .cart-terms-text {
        font-size: 18px;
        display: inline-block;
        margin-left: 10px;
        .cart-terms-link {
            color: var(--theme-color-2, $color-text-teal);
            :hover {
                color: var(--theme-color-2, $color-highlight-light-orange);
                text-decoration: none;
            }
        }
        @include media($breakpoint-small) {
            font-size: 12px;
        }
    }
}
.cart-terms.red {
    background-color: $color-background-light-red;
}

.cart-item-summary {
    @include outer-container(100%);
    padding: 15px;
    border-bottom: 1px solid $color-background-grey;
    margin-bottom: 15px;
}

.cart-item-summary-thumbnail-wrapper {
    @include span-columns(2 of 12, block-collapse);
    height: 150px;
    padding-right: 15px;

    @include media($breakpoint-small, 12) {
        @include span-columns(12 of 12, block-collapse);
        margin-bottom: 15px;
    }
}

.cart-item-summary-thumbnail {
}

.cart-item-summary-product-info {
    @include span-columns(10 of 12, block-collapse);
    padding-right: 15px;
    padding-left: 15px;
    line-height: 30px;
    @include media($breakpoint-small, 12) {
        @include span-columns(12 of 12, block-collapse);
        margin-bottom: 15px;
    }

    .cart-item-summary-order-info {
        padding-left: 0;
    }

    .cart-body-row {
        @include row();
        .cart-body-title {
            display: inline-block;
            @include span-columns(3 of 10);
            font-family: 'Open Sans', sans-serif;
            font-weight: lighter;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            color: $color-text-darkish-grey;
            font-size: 11px;
        }

        .cart-body-text {
            display: inline-block;
            @include span-columns(3 of 10);
        }

        @include media($breakpoint-small) {
            .cart-body-title,
            .cart-body-text {
                @include span-columns(5 of 10);
                line-height: 30px;
                margin-bottom: 1px;
                vertical-align: baseline;
            }
        }
    }
}

.cart-item-summary-product-name {
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    color: var(--theme-color-3, $color-text-teal);
    margin-bottom: 5px;

    @include media($breakpoint-small) {
        font-size: 18px;
    }
}

.cart-item-summary-product-sku {
    font-size: 14px;
    line-height: 24px;
    color: $color-text-grey;
    margin-bototm: 5px;

    @include media($breakpoint-small) {
        font-size: 12px;
    }
}

.cart-item-summary-order-info {
    @include span-columns(12 of 12, block-collapse);
    padding-left: 15px;
}

.cart-item-summary-order-info-container {
    background-color: $color-background-grey;
    padding: 15px;
}

.cart-item-summary-order-subtotal {
    height: 20px;
}

.cart-item-summary-order-quantity {
    height: 20px;
}

.cart-item-summary-order-subtotal-title {
    @include span-columns(1 of 3, block-collapse);
    text-align: right;
    text-transform: uppercase;
    font-size: 16px;
    color: $color-highlight-orange;
    font-weight: bold;
}

.cart-item-summary-order-quantity-title {
    @include span-columns(1 of 3, block-collapse);
    text-align: right;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: $color-text-grey;
}

.cart-item-summary-order-subtotal-value {
    @include span-columns(2 of 3, block-collapse);
    text-align: right;
    font-size: 16px;
    color: $color-highlight-orange;
    font-weight: bold;
}

.cart-item-summary-order-quantity-value {
    @include span-columns(2 of 3, block-collapse);
    text-align: right;
    font-size: 14px;
}

// .cart-body-image-preview-container {
// 	display: none;
// 	position: absolute;
// 	bottom: 25px;
// 	left: 0;
// 	padding: 10px;
// 	background-color: $color-background-white;
// 	border: 1px solid $color-background-grey;
// 	box-shadow: 2px 2px 2px gray;
// }

// .cart-body-image-preview {
// 	z-index: 10;
// 	width: 100px;
// }

// .cart-body-image-preview-wrapper {
// 	position: relative;

// 	&:hover {
// 		.cart-body-image-preview-container {
// 			display: block;
// 		}
// 	}
// }

// .cart-body-image-preview-link {
// 	width: 10px;
// 	height: 10px;
// 	margin-left: 10px;
// }

.cart-checkout-item-added {
    @include outer-container();
    margin-bottom: 15px;
    display: flex;
    @include media($breakpoint-small) {
        display: block;
    }
}

.cart-checkout-item-added-title {
    font-size: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter;
    color: var(--theme-color-3, $color-text-light-teal);
    padding-top: 5px;

    @include span-columns(5);

    @include media($breakpoint-small) {
        @include span-columns(30);
        font-size: 24px;
    }
}

.cart-checkout-item-added-summary {
    @include display(flex);
    @include justify-content(flex-end);
    @include align-items(center);
    margin: auto;
    margin-right: 0px;

    @include media($breakpoint-small) {
        @include span-columns(30);
        @include flex-direction(column);
    }

    .cart-checkout-button {
        margin-left: 20px;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 18px;

        @include media($breakpoint-small) {
            margin: 0px;
        }
        &:hover {
            background-color: var(--theme-color-3-fade, $color-highlight-orange);
        }
    }
}

.cart-checkout-item-added-summary-totals {
    width: 200px;
    text-align: right;
    @include media($breakpoint-small) {
        @include span-columns(30);
        margin-bottom: 15px;
        text-align: left;
    }
}

.cart-checkout-item-added-summary-total-items {
    font-size: 20px;
    color: $color-text-grey;
    font-weight: 300;
}

.cart-checkout-item-added-summary-total-amount {
    font-size: 20px;
    font-weight: 300;
}

.sales-tax-icon {
    width: 20px;
    height: 20px;
    margin-right: 2%;
    vertical-align: middle;
    cursor: pointer;
    display: inline-block;
}

.cart-checkout-button {
    width: 280px;
    margin: auto;
    margin-top: 1px;
    margin-right: 0px;
    display: block;
    float: right;
    clear: both;
    background: var(--theme-color-3, $color-button-background);
    color: var(--wl-white, $color-text-white);
    text-align: center;
    text-decoration: none;
    border: 0px;
    border-radius: 3px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 18px;
    font-family: Open Sans, sans-serif;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        background-color: var(--theme-color-3, $color-highlight-light-orange);
    }

    &[disabled],
    &.disabled {
        background: $color-background-darker-grey !important;
        pointer-events: none;
    }

    @include media($breakpoint-small) {
        @include span-columns(30);
        margin: 0px;
    }
}
.cart-checkout-row-wrapper {
    display: block;

    &.hide-on-table-or-mobile {
        display: none;
    }

    &.display-on-table-or-mobile {
        display: block;
    }

    @include media($breakpoint-larger) {
        &.hide-on-table-or-mobile {
            display: block;
        }

        &.display-on-table-or-mobile {
            display: none;
        }

        &.hide {
            display: none !important;
        }

        &.show {
            display: block !important;
        }
    }

    .checkout-row {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        border-top: 5px solid $color-background-grey;
        padding-top: 20px;
        margin-bottom: 20px;

        @include media($breakpoint-small) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .checkout-column {
            display: flex;
            flex-basis: 24%;
            flex-grow: 0;
            flex-shrink: 0;
            flex-direction: column;
            margin-left: 20px;

            @include media($breakpoint-small) {
                flex-basis: 100%;
                width: 100%;
                margin: 0px;
            }

            .checkout-total-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: baseline;

                .checkout-label {
                    display: inline;
                    font-size: 14px;
                    color: $color-text-grey;
                    margin-right: 60px;
                    text-align: flex-end;
                }
            }
        }
    }
}

.value-props-near-container {
    margin: 30px 0 70px;
    display: -ms-flexbox;
    display: flex;

    .value-prop {
        -ms-flex: 1 1 33%;
        flex: 1 1 33%;
        display: inline-block;
        vertical-align: top;
        width: 28.33%;

        .value-prop-title {
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    .value-prop + .value-prop {
        margin-left: 50px;
    }

    @include media($breakpoint-small) {
        display: none;
    }
}
