.switch {
    position: relative;
    display: inline-block;
    min-width: 60px;
    height: 34px;

    input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-lightish-grey;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &::before {
            border-radius: 50%;
            position: absolute;
            content: '';
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: $color-text-white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }

    &.active {
        .slider {
            background-color: var(--theme-color-3, $color-text-teal);

            &::before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }
    }

    &:hover,
    &:focus {
        .slider {
            box-shadow: 0 0 1px var(--theme-color-3, $color-text-teal);
        }
    }
}
