.floating-seal-code {
    .tgfloat {
        position: fixed;
        bottom: 0;
        z-index: $z-index-upperest;
        text-align: left;
        left: 0;

        img {
            border: 0;
            width: 175px;

            @media (max-width: 500px) {
                & {
                    width: 120px !important;
                }
            }
        }
    }
}

.cart-banner {
    text-align: right;

    .tgfloat {
        border: 0;
        width: 275px;
    }
}

.star_container {
    .group-stars {
        .ind_cnt {
            display: inline;
            font-size: 14px;
            color: $color-text-grey;
            line-height: 24px;
            margin-left: 20px;
            position: relative;
            top: -4px;
        }

        .on {
            @include star-on();

            &.half {
                @include star-on-half();
            }
        }

        .off {
            @include star-off();
        }
    }
}

.product-header-reviews {
    #product_just_stars {
        .SA__total_reviews {
            padding: 0;
            .sa_jump_to_reviews {
                margin: 0;
                margin-right: 10px;
                text-align: left;
            }
        }
    }
    .sa_jump_to_reviews {
        font-size: 14px;
        color: $color-text-grey;
        line-height: 24px;
        margin-left: 20px;
        position: relative;
        top: -4px;
    }
    #ab-tiny-widget {
        margin-top: 0;
        margin-bottom: 0;
        a {
            color: $color-highlight-dark-orange;
        }
    }
}

#shopper_approved input[type='checkbox']::before,
#shopper_approved input[type='checkbox']::after,
.product-information-tabs-container .qa-widget-search-content,
.qa-product-description-short {
    display: none !important;
}

#SA_review_wrapper {
    .SA_review_wrapper_child {
        .SA__review_container {
            .SA__customer_reviews_wrap {
                .SA__customer_reviews {
                    width: 100%;

                    .SA__see_more {
                        background-color: $color-background-white;
                    }
                }
            }
        }
    }
}

.answerbase-widget .questions-list .item {
    a.follow,
    span.timestamp {
        color: $color-text-darkish-grey;
    }
}
