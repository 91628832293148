.search-wrapper {
    @include span-columns(15);
    width: 100%;
    margin-right: 0;

    @include media($breakpoint-small) {
        max-width: 100%;
    }

    .header-search {
        border: 2px solid var(--theme-color-3, $color-text-teal);
        background-color: var(--theme-color-3, $color-text-teal);
        border-radius: 6px;
        position: relative;
        display: flex;
        width: 100%;
        justify-content: flex-start;

        label {
            width: 100%;

            .header-search-input {
                width: 100%;
                border: 0;
                height: 40px;
                border-radius: 4px 0 0 4px;
                min-height: 0;
                padding-left: 10px;
                font-size: 17px;
                @include media($breakpoint-large) {
                    &:hover {
                        background-color: var(--theme-color-3-tint5, $color-background-light-grey);
                        outline: none;
                    }
                }
                &:focus,
                &:active {
                    background-color: var(--theme-color-3-tint5, $color-background-light-grey);
                    outline: none;
                }
            }
        }

        &.icon {
            .header-search-button {
                border-left: 0;
                min-height: 38px;
                height: 38px;
            }

            .header-search-input {
                height: 38px;
            }
        }

        .header-search-button {
            display: inline-block;
            outline: none;
            cursor: pointer;
            background-color: $color-background-grey;
            border: 0;
            border-left: 2px solid var(--theme-color-3, $color-text-teal);
            border-radius: 0 4px 4px 0;
            padding: 3px 30px;
            color: var(--theme-color-3, $color-text-teal);
            text-align: center;
            width: 150px;
            height: 40px;
            min-height: 40px;

            &:hover {
                background-color: $color-background-darker-grey;
            }

            &.icon {
                width: 40px;
                background-color: transparent;
                padding: 3px 6px;

                &:hover {
                    background-color: transparent;
                }

                .search-button-img {
                    width: 30px;
                    padding: 2px;
                }
            }
        }
    }
}

.public-header {
    background-color: $color-background-white;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    z-index: $z-index-header;
    font-family: 'Open Sans', sans-serif;

    &.open {
        position: absolute;
    }

    li {
        list-style-type: none;
    }

    ul {
        margin-left: 0;
        padding-left: 0;
    }

    @include media($breakpoint-large) {
        .primary-header {
            border-bottom: 1px solid $color-header-divider;
        }
    }

    @include media($breakpoint-small) {
        background-color: $color-header-background;
        border-bottom: 1px solid $color-text-light-grey;
    }

    .header-slogan {
        padding: 10px 30px;
        background: var(--theme-color-1, $color-text-teal);
        color: var(--theme-color-2, $color-background-white);
        font-size: 18px;
        text-align: center;

        @include media($breakpoint-small) {
            display: none;
        }
    }

    .primary-header {
        height: 100%;
        display: flex;

        @include media($breakpoint-small) {
            display: none;
        }

        .container {
            display: flex;
            width: 100%;
            align-items: center;

            .header-logo-wrapper {
                height: 100%;
                width: 50%;
                min-width: 179px;

                .header-logo {
                    height: 100%;
                    width: 100%;
                    outline: none;
                    vertical-align: middle;

                    @include media($breakpoint-large) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    @include media($breakpoint-small) {
                        display: inline-block;
                        padding-top: 5px;
                        height: 50px;
                    }

                    .header-logo-img {
                        min-width: 179px;
                        object-fit: contain;
                        object-position: left;
                        width: 100%;
                        height: 100%;
                        padding: 25px 0;
                        max-height: 160px;
                        max-width: 780px;
                    }
                }
            }

            .search-bar-wrapper {
                height: 100%;
                width: 100%;
                padding: 20px;

                .search-wrapper {
                    margin: auto;
                }
            }

            .header-actions {
                width: 33%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;

                .header-contact-wrapper {
                    width: 100%;
                    margin: 5px 0;

                    .header-contact {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        color: $color-text-black;
                        text-decoration: none;
                        align-items: center;

                        .header-contact-icon {
                            display: block;
                            width: 40px;
                            height: 40px;
                            background-color: var(--theme-color-3, $color-text-teal);
                            border-radius: 50%;

                            .icon {
                                width: 100%;
                                height: 100%;
                                display: block;
                                margin: auto;
                                background-color: $color-background-white;

                                @include mask-image('../../public/images/phone-icon.svg');
                            }
                        }

                        .header-contact-content {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
                            font-size: 16px;
                            padding-left: 10px;

                            .header-phone {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .header-livechat-button-wrapper {
                    width: 100%;
                    margin: 5px 0;

                    .header-livechat-button {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        color: $color-text-black;
                        text-decoration: none;
                        align-items: center;

                        .header-chat-icon {
                            display: block;
                            width: 40px;
                            height: 40px;
                            background-color: var(--theme-color-3, $color-text-teal);
                            border-radius: 50%;

                            .icon {
                                width: 100%;
                                height: 100%;
                                display: block;
                                margin: auto;
                                background-color: $color-background-white;

                                @include mask-image('../../public/images/chat-icon.svg');
                            }
                        }

                        .header-livechat-text {
                            padding-left: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.mobile-header {
    @include media($breakpoint-large) {
        display: none;
    }
    padding: 0 10px;

    .header-search {
        margin-bottom: 10px;
        margin-top: 5px;
        padding-right: 10px;
    }

    .autocomplete-wrapper {
        margin-left: -21px;
        width: calc(100% + 21px);
        max-height: calc(100vh - 115px - 50px);
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.mobile-header-logo {
    width: 45%;
    height: 50px;
    line-height: 50px;
    float: left;
    padding-top: 5px;
    padding-bottom: 5px;

    @include media($breakpoint-tiny) {
        width: 35%;
    }

    a {
        display: inline-flex;
        height: 100%;
        width: 100%;

        .mobile-header-logo-img {
            object-position: left;
            object-fit: contain;
            height: 100%;
            width: inherit;
        }
    }
}

.mobile-header-logo-img {
    vertical-align: middle;
}

.mobile-header-icons {
    @include display(flex);
    @include justify-content(space-around);
    @include align-items(center);

    width: 185px;
    height: 50px;
    float: right;
    margin-right: 5px;

    // firefox doesn't work well with svg height
    img {
        height: 21.67px;
    }
}

.mobile-header-search,
.mobile-header-cart,
.mobile-header-hamburger,
.mobile-header-call {
    cursor: pointer;
    padding: 20px 10px;
}

.mobile-header-search-img,
.mobile-header-cart-img,
.mobile-header-hamburger-img {
    width: 100%;
}

.mobile-header-call-img {
    height: 21.67px;
    width: 21.67px;
    background-color: var(--theme-color-3, $color-highlight-light-orange);
    -webkit-mask: url('../../public/images/mobile-call.svg') no-repeat center;
    mask: url('../../public/images/mobile-call.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
}

@include keyframes(slideIn) {
    0% {
        display: block;
        position: fixed;
        @include transform(translatex(100%));
    }

    100% {
        @include transform(translatex(0%));
    }
}

@include keyframes(slideOut) {
    0% {
        @include transform(translatex(0%));
    }

    100% {
        display: none;
        position: absolute;
        @include transform(translatex(100%));
    }
}

.mobile-header-tray {
    position: fixed;
    width: 288px;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: var(--theme-color-1, $color-background-teal-darker);

    &.closed {
        @include transform(translatex(100%));
    }

    &.tray-open {
        @include transform(translatex(0%));
        z-index: 100001;
    }
}

.mobile-header-tray-contents {
    overflow: scroll;
    height: calc(100% - 45px);
    -webkit-overflow-scrolling: touch;
}

.mobile-header-tray-section {
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid $color-background-teal-darkest;
    min-height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.mobile-header-tray-close {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    position: relative;
    @include close-mask(var(--theme-color-2, $color-text-teal));
}

.mobile-header-tray-section-title {
    color: var(--theme-color-2, $color-text-white);
    font-weight: bold;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
    padding-bottom: 10px;
    @include display(flex);
    @include justify-content(space-between);
    @include align-items(center);

    &:last-child {
        padding-bottom: 0;
    }

    a {
        color: var(--theme-color-2, $color-text-white);
    }

    a:visited {
        color: var(--theme-color-2, $color-text-white);
    }
}

.mobile-expandable-title {
    cursor: pointer;
}

.mobile-header-tray-section-expand {
    width: 20px;
    height: 10px;
    display: block;
    float: right;
    cursor: pointer;
    position: relative;

    @include expand-arrow-mask(var(--theme-color-2, white));
    &.expanded {
        @include transform(rotate(180deg));
    }
}

.mobile-header-tray-sub-section {
    padding-top: 5px;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 10px;
    }
}

.mobile-header-tray-section-utility-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: var(--theme-color-2, $color-text-white);
    line-height: 25px;
    text-transform: uppercase;
    font-weight: bold;
}

.mobile-header-tray-section-utility-list {
    margin-left: 10px;
}

.mobile-header-tray-section-link-text {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;

    a {
        color: var(--theme-color-2, $color-text-teal-white);
    }

    a:visited {
        color: var(--theme-color-2, $color-text-teal-white);
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.mobile-header-screen.frozen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.mobile-search-bar-field-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
    padding-right: 10px;
}

.mobile-search-bar-field {
    border: 2px solid$color-divider-dark-grey;
    border-right: none;
    height: 40px;
    width: calc(100% - 40px);
    padding-left: 10px;
}

.mobile-search-bar-icon-wrapper {
    background-color: $color-highlight-light-orange;
    display: inline-block;
    height: 40px;
    width: 40px;
    vertical-align: bottom;
    cursor: pointer;
}

.mobile-search-bar-icon {
    width: 25px;
    margin: 7.5px;
}

.mobile-search-bar-screen {
    position: absolute;
    height: 100vh;
    width: 100%;
    left: 0;
    background-color: gray;
    opacity: 0.8;
}
