.swag-store {
    .swag-store-banner-wrapper {
        width: 100%;

        .swag-store-banner-container {
            margin: auto;

            .swag-store-banner-img {
                width: 100%;
                max-height: 300px;
                object-fit: contain;
            }
        }
    }

    .swag-store-title-wrapper {
        h2 {
            color: $color-text-darker-grey;
            padding: 0 100px;
            text-align: center;

            @include media($breakpoint-large) {
                font-size: 40px;
            }
        }
    }
}
