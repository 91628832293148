.password-validation-indicator {
    margin: 10px 0;

    .password-validation-indicator-row {
        padding: 2px 5px;
        display: flex;

        .icon {
            margin: auto;
            margin-right: 5px;
            display: block;
            height: 15px;
            min-width: 15px;
            background-color: red;
            @include mask-image('../../public/images/close.svg');
        }

        .text {
            display: block;
            flex-grow: 1;
            color: red;
        }

        &.valid {
            .icon {
                background-color: green;
                @include mask-image('../../public/images/check-mark.svg');
            }

            .text {
                color: green;
            }
        }
    }
}
