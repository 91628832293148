.customize-page {
    overflow: auto;
    padding-top: 10px;
    .customize-header-wrapper {
        padding-bottom: 5px;

        .customize-header-title {
            font-family: 'Open Sans', sans-serif;
            color: var(--theme-color-3, $color-text-teal);
            font-size: 32px;
            display: inline-block;
            margin: 0;
            text-indent: 15px;
        }
        .customize-header-subtext {
            font-size: 14px;
            display: inline-block;
            padding-left: 10px;
        }
    }
    .customize-content-wrapper {
        @include display(flex);
        @include align-items(stretch);
        position: relative;
        @include media($breakpoint-small) {
            @include flex-direction(column);
        }

        .customize-options-column {
            @include media($breakpoint-large) {
                width: 75%;
                margin-right: 1.50376%;
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
            .customize-product-header-wrapper {
                border-top: 1px solid $color-background-light-grey3;
                background-color: $color-background-white;
                @include media($breakpoint-header-large) {
                    height: 50px;
                }
                @include media($breakpoint-header-small) {
                    height: 50px;
                }
                padding: 15px;
                display: table;
                width: 100%;
                z-index: 1000;

                .customize-product-header-wrapper-spacer {
                    display: none;
                }

                &.full-page {
                    @include media($breakpoint-small) {
                        display: none;
                    }
                }

                &.mobile {
                    @include media($breakpoint-large) {
                        display: none;
                    }
                }
            }

            .customize-product-header-wrapper-sticky {
                display: none;
                .customize-product-header-wrapper-spacer {
                    display: block;
                    height: 30px;
                    width: 100%;
                    position: absolute;
                    top: -31px;
                    background-color: $color-background-white;
                }
            }

            .customize-product-name-wrapper {
                @include omega();
                @include media($breakpoint-large) {
                    @include span-columns(23);
                }
                @include media($breakpoint-small) {
                    @include span-columns(30);
                }
                padding-top: 10px;
                padding-bottom: 10px;
                .customize-product-name {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 24px;
                    color: initial;
                    text-decoration: none;
                }
                .customize-product-sku {
                    font-size: 14px;
                    line-height: 30px;
                    color: $color-text-grey;
                }
            }
            .customize-thumbnail-wrapper {
                @include media($breakpoint-large) {
                    @include span-columns(7);
                }
                @include media($breakpoint-small) {
                    @include span-columns(30);
                }
                text-align: center;
                .customize-product-thumbnail {
                    padding: 5px;
                    height: 90px;
                    @include media($breakpoint-header-large) {
                        height: 120px;
                    }
                    @include media($breakpoint-header-small) {
                        height: 100px;
                    }

                    @include media($breakpoint-small) {
                        height: 100px;
                    }

                    .customize-product-thumbnail-image {
                        width: 100%;
                        height: 100%;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .customize-option-wrapper {
                border-radius: 8px;
                padding: 15px 15px 30px 10px;
                margin-bottom: 15px;
                opacity: 1;
                background-color: $color-text-white;
                border: 2px solid $color-text-light-teal;
                -webkit-box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.4);
                -moz-box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.4);
                box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.4);

                .customize-option-number {
                    background-color: var(--theme-color-3, $color-text-light-teal);
                    color: var(--wl-white, $color-text-white);
                }
                .customize-option-name {
                    color: var(--theme-color-3, $color-text-light-teal);
                }
            }

            .customize-option-container {
                display: flex;
                flex-direction: column;

                .customize-option-title-row {
                    margin: 0;
                    padding-top: 10px;
                }
            }

            .customize-option-title-row {
                @include row();
                padding: 0;
                margin: 0;
                margin-bottom: 10px;
                padding-bottom: 10px;

                @include media($breakpoint-larger) {
                    display: flex;
                }
            }
            .customize-option-input {
                background-color: $color-text-white;
                height: 35px;
                font-size: 18px;
                padding-left: 10px;
                width: 120px;
                border-radius: 3px;
                border: 1px solid $color-lightish-grey;
                &:focus {
                    background-color: $color-background-light-yellow;
                    border-color: $color-text-light-teal;
                }
            }
            .customize-opiton-select {
                background-color: $color-background-input;
            }
            .customize-option-imprint-method-wrapper-wrapper {
                display: block;
                width: 50%;
                float: right;
                margin-top: 10px;

                @include media($breakpoint-large) {
                    float: left;
                }
            }
            .customize-option-date-wrapper {
                position: relative;
                display: inline-block;
                margin-left: 30px;

                @include media($breakpoint-large) {
                    margin-left: 0px;
                }

                @include media($breakpoint-smaller) {
                    margin-left: 0px;
                    margin-top: 15px;
                    display: block;
                }
                .customize-option-date-icon {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    top: 6px;
                    left: 6px;
                    mask-image: url('../../public/images/icon-cal.png');
                    -webkit-mask-image: url('../../public/images/icon-cal.png');
                    mask-position: center;
                    -webkit-mask-position: center;
                    mask-size: contain;
                    -webkit-mask-size: contain;
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;
                    background-color: var(--wl-input-border, $color-calendar);
                }
                input {
                    padding-left: 42px;
                }
            }
            .customize-option-title-column {
                @include media($breakpoint-large) {
                    @include span-columns(15);
                }
                @include media($breakpoint-small) {
                    @include span-columns(30);
                    margin-bottom: 10px;
                }

                &.full-width {
                    @include span-columns(30);
                }

                .margin-top {
                    margin-top: 10px;
                }

                .customize-option-quantity-input {
                    position: relative;
                    display: inline-block;
                    margin-left: 30px;

                    @include media($breakpoint-smaller) {
                        margin-left: 0px;
                        margin-top: 15px;
                        display: block;
                    }
                }

                input {
                    padding-left: 42px;
                }
            }
            .customize-option-shift {
                @include media($breakpoint-large) {
                    @include shift(15);
                }
            }
            .customize-option-number {
                font-family: 'Open Sans', sans-serif;
                display: inline-block;
                color: var(--theme-color-3, $color-text-white);
                background-color: $color-text-grey;
                font-size: 22px;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50px;
                line-height: 38px;
                padding: 0;
            }
            .customize-option-name {
                font-family: 'Open Sans', sans-serif;
                display: inline-block;
                color: $color-text-grey;
                font-size: 22px;
                padding-left: 10px;
                .subtext {
                    font-size: 14px;
                    color: $color-text-black;
                }

                @include media($breakpoint-tiny) {
                    font-size: 18px;
                }
            }
            .customize-option-addon-name {
                padding-left: 55px;
                font-size: 16px;
            }
            .customize-option-addon-name.red {
                color: var(--theme-color-3, $color-text-red);
            }
            .customize-option-date {
                padding-left: 10px;
                font-family: 'Open Sans', sans-serif;
                height: 36px;
                font-size: 14px;
                border: 1px solid $color-divider-grey;
                border-radius: 3px;
                background-color: $color-background-input;
            }
            .customize-option-text {
                font-size: 14px;
                padding: 10px 5px;
                line-height: 21px;
            }
            .customize-option-addon-button {
                background-color: $color-text-white;
                border: 1px solid $color-divider-grey;
                width: 240px;
                margin-right: 10px;
            }
            .customize-option-addon-button:hover {
                @include media($breakpoint-large) {
                    text-decoration: none;
                    background-color: $color-background-dark-grey;
                }
            }
            .customize-contact-wrapper {
                margin-top: 10px;
                margin-bottom: 30px;
                background: -moz-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
                background: -webkit-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
                background: -o-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
                background: -ms-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
                background: linear-gradient(180deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
                background-color: $color-background-blue;
                padding: 20px;
                overflow: auto;
                border-radius: 4px;
                width: 100%;

                .partner & {
                    background-color: $color-background-light-grey4;
                }

                @include media($breakpoint-small) {
                    width: 100%;
                }
                .customize-contact-text {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                .customize-contact-button {
                    @include span-columns(5);
                    font-family: 'Open Sans', sans-serif;
                    cursor: pointer;
                    background-color: var(--theme-color-3, $color-background-dark-blue);
                    color: var(--wl-white, $color-text-white);
                    border: 1px solid $color-background-dark-blue;
                    border-radius: 3px;
                    padding: 10px;
                    font-size: 14px;
                    margin-bottom: 10px;
                    width: auto;
                    min-width: 150px;
                    text-align: center;

                    &:hover {
                        @include media($breakpoint-large) {
                            background-color: var(--theme-color-3-fade, $color-background-darker-blue);
                            color: var(--wl-white, $color-background-darker-blue);
                        }
                    }
                    & > span {
                        color: $color-text-white;
                    }
                    @include media($breakpoint-small) {
                        width: 100%;
                    }
                }
            }
            .customize-text-area {
                margin-top: 10px;
                padding: 5px;
                width: 100%;
                line-height: 21px;
                resize: none;
            }
            .customize-option-column {
                @include media($breakpoint-large) {
                    @include span-columns(7);
                }
                @include media($breakpoint-small) {
                    @include span-columns(30);
                }
                text-align: center;
                margin: 10px 0px;
                .customize-upload-img {
                    height: 60px;
                }
                .customize-upload-text-img {
                    height: 40px;
                    margin: 10px 0px;
                }
                .customize-upload-button {
                    margin-top: 10px;
                    padding: 8px 0px;
                    width: 100%;
                    background-color: $color-background-white;
                    border: 1px solid $color-divider-grey;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 14px;
                }
                .customize-upload-button:hover {
                    @include media($breakpoint-large) {
                        background-color: $color-divider-grey;
                    }
                }
            }
            .customize-option-column.right {
                @include media($breakpoint-large) {
                    @include shift(9);
                }
            }
        }
        .customize-summary-column {
            @include media($breakpoint-large) {
                width: 25%;
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
                margin-bottom: 10px;
            }
            .summary-header {
                background-color: $color-background-grey;
                font-weight: bold;
                padding: 7px 10px;
            }
            .summary-option-wrapper {
                padding: 6px 10px;
                line-height: 17px;
                border-bottom: 1px solid $color-background-light-grey3;
                .summary-option-name {
                    font-family: 'Open Sans', sans-serif;
                    display: inline-block;
                    font-size: 13px;
                    padding-right: 8px;
                    letter-spacing: -0.02em;
                    line-height: 18px;
                    color: $color-text-darker-grey;
                }
                .summary-option-value {
                    display: inline;
                    font-size: 14px;
                    font-weight: bold;
                    padding-left: 0px;
                    line-height: 18px;
                    div {
                        display: inline;
                    }
                    .red {
                        color: var(--theme-color-3, $color-text-red);
                    }
                }
                .summary-option-notes {
                    font-size: 14px;
                    color: var(--wl-black, $color-text-light-teal);
                    font-style: italic;
                    line-height: 18px;
                    display: block; /* Fallback for non-webkit */
                    display: -webkit-box;
                    width: 100%;
                    max-height: 36px; /* Fallback for non-webkit */
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .summary-calculator-wrapper {
                line-height: 21px;
                padding: 6px 10px 7px 10px;
            }
            .summary-total {
                padding: 2px 10px;
                background-color: $color-background-grey;
            }
            .summary-error {
                padding: 10px;
                background-color: $color-background-light-red;
                .summary-error-header {
                    color: $color-text-red;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                }
                .summary-error-item {
                    color: $color-text-red;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            .summary-calculator-name {
                font-family: 'Open Sans', sans-serif;
                display: inline-block;
                font-size: 13px;
                color: $color-text-darker-grey;
                width: 65%;
            }
            .summary-calculator-name.short {
                width: 40%;
            }
            .summary-calculator-value {
                font-family: 'Open Sans', sans-serif;
                display: inline-block;
                text-align: right;
                width: 35%;
                font-size: 14px;
                padding-bottom: 10px;
                padding-right: 10px;
            }
            .summary-calculator-value.long {
                width: 60%;
            }
            .summary-calculator-input {
                display: inline-block;
                text-align: right;
                margin-left: 15%;
                width: 20%;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 7px;
                border: 1px solid $color-lightish-grey;
                border-radius: 3px;
                font-weight: bold;
                padding: 4px 0;
                min-height: 10px;
            }
            .summary-calculator-name-total {
                padding-top: 10px;
                display: inline-block;
                text-align: left;
                width: 40%;
                font-size: 24px;
                color: var(--wl-black, $color-text-orange);
                font-weight: bold;
            }
            .summary-calculator-value-total {
                display: inline-block;
                text-align: right;
                width: 60%;
                font-size: 24px;
                color: var(--wl-black, $color-text-orange);
                padding-bottom: 10px;
                font-weight: bold;
                padding-right: 8px;
            }
            .summary-cart-button {
                margin-bottom: 10px;
                padding: 10px;
                width: 100%;
                background-color: var(--theme-color-3, $color-button-background);
                color: $color-text-white;
                border: 1px solid var(--theme-color-3, $color-button-background);
                border-radius: 4px;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.02em;
                cursor: pointer;
            }
            .summary-cart-button:hover {
                @include media($breakpoint-large) {
                    background-color: var(--theme-color-3, $color-highlight-orange) !important;
                    border-color: var(--theme-color-3, $color-highlight-orange) !important;
                }
            }
            .summary-cart-button-icon {
                width: 26px;
                min-height: 16px;
                padding-right: 10px;
            }
        }
        .quantity-changed-wrapper {
            margin-bottom: 10px;
        }
    }
}

.customize-upload-img-preview-wrapper {
    position: relative;
    padding: 45px;
    background-color: $color-background-dark-grey;
}

.customize-upload-img-container {
    position: relative;
    height: 125px;
    width: 125px;
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 20px;
    vertical-align: top;
}

.customize-upload-img-preview-clear {
    position: absolute;
    top: 10px;
    left: -35px;
    cursor: pointer;
}

.customize-upload-img-preview-clear-button {
    width: 25px;
}

.customize-upload-img-preview {
    height: 100%;
}

.customize-upload-img-preview,
.customize-upload-img-preview-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid lightgray;

    &.no-preview {
        text-align: center;
        @include display(flex);
        @include justify-content(flex-start);
        @include align-items(center);
        @include flex-direction(column);

        .no-preview-text {
            margin-top: 40px;
            text-transform: uppercase;
            font-size: 20px;
            color: $color-text-grey;
        }
    }
}

.customize-summary-column-border-wrapper {
    border: 1px solid $color-divider-grey;
}

.customize-summary-column-content {
    padding-bottom: 125px;
}

.sticky-bottom {
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.customize-option-title-column .Select__control {
    padding: 0;
}
.customize-option-title-column .Select__control div.Select__placeholder {
    line-height: 38px;
}

.customize-page {
    .Select__placeholder {
        color: black;
    }
}

.customize-option-subtitle {
    margin-left: 90px;
    line-height: 36px;
}

.image-text-danger {
    width: 100%;
    padding: 10px;
    color: $color-text-dark-red;
    background-color: $color-background-pink;

    a {
        color: $color-text-dark-red;
        text-decoration: underline;
    }
}

.is-today .pika-button {
    color: #666 !important;
    font-weight: normal !important;

    &:hover {
        color: #fff !important;
    }
}

.is-selected .pika-button {
    color: white !important;
    font-weight: bold !important;
}

.product-circle-checkmark {
    &.assort {
        background-image: url('../../public/images/assort.svg');
    }

    &.stock {
        background-image: url('../../public/images/stock.svg');
    }
}

.customize-options-panel {
    display: none;
    border: 1px solid $color-background-light-grey3;
    margin-right: 1.50376%;

    @include media($breakpoint-larger) {
        & {
            display: flex;
            flex-direction: row;
        }
    }

    .customize-options-panel-tabs {
        width: 30%;
        border-right: 1px solid $color-background-light-grey3;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: 0px;

        .optional-panel-tab-button {
            color: var(--theme-color-3, $color-text-teal2);
            border-bottom: 1px solid $color-background-light-grey3;
            background-color: transparent;
            width: 100%;
            padding: 20px 15px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            display: inline-block;
            text-align: left;
            font-size: 18px;

            &:last-child {
                border-bottom: none;
            }
            &.active,
            &:hover {
                &::before {
                    background-color: var(--theme-color-3, $color-text-teal2);
                    opacity: 0.1;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
            }
        }
    }

    .customize-options-panel-tab {
        width: 70%;
        min-height: 100%;
        padding: 15px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .row {
            .customize-option-column {
                &.right {
                    @include media($breakpoint-large) {
                        width: 36.1805%;
                        margin-left: 16.4511%;
                    }
                }
            }
        }

        .product-price-table-wrapper {
            .product-price-table {
                min-width: initial;
            }
        }

        .product-color-grid {
            overflow: visible;

            .selected-color-label {
                font-weight: 600;
            }

            .product-color-column {
                position: relative;
                width: auto;
                height: auto;
                padding: 5px;
                margin: 5px 15px 10px 0px;

                div {
                    margin: 0px;
                }

                .product-color-label-wrapper {
                    position: absolute;
                    bottom: 105%;
                    left: 50%;
                    transform: translateX(-50%);
                    border: 1px solid #efefef;
                    width: auto;
                    padding: 0px 4px;
                    border-radius: 4px;
                    white-space: nowrap;
                    display: none;
                    background-color: white;
                    z-index: 1;
                }

                .gray {
                    background-color: #666666;
                }

                &:hover {
                    .product-color-label-wrapper {
                        display: block;
                    }
                }
            }

            .narrow {
                width: auto;
            }

            .selectable {
                width: auto;
            }
        }

        .customize-options-panel-next-button {
            width: 100%;

            button {
                margin: auto;
                display: block;
                margin-right: 0px;
                padding: 5px 30px;
                font-size: 24px;
                color: var(--theme-color-3, $color-text-teal2);
                background-color: $color-background-teal;
                position: relative;
                border: 1px solid var(--theme-color-3, $color-text-teal2);

                &:hover {
                    &::before {
                        background-color: var(--theme-color-3, $color-text-teal2);
                        opacity: 0.2;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .customize-option-title-row {
            display: flex;

            .customize-option-title-column {
                flex-grow: 1;
                width: 100%;
                display: block;
            }

            .customize-option-select {
                flex-grow: 1;
                width: 100%;
                margin: 0px;
            }
        }
    }
}
