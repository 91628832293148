@mixin close-mask($color) {
    &:after {
        content: '';
        mask-image: url('../../public/images/close.svg');
        -webkit-mask-image: url('../../public/images/close.svg');
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: cover;
        -webkit-mask-size: cover;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        background-color: $color;
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
    }
}

@mixin checkmark-mask($color) {
    &:after {
        content: '';
        mask-image: url('../../public/images/checkmark-white.svg');
        -webkit-mask-image: url('../../public/images/checkmark-white.svg');
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: cover;
        -webkit-mask-size: cover;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        background-color: $color;
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
    }
}

@mixin expand-arrow-mask($color) {
    &:after {
        content: '';
        mask-image: url('../../public/images/downarrow-white.svg');
        -webkit-mask-image: url('../../public/images/downarrow-white.svg');
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        background-color: $color;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin check-mark-icon() {
    mask-image: url('../../public/images/check-mark.svg');
    -webkit-mask-image: url('../../public/images/check-mark.svg');
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

@mixin mask-image($url) {
    mask-image: url($url);
    -webkit-mask-image: url($url);
    @include mask-image-without-url();
}

@mixin mask-image-without-url() {
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

@mixin box-shadow() {
    -webkit-box-shadow: 0px 5px 10px 0px $color-shadow-black;
    -moz-box-shadow: 0px 5px 10px 0px $color-shadow-black;
    box-shadow: 0px 5px 10px 0px $color-shadow-black;
}

@mixin focus() {
    -webkit-box-shadow: 0px 0px 5px 4px $color-focus;
    -moz-box-shadow: 0px 0px 5px 4px $color-focus;
    box-shadow: 0px 0px 5px 4px $color-focus;
}

@mixin star-on() {
    @include mask-image('../../public/images/star_on.svg');
    background-color: $color-button-background;
    background-image: none;
}

@mixin star-on-half() {
    @include mask-image('../../public/images/star_on_half.svg');
    background-image: none;
}

@mixin star-off() {
    @include mask-image('../../public/images/star_off.svg');
    background-color: $color-button-background;
    background-image: none;
}

@mixin gradient-grey() {
    background: $color-background-grey;
    background: -moz-linear-gradient(90deg, $color-background-grey 60%, $color-background-grey2 95%);
    background: -webkit-linear-gradient(90deg, $color-background-grey 60%, $color-background-grey2 95%);
    background: -o-linear-gradient(90deg, $color-background-grey 60%, $color-background-grey2 95%);
    background: -ms-linear-gradient(90deg, $color-background-grey 60%, $color-background-grey2 95%);
    background: linear-gradient(180deg, $color-background-grey 60%, $color-background-grey2 95%);
}

@mixin gradient-blue() {
    background: -moz-linear-gradient(90deg, $color-text-light-teal 30%, $color-blue-darken 95%);
    background: -webkit-linear-gradient(90deg, $color-text-light-teal 30%, $color-blue-darken 95%);
    background: -o-linear-gradient(90deg, $color-text-light-teal 30%, $color-blue-darken 95%);
    background: -ms-linear-gradient(90deg, $color-text-light-teal 30%, $color-blue-darken 95%);
    background: linear-gradient(180deg, $color-text-light-teal 3%, $color-blue-darken 95%);
}
