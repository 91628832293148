html {
    box-sizing: border-box;
    min-height: 100vh;
}

body {
    margin: 0px;
    height: 100%;
    min-height: 100vh;
}

a,
img {
    border: none;
    outline: none;
    text-decoration: none;
}
a:hover {
    @include media($breakpoint-large) {
        text-decoration: underline;
    }
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

#app {
    height: 100%;
    min-height: 100vh;
}

#pg-app {
    height: 100%;
    min-height: 100vh;
}

#storefront {
    min-height: 100vh;
}

#app * {
    -webkit-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
}

.storefront {
    @include display(flex);
    @include flex-direction(column);
    justify-content: space-between;
}

.pg-app {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    height: 100%;
}

textarea,
input {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
}

.site-body {
    background-color: $color-background-white;
    margin-top: 0;

    &.partner {
        margin-top: 0;
    }
}

.safari {
    .site-body {
        margin-top: 0;

        &.partner {
            margin-top: 0;
        }
    }
}

.no-scroll {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.bcFloat {
    @include media($breakpoint-smaller) {
        display: none !important;
    }
}
.bc-chat-state-prechat {
    z-index: $z-index-upper !important;
}

.iframe {
    .bcFloat {
        display: none !important;
    }
}

.pika-single {
    z-index: 99999999999 !important;
}

.site-phone,
.partner-phone {
    white-space: pre;
    color: var(--theme-color-3);

    &.partner-black {
        color: black;
    }
    a {
        color: inherit !important;
    }
}

input,
textarea,
.product-page .price-calculator-region .price-calculator-select,
.product-page .price-calculator-region .price-calculator-input {
    min-height: 42px;
}

input,
textarea,
.product-page .price-calculator-region .price-calculator-select,
.product-page .price-calculator-region .price-calculator-input,
.customize-option-title-column .Select__control {
    border: 1px solid $color-border-grey;
    border-radius: 0px;
    padding: 8px 6px;
}

.customize-option-title-column .Select__control input {
    min-height: initial;
}

input:hover,
textarea:hover,
.product-page .price-calculator-region .price-calculator-select:hover,
.product-page .price-calculator-region .price-calculator-input:hover,
.customize-option-title-column .Select__control:hover,
.customize-page .customize-content-wrapper .customize-options-column .customize-option-input:hover {
    @include media($breakpoint-large) {
        background-color: var(--wl-white, $color-text-white);
        border-color: var(--theme-color-3, $color-text-light-teal) !important;
        color: $color-text-black;
    }
}
/*input text black on hover / focus*/

@include media($breakpoint-large) {
    input:hover::-webkit-input-placeholder {
        color: #000;
    }
    input:hover::-moz-placeholder {
        color: #000;
    }
    input:hover::-ms-input-placeholder {
        color: #000;
    }
}

*:focus {
    @include focus();
}

input:focus::-webkit-input-placeholder {
    color: #000;
}
input:focus::-moz-placeholder {
    color: #000;
}
input:focus::-ms-input-placeholder {
    color: #000;
}
select.select-ctrl {
    border: 1px solid $color-lightish-grey;
    display: block;
    height: 37px;
}

input.edit-note {
    width: 100%;
}

.Select__input {
    &:focus,
    &:focus-visible {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: none;
    }
}
/*dropdown*/

.Select__menu {
    top: 42px;
    border-color: var(--theme-color-2, $color-text-light-teal);
    border-top: 0;
    border-radius: 0;
    -webkit-box-shadow: 0px 5px 5px 0px var(--theme-color-2-fade, rgba(50, 50, 50, 0.2));
    -moz-box-shadow: 0px 5px 5px 0px var(--theme-color-2-fade, rgba(50, 50, 50, 0.2));
    box-shadow: 0px 5px 5px 0px var(--theme-color-2-fade, rgba(50, 50, 50, 0.2));

    .Select__menu-list {
        .Select__option {
            &.is-selected,
            &.is-focused,
            &:hover {
                background: rgba(50, 50, 50, 0.2);
            }

            .partner &.is-selected,
            .partner &.is-focused {
                background: initial;
            }

            .partner &:hover {
                background: $color-background-light-grey4;
            }
        }
    }
}

*::placeholder,
*:-ms-input-placeholder,
*::-ms-input-placeholder {
    color: #877171;
    opacity: 1; /* Firefox */
}

.error {
    color: $color-text-red;
}

.default-dropdown {
    background-color: $color-text-white;
    color: $color-lightish-grey2;
}

.contact-us-image {
    width: 100%;
    max-height: 650px;
}

.offleft {
    position: absolute;
    left: $hide-absolute-position;
}
