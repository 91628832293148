.public-footer {
    background-color: var(--theme-color-1, $color-background-light-grey4);

    .footer-content {
        background: inherit;
    }

    li {
        list-style-type: none;
    }

    ul {
        margin-left: 0;
        padding-left: 0;
    }

    &.partner {
        .footer-summary-text {
            font-size: 16px;
            padding: 0 8px;

            .site-phone {
                color: var(--theme-color-3);
            }
        }
        .footer-end {
            padding-bottom: 20px;
        }
    }
    .footer-title {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        color: $color-text-teal;
        font-size: 34px;
        display: inline;
        @include media($breakpoint-small) {
            font-size: 26px;
        }
    }
    .footer-subtext {
        padding-left: 5px;
        font-size: 16px;
        display: inline;
        line-height: 21px;
        .footer-subtext-link {
            cursor: pointer;
            color: $color-text-black;
            text-decoration: underline;
        }
    }

    .footer-customers {
        background-color: $color-background-grey;
        padding-top: 30px;
        padding-bottom: 40px;

        .footer-customers-list {
            padding-left: 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            @include media($breakpoint-small) {
                justify-content: space-between;
            }

            .footer-customers-icon-wrapper {
                @include media($breakpoint-large) {
                    display: inline-block;
                    width: 16%;
                    margin: 0px;
                }
                @include media($breakpoint-small) {
                    @include span-columns(5);
                    margin: 0px;
                }
                text-align: center;

                .footer-customers-icon {
                    display: inline-block;
                    width: 100%;
                    height: 100%;

                    @include media($breakpoint-small) {
                        width: 100%;
                        max-width: 120px;
                        height: auto;
                    }
                }
            }
        }
    }

    .footer-social {
        background-color: transparent;

        .footer-social-icon-list {
            padding-left: 0;
            display: flex;

            .footer-social-icon {
                cursor: pointer;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin: 0 5px;
                background: transparent no-repeat scroll 50% 0;
                background-size: contain;
                padding: 0;
            }
        }

        .footer-social-title {
            color: $color-text-teal;
            font-size: 18px;
            font-weight: normal;
            font-family: 'Open Sans', sans-serif;
        }
    }
    .footer-categories {
        padding-top: 30px;
        padding-bottom: 20px;

        @include media($breakpoint-small) {
            display: none;
        }

        .footer-category-group {
            overflow: auto;
            border-bottom: 5px solid $color-text-white;
            padding-bottom: 20px;
            margin-bottom: 10px;
            line-height: 18px;
        }

        .footer-category-column {
            @include media($breakpoint-large) {
                @include span-columns(5);
                @include omega(6n);
            }
            @include media($breakpoint-small) {
                @include span-columns(15);
                @include omega(2n);
            }
        }

        .footer-category-title {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin-top: 10px;
            display: block;
            padding: 5px 8px;
            border-radius: 3px;
            color: $color-text-teal;
            &:hover {
                text-decoration: none;
                color: $color-text-teal2;
                background: $color-background-blue;
            }
        }

        .text-light-teal {
            color: $color-text-light-teal;
        }

        .footer-category-subtitle {
            font-size: 14px;
            line-height: 18px;
            display: block;
            color: $color-text-black;
            padding: 5px 8px;
            border-radius: 3px;
            &.bold {
                padding: 6px 15px;
                border-radius: 20px;
                font-weight: normal;
                background: $color-background-light-grey5;
                display: inline;
                line-height: 40px;
            }
            &:hover {
                text-decoration: none;
                color: $color-text-teal2;
                background: $color-background-blue;
            }
        }

        .footer-supercategory-group {
            width: 100%;
            overflow: auto;
            line-height: 18px;
            border-bottom: 2px solid $color-divider-dark-grey;
            padding-bottom: 30px;
        }

        .footer-supercategory-column {
            @include media($breakpoint-large) {
                @include span-columns(15);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
        }

        .footer-supercategory-subcolumn {
            @include span-columns(10);

            @include media($breakpoint-small) {
                @include span-columns(30);
            }
        }
    }
    .footer-support {
        padding-top: 10px;
        padding-bottom: 40px;
        border-bottom: 2px solid $color-divider-dark-grey;
        .footer-support-title {
            font-family: 'Open Sans', sans-serif;
            color: $color-text-teal;
            padding-bottom: 5px;
            letter-spacing: -0.02em;
            font-size: 34px;
            font-weight: normal;
            text-transform: capitalize;
            text-shadow: 0px 2px 0px $color-text-white;
            @include media($breakpoint-small) {
                font-size: 32px;
            }
        }
        .footer-support-image {
            width: 100%;
            max-width: 150px;

            @include media($breakpoint-small) {
                display: none;
            }
        }
        .footer-support-column {
            @include media($breakpoint-large) {
                @include span-columns(4);
                &:first-child {
                    @include span-columns(10);
                }
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
                padding-bottom: 10px;
            }
        }

        .footer-support-action-buttons {
            @include media($breakpoint-small) {
                @include span-columns(30);
            }

            text-align: center;
        }

        .footer-support-subtitle {
            color: $color-text-teal2;
            font-weight: bold;
            font-size: 18px;
            padding-bottom: 10px;
            padding-left: 8px;
            line-height: 24px;
            display: block;
        }
        .footer-support-text {
            font-size: 14px;
            padding: 5px 8px;
            padding-left: 0;
            border-radius: 3px;
            display: block;
            color: $color-text-black;

            &:hover {
                text-decoration: none;
                color: $color-text-teal2;
                background: transparent;
            }
        }
    }
    .footer-summary {
        padding-top: 20px;
        padding-bottom: 40px;
        border-bottom: 2px solid $color-divider-dark-grey;

        &.partner {
            padding-bottom: 0;
            border: 0;
        }
        .footer-summary-logo {
            @include row();
            width: 210px;
            height: 75px;
            padding-bottom: 10px;
            object-fit: contain;
            object-position: left;
        }
        .footer-summary-header-partner {
            padding: 10px 8px;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            color: var(--theme-color-2);

            &.partner {
                padding: 1px 8px;
            }
            .footer-sign-in {
                font-size: 16px;
                font-weight: normal;
                cursor: pointer;
                color: var(--theme-color-2);
            }
        }
        .footer-summary-column {
            margin-block-start: 1em;

            @include media($breakpoint-large) {
                @include span-columns(5);
                width: initial;
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
                width: initial;
                margin: 0px;
                margin-bottom: 10px;
            }

            &.partner {
                .footer-summary-text {
                    padding: 1px 8px;
                    font-size: 16px;
                    height: auto;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }

        .footer-summary-wide-column {
            @include media($breakpoint-large) {
                @include span-columns(20);
            }
            @include media($breakpoint-small) {
                @include span-columns(30);
            }
        }

        .footer-summary-text {
            font-size: 14px;
            padding: 5px 8px;
            border-radius: 3px;
            height: 28px;
            display: block;
            color: var(--theme-color-2, $color-text-black);

            &:hover {
                text-decoration: none;
                color: var(--theme-color-2, $color-text-teal2);
                background: $color-background-blue;
            }
        }

        .footer-summary-text-link {
            cursor: pointer;
        }

        .footer-summary-textarea {
            font-size: 14px;
            line-height: 28px;
        }
    }
    .footer-end {
        padding-top: 20px;
        padding-bottom: 60px;

        .footer-end-list {
            width: 100%;
            text-align: center;
            list-style: none;
            padding-left: 0px;
            @include display(flex);
            @include justify-content(center);
            @include align-items(center);

            @include media($breakpoint-small) {
                @include flex-wrap(wrap);
            }
        }
        .footer-end-logo {
            height: 50px;
            width: 100%;
            margin-left: 2%;
            margin-right: 2%;

            .footer-end-image {
                width: 100%;
                max-height: 50px;
            }

            @include media($breakpoint-small) {
                @include span-columns(8 of 30);
                margin-bottom: 10px;
            }
        }
        .footer-end-text-wrapper {
            padding-top: 20px;
        }
        .footer-end-text {
            font-size: 12px;
            line-height: 25px;
            text-align: center;
            color: var(--theme-color-2, $color-text-black);

            a {
                color: var(--theme-color-2, $color-text-teal);
                font-weight: bold;
                text-decoration: underline;
            }
            a:hover {
                color: var(--theme-color-2, $color-orange-dark-hover2);
            }
        }

        .cookie-settings-link {
            display: block;
            width: 100px;

            a {
                display: inline-block;
                height: 100%;
                cursor: pointer;
            }
        }

        .footer-copyrights-container {
            margin: auto;

            .adasitecompliance {
                text-align: center;
            }
        }

        &.footer-container-flex {
            display: flex;
            align-items: flex-end;
        }

        @include media($breakpoint-small) {
            &.footer-container-flex {
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .footer-teal-strip {
        background-color: $color-text-teal;
        min-height: 20px;
        height: 100%;
        width: 100%;
        max-height: 20px;
    }
}

.st_email_custom,
.st_pinterest_custom,
.st_facebook_custom,
.st_twitter_custom,
.st_googleplus_custom {
    display: none;
}
