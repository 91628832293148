.featured-categories-wrapper {
    display: flex;
    flex-direction: column;

    .featured-categories-list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;

        .featured-categories-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            @include media($breakpoint-small) {
                width: 50%;

                .featured-categories-item-image-wrapper {
                    padding: 0 5px;
                }
            }

            @include media($breakpoint-large) {
                width: 25%;
            }

            @include media($breakpoint-larger) {
                width: 16.66%;
            }

            &:hover {
                .featured-categories-item-title {
                    text-decoration: underline;
                }
            }

            .featured-categories-item-image-wrapper {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 5px;

                .featured-categories-item-image {
                    border-radius: 50%;
                    aspect-ratio: 1;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }
            }

            .featured-categories-item-title {
                color: var(--theme-color-3, $color-text-teal);
                text-decoration: none;
                font-weight: bold;
            }
        }
    }
}
