$submissions-form-font: 14px;

.submission-form-wrapper {
    display: flex;
    min-height: 100vh;
    background-color: $color-background-dark-grey;
    color: $color-text-darker-grey;
    font-size: 25px;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include media($breakpoint-small) {
        text-align: left;
    }

    .submission-form-content {
        @include border-radius(0);
        background-color: $color-background-light-grey;
        padding: 20px;
        margin: 10px;
        position: relative;

        @include media($breakpoint-small) {
            margin: 0px;
            min-height: 100vh;
        }

        .welcome-message {
            padding: 20px;

            .logo {
                width: 300px;
            }

            @include media($breakpoint-small) {
                font-size: 18px;

                .logo {
                    width: auto;
                }
            }
        }

        .submission-form-button {
            @include border-radius(0);
            padding: 15px 30px;
            margin-top: 40px;
            border: 0 solid transparent;
            font-size: $submissions-form-font;
            font-weight: 700;
            font-style: normal;
            line-height: $submissions-form-font;
            color: $color-background-white;
            background-color: var(--theme-color-3, $color-background-button-primary);
            cursor: pointer;
        }

        .card-input-wrapper,
        .submission-form-input {
            background: $color-background-white;
            border: 1px solid $color-lightish-grey;
            padding: 7px;
            font-size: $submissions-form-font;
            font-weight: 400;
            font-style: normal;
            line-height: $submissions-form-font;
            color: $color-text-darker-grey;
            box-shadow: none;
            outline: none;

            &:focus {
                box-shadow: 0 0 5px $color-shadow-black;
            }
        }

        .submission-form-header {
            .submission-form-header-content {
                display: flex;
                justify-content: space-between;
            }

            .logo-wrapper {
                height: min-content;
                margin-top: auto;
                margin-bottom: 0px;

                &.partner {
                    width: 100%;
                }

                @include media($breakpoint-small) {
                    width: 50%;
                    margin-top: 0px;
                    padding-right: 5px;
                }

                .logo {
                    width: 300px;

                    @include media($breakpoint-small) {
                        width: 100%;
                    }
                }
            }

            .info-wrapper {
                text-align: right;
                font-size: 10px;
            }
        }

        .cc-submission-form {
            max-width: 700px;
            min-width: 700px;
            padding: 20px 0px;

            @include media($breakpoint-small) {
                max-width: 100vw;
                min-width: 100vw;
                padding: 20px;
            }

            .section-error {
                width: 100%;
                color: red;
                border: 1px solid red;
                background-color: rgba($color: red, $alpha: 0.15);
                display: inline-block;
                margin: 0 0 20px 0;
                padding: 10px;
                font-size: $submissions-form-font;

                p {
                    margin: 0px;
                    text-align: center;

                    .bold {
                        font-weight: bold;
                    }
                }
            }

            .section-header {
                h2 {
                    margin-bottom: 10px;
                    text-align: center;
                    font-size: 25px;
                    font-weight: 700;
                    font-style: normal;
                    line-height: 25px;
                    color: var(--theme-color-3, $color-text-teal);
                }
            }

            .section {
                .section-row {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    padding: 10px 5px;

                    @include media($breakpoint-small) {
                        margin-bottom: 10px;
                        padding: 0px;
                    }

                    &.error {
                        .section-row-header {
                            color: red;
                        }

                        border: 1px solid red;
                        background-color: rgba($color: red, $alpha: 0.15);
                    }

                    &.section-subheader {
                        font-size: 16px;
                        text-align: left;
                    }

                    .section-row-header {
                        font-size: 21px;
                        text-align: left;
                        margin-bottom: 10px;
                        color: $color-text-darker-grey;
                    }

                    .section-row-input {
                        display: flex;
                        justify-content: center;

                        @include media($breakpoint-small) {
                            flex-direction: column;
                        }
                    }

                    .section-colum-input {
                        display: flex;
                        flex-direction: column;

                        .submission-form-input-container {
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }

            .submission-form-buttons-row {
                display: flex;
                justify-content: space-between;

                .space {
                    flex-grow: 1;
                }
            }

            .progress-bar {
                width: 100%;
                height: 14px;
                position: relative;
                background-color: $color-background-light-grey5;
                margin-top: 20px;

                .progress-indicator {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $color-background-darker-grey;
                }

                .progress-text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    display: inline-block;
                    font-size: 7px;
                    margin: 0px;
                    line-height: $submissions-form-font;
                    color: $color-text-darker-grey;
                }
            }

            .successful-message {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 50px;

                .icon-wrapper {
                    background-color: $color-text-darker-grey;
                    border-radius: 50%;
                    margin: auto;
                    padding: 10px;

                    .icon-check {
                        @include check-mark-icon();
                        width: 40px;
                        height: 40px;
                        background-color: $color-background-white;
                    }
                }

                h2 {
                    text-align: center;
                    font-size: 35px;
                    font-weight: 700;
                    font-style: normal;
                    line-height: 50px;
                    color: var(--theme-color-3, $color-text-teal);
                    margin: 10px;
                }

                p {
                    font-size: 25px;
                    margin: 0px;

                    @include media($breakpoint-small) {
                        font-size: 18px;
                        text-align: center;
                    }
                }
            }
        }

        .submission-form-input-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: left;
            color: $color-text-darker-grey;

            @include media($breakpoint-small) {
                margin-bottom: 10px;
            }

            &:first-child {
                margin-right: 10px;
            }

            &.radio {
                display: flex;
                flex-direction: row;

                @include media($breakpoint-small) {
                    flex-direction: column;
                }

                .radio-wrapper {
                    width: fit-content;
                    margin-right: 20px;
                    font-size: $submissions-form-font;
                    line-height: 28px;

                    @include media($breakpoint-small) {
                        margin: 5px 0px;
                        border: 1px solid $color-lightish-grey;
                        width: 100%;
                        padding: 5px;
                        cursor: pointer;
                        display: flex;
                    }

                    input[type='radio'] {
                        width: 14px;
                        height: 14px;
                        min-height: fit-content;
                        margin: 0px 5px;

                        @include media($breakpoint-small) {
                            margin: auto 5px;
                        }
                    }
                }
            }

            &.checkbox {
                .submission-form-label {
                    text-align: left;
                    font-size: $submissions-form-font;
                    line-height: 28px;

                    @include media($breakpoint-small) {
                        border: 1px solid $color-lightish-grey;
                        padding: 5px;
                    }

                    .submission-form-input {
                        min-height: 14px;
                    }
                }
            }

            &.dropdown {
                .submission-form-input {
                    min-height: 42px;

                    &:hover {
                        background-color: var(--wl-white, $color-background-light-yellow);
                        border-color: var(--theme-color-3, $color-text-light-teal) !important;
                        color: #000;
                    }
                }
            }

            &.card {
                .card-input-wrapper {
                    display: flex;
                    flex-direction: row;
                    padding: 0px;

                    .card-input-icon {
                        width: 25px;
                        height: 15px;
                        margin: auto 5px;

                        img {
                            width: 100%;
                        }
                    }

                    .card-input-container {
                        padding: 0px;
                        width: 100%;

                        .number-input {
                            width: 60%;
                        }

                        .date-input,
                        .cvc-input {
                            width: 20%;
                        }

                        input {
                            background: transparent;
                            border: none;
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }
            }

            .submission-form-label {
                width: fit-content;
                margin-top: 2px;
                font-size: 12px;
                font-weight: 400;
                font-style: normal;
                line-height: 12px;
            }
        }

        .input-address {
            display: flex;
            flex-direction: row;

            @include media($breakpoint-small) {
                flex-direction: column;
            }

            .submission-form-input-container {
                width: auto;
                margin: 0px;

                &:nth-child(1) {
                    width: 60%;
                    padding-right: 10px;
                }

                &:nth-child(2) {
                    width: 25%;
                    padding-right: 10px;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                @include media($breakpoint-small) {
                    width: 100%;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        width: 100%;
                        padding: 0px;
                    }
                }
            }
        }
    }
}
