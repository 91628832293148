.home {
    background: inherit;

    h2 {
        font-weight: bold;
    }
    .home-title {
        font-family: 'Open Sans', sans-serif;
        font-size: 34px;
        color: var(--theme-color-3, $color-text-teal);
        display: inline-block;
        font-weight: normal;
        margin: 10px 0;

        @include media($breakpoint-small) {
            font-size: 26px;
        }
    }

    h1.home-title {
        position: absolute;
        left: -9999px;
    }

    .home-subtext {
        font-size: 16px;
        padding-left: 5px;
        display: inline-block;
    }
    .home-subtitle {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.015em;
    }
    .home-text {
        font-size: 16px;
        line-height: 28px;
    }
    .home-service-wrapper {
        padding-top: 30px;
        padding-bottom: 40px;
        .home-service-content-wrapper {
            @include row();
            padding-top: 10px;

            .home-service-column {
                text-align: center;
                @include media($breakpoint-large) {
                    @include span-columns(10);
                }
                @include media($breakpoint-small) {
                    @include span-columns(30);
                }

                &.reviews {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .image-wrapper {
                        width: 100%;
                        aspect-ratio: 3.12;
                        padding-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        font-style: italic;
                        font-size: 25px;
                        text-align: start;
                    }
                }
            }
        }
        .home-service-text-wrapper {
            padding-bottom: 20px;
            text-align: left;

            .home-text {
                display: flex;
                .home-service-item-icon {
                    height: 85px;
                    aspect-ratio: 1;
                    background-color: $color-yellow;

                    @include mask-image-without-url();
                }
            }
        }
    }
    .home-learnmore-wrapper {
        @include row();
        @include media($breakpoint-large) {
            display: table;
        }
        width: 100%;
        border-spacing: 10px;
        padding-bottom: 40px;
        .home-learnmore-column {
            padding: 15px 15px 20px 15px;
            background-color: $color-background-blue;
            background: -moz-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
            background: -webkit-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
            background: -o-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
            background: -ms-linear-gradient(90deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
            background: linear-gradient(180deg, rgb(216, 241, 244) 30%, rgb(227, 250, 252) 70%);
            margin-bottom: 30px;
            border-radius: 8px;
            @include media($breakpoint-large) {
                width: 33.3333333%;
                display: table-cell;
            }
        }

        .home-learnmore-subtitle {
            font-family: 'Open Sans', sans-serif;
            font-size: 24px;
            font-weight: normal;
            color: $color-text-teal;
            padding-bottom: 20px;
        }

        .home-learnmore-button {
            background-color: $color-background-blue;
            color: $color-text-teal;
            border: 1px solid $color-text-teal;
            margin-top: 40px;
            margin-bottom: 0;
            padding-left: 20px;
            padding-right: 20px;
            display: inline-block;
            cursor: pointer;
            text-decoration: none;
        }
        .home-learnmore-button:hover {
            @include media($breakpoint-large) {
                background-color: $color-text-teal;
                color: $color-text-white;
            }
        }
    }
}

.home-herobanner-wrapper {
    width: 100%;
    margin-top: 20px;
    a,
    a:hover {
        text-decoration: none;
        display: inline-block;
    }

    .carousel {
        .slick-list {
            width: 100%;
        }
    }
    .home-herobanner-item-wrapper {
        .home-herobanner-item {
            display: inline-block;
            aspect-ratio: 4;
            position: relative;
            width: 100%;

            .home-herobanner-item-image-wrapper {
                display: inline-block;
                width: 100%;
                height: 100%;

                .home-herobanner-item-image {
                    width: 100%;
                    height: 100%;

                    &.desktop {
                        display: block;
                        aspect-ratio: 4;
                    }

                    &.mobile {
                        display: none;
                        aspect-ratio: 2;
                    }

                    @include media($breakpoint-small) {
                        &.desktop {
                            display: none;
                        }

                        &.mobile {
                            display: block;
                        }
                    }
                }

                @include media($breakpoint-small) {
                    height: initial;
                }
            }

            .home-herobanner-item-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 30%;
                height: 100%;
                padding: 50px 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &.right {
                    left: initial;
                    right: 0;
                }

                .home-herobanner-item-title-wrapper {
                    .home-herobanner-item-title {
                        text-align: center;
                        font-size: 30px;
                        color: var(--titleColor);
                        margin: 0;
                        font-weight: bold;
                        white-space: pre-line;
                    }
                }
                .home-herobanner-item-description {
                    color: var(--descriptionColor);
                    font-size: 14px;
                    white-space: pre-line;
                    text-align: center;
                }

                .home-herobanner-item-button {
                    display: inline-block;
                    color: var(--buttonTextColor);
                    background-color: var(--buttonColor);
                    border-color: var(--buttonBorderColor);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 4px;
                    outline: none;
                    min-height: 42px;
                    padding: 8px 15px;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: 'OpenSans', 'Open Sans', sans-serif;
                    margin-top: 10px;

                    &:hover {
                        color: var(--buttonHoverTextColor);
                        background-color: var(--buttonHoverColor);
                        border-color: var(--buttonHoverBorderColor);
                    }
                }

                @include media($breakpoint-small) {
                    position: initial;
                    width: 100%;
                    padding: 20px 10px;
                    background-color: var(--contentMobileBackgroundColor);
                }
            }

            @include media($breakpoint-small) {
                aspect-ratio: 1.22;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
