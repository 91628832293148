$color-primary: #00758b;
$color-primary-dark: #0b6274;
$color-secondary: #d3d3d3;
$color-secondary-dark: #b1b1b1;
$color-secondary-darker: #4d4d4d;
$color-white: #ffffff;
$color-black: #000;
$color-yellow: #f6b11b;

$color-background-blue: #d8f1f4;
$color-background-seafoam-shade: #cee5ec;
$color-background-seafoam-shade-border: #a0ced8;
$color-background-dark-blue: #344e75;
$color-background-darker-blue: #1f3d56;
$color-background-dark-grey: #e5e5e5;
$color-background-darker-grey: #b1b1b1;
$color-background-grey: #ededed;
$color-background-grey2: #e1e1e1;
$color-lightish-grey: #ccc;
$color-lightish-grey2: #aaa;
$color-background-input: #fdfdf4;
$color-background-light-grey: #f8f8f8;
$color-background-lighter-grey: #fdfdfd;
$color-background-light-grey2: #f1f1f1;
$color-background-light-grey3: #efefef;
$color-background-light-grey4: #f4f4f4;
$color-background-light-grey5: #eaeaea;
$color-background-light-red: #ffcccf;
$color-background-orange: #f2b116;
$color-background-teal-dark: #008293;
$color-background-teal-darker: #008d9e;
$color-background-teal-darkest: #008393;
$color-background-teal-darkest-forgot-password: #007e8e;
$color-background-teal-0: #007782;
$color-background-teal: #ebf8f9;
$color-background-white: #ffffff;
$color-background-yellow-orange: #ecd66d;
$color-background-light-yellow: #fffbee;
$color-background-yellow: #f9f7d5;
$color-background-pink: #eccdcc;
$color-background-button-primary: #00758a;
$color-divider-grey: #e7e7e7;
$color-divider-dark-grey: #d0d0d0;

$color-button-background: #e03400;
$color-button-background-secondary: #dd6427;
$color-button-background-third: #d14c11;
$color-highlight-orange: #ff612b;
$color-highlight-hover-orange: #ff7546;
$color-highlight-light-orange: #f47722;
$color-highlight-dark-orange: #da3c06;

$color-highlight-teal: #92c7d0;
$color-text-darker-grey: #4d4d4d;
$color-text-darkish-grey: #767676;
$color-text-dark-grey: #808080;
$color-text-grey: #6b6b6b;
$color-text-light-grey: #b2b2b2;
$color-text-light-grey2: #a3a3a3;
$color-border-grey: #767676;
$color-text-white: #ffffff;
$color-text-teal: #00758b;
$color-text-teal2: #007682;
$color-text-teal3: #058096;
$color-text-light-teal: #3bb7c6;
$color-bg-blue-lighten: #cee5ec;
$color-text-blue-darken: #008392;
$color-blue-darken: #17a0af;

$color-calendar: #0b6274;

$color-text-maroon: #600a3e;
$color-text-red: #b8071e;
$color-text-green: #009900;
$color-text-black: #000000;
$color-text-teal-white: #e2f4f6;
$color-text-dark-red: #a00200;
$color-text-off-black: #333333;
$color-text-orange: #e03400;
$color-text-orange2: #dd3100;
$color-orange-dark-hover: #e46a28;
$color-orange-dark-hover2: #d72b00;

$color-header-background: #fcfcfc;
$color-header-divider: #d3d3d3;

$color-button-golden: #f0c24d;
$color-button-golden-lighter: #f6db9a;
$color-button-golden-lightest: #fbecc8;
$color-button-golden-darken: #a88734;

$color-shadow-black: rgba(
    $color: #000,
    $alpha: 0.2
);

$color-focus: rgba(0, 109, 220, 0.5);
/* set large values because there are third-party styles with a very large z-index */
$z-index-up: 9999;
$z-index-upper: 99999;
$z-index-upperest: 9999999;
$z-index-header: 100000;
$hide-absolute-position: -9999px;
$hamburger-menu-header-heigth: 50px;
$max-heigth-cart-items-section: 1550px;
$color-customer-review-info: #a89b90;
$color-customer-review-text: #424e5c;
$color-shadow: rgba(56, 56, 56, 0.13);
$color-live-chat-dark-blue: #14295e;
$color-live-chat-golden: #ab8d2c;
