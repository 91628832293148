.awesome-design-guarantee-container {
    display: flex;
    width: 100%;
    padding: 8px;
    align-items: center;

    &.border {
        border: 5px solid $color-divider-grey;
    }

    .awesome-design-guarantee-image-wrapper {
        .image {
            height: 150;
        }
    }

    .awesome-design-guarantee-text-wrapper {
        margin-left: 12px;

        .awesome-design-guarantee-button {
            text-decoration: underline;
            cursor: pointer;
        }

        .awesome-design-guarantee-text {
            margin-top: 0px;

            &.no-bottom-margin {
                margin-bottom: 0px;
            }

            .bold {
                font-weight: bold;
            }
        }
    }
}
