.company-store {
    .clear {
        clear: both;
    }
    margin-bottom: 110px;
    p {
        line-height: 28px;
    }
    .half {
        @include media($breakpoint-large) {
            @include span-columns(14);
        }
        @include media($breakpoint-small) {
            @include span-columns(30);
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    .half.last {
        float: right;
    }

    .btn-submit {
        float: right;
    }
    .email-submitted {
        font-family: 'Open Sans', sans-serif;
        color: $color-text-light-teal;
        font-size: 24px;
    }
    .img-company-store {
        width: 100%;
        margin-top: 42px;
        margin-bottom: 28px;
    }
    .company-store-offers {
        display: flex;
        width: 100%;

        @include media($breakpoint-small) {
            display: block;
        }
    }
    .third {
        @include span-columns(4 of 12);

        @include media($breakpoint-small) {
            @include span-columns(12 of 12);
        }

        &.fourth {
            @include span-columns(3 of 12);
        }
        img {
            width: 80%;
            margin: 34px 0px;
        }
        text-align: center;
        .title {
            font-size: 28px;
        }
        .gray {
            color: #808080;
            p {
                margin: 0px 0px;
            }
        }
        .gray:last-child {
            clear: right;
        }
        .benefits {
            color: #808080;
            font-weight: bold;
        }
    }

    .radio {
        float: left;
        margin-right: 24px;
        margin-top: 12px;

        input {
            -ms-transform: scale(1.5); /* IE 9 */
            -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
            transform: scale(1.5);
            margin-right: 0.9em;
        }
    }

    .radio input {
        float: left;
    }

    .radio label {
        float: left;
        margin-top: 15px;
    }

    .label {
        font-weight: bold;
    }

    .row {
        margin-bottom: 20px;
    }
    .blank-row {
        height: 54px;
    }
    .title {
        font-size: 34px;
        color: var(--theme-color-3, $color-text-teal);
        font-family: 'Open Sans', sans-serif;
        font-weight: lighter;
        margin-top: 28px;

        @include media($breakpoint-small) {
            font-size: 26px;
        }
    }

    .company-store-in-place,
    .special-offers,
    .company-store-solution {
        padding-top: 16px;
    }
    .title.large {
        font-size: 58px;
        text-align: center;
        margin-top: 24px;

        &.left {
            text-align: left;
        }
    }
    .section-title {
        color: var(--wl-dark, $color-text-light-teal);
        font-size: 16px;
        margin: 24px 0px;
    }
    .center {
        text-align: center;
    }
    .text-region {
        overflow: auto;
        .subtitle {
            font-size: 18px;
            color: var(--theme-color-2, $color-text-light-teal);
            font-weight: normal;
            margin-bottom: 0px;
            font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
        }
        .subtitle.dark {
            color: $color-text-teal;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
        }
        .subtitle.large {
            font-size: 28px;
        }
        .text {
            font-size: 14px;
            line-height: 24px;
            .teal {
                color: var(--wl-input-border, $color-text-teal);
                font-weight: bold;
            }
            strong {
                text-transform: uppercase;
            }
        }
        .m-t-0 {
            margin-top: 0;
        }
    }
    .callout {
        position: relative;
        padding: 20px;
        margin-bottom: 20px;
        min-height: 230px;
        .callout-title {
            font-size: 26px;
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            color: var(--theme-color-2, $color-text-light-teal);
            margin: 0px;
        }
        .callout-error {
            color: $color-text-red;
        }
        .callout-text {
            font-size: 14px;
            line-height: 21px;
        }
        .text {
            font-size: 14px;
            line-height: 21px;
            .teal {
                color: var(--theme-color-2, $color-text-teal);
                font-weight: bold;
            }
            strong {
                text-transform: uppercase;
            }
        }
    }
    .yellow-background {
        margin: 12px 0px;
        padding: 16px;
        background-color: var(--wl-grey, $color-background-yellow);
        border-radius: 8px;
        .button {
            margin-top: 10px;
            border: 1px solid;
            border-color: var(--theme-color-3, $color-text-light-teal);
            color: var(--wl-white, $color-text-light-teal);
            background-color: var(--theme-color-3, $color-background-yellow);

            &:hover {
                background: var(--theme-color-3-fade, darken($color-text-light-teal, 10%));
            }
        }
        .button:hover {
            @include media($breakpoint-large) {
                color: var(--wl-white, $color-text-white);
                background-color: var(--theme-color-3-fade, $color-text-light-teal);
            }
            text-decoration: none;
        }
        a.button {
            display: inline-block;
        }
        .red {
            color: var(--theme-color-2, $color-text-red);
        }
        .green {
            color: var(--theme-color-1, $color-text-green);
        }
    }
    .call {
        color: var(--theme-color-3, $color-text-teal);
    }
    .grey-background {
        border-radius: 8px;
        background: none;
        border: 1px solid $color-lightish-grey;
        .row:first-child {
            margin-bottom: 70px;
        }
        a.orange-button {
            width: 150px;
            min-width: 210px;
        }
        iframe {
            border: 2px solid $color-background-grey !important; /* imporant because competing with third party scripts styles */
            padding: 5px;
            margin-bottom: 20px;
        }
    }
    .teal-background {
        background-color: var(--theme-color-2-fade, $color-background-teal);
    }
    .edit-input-row {
        .edit-input {
            border: 1px solid var(--wl-input-border, $color-text-light-teal);
            font-size: 14px;
            border-radius: 3px;
            padding: 8px;
            margin: 5px 0px;
            width: 95%;
            outline: none;
        }
        textarea {
            min-height: 91px;
        }
        select.edit-input {
            height: 44px;

            &:hover {
                border-color: var(--theme-color-2, $color-text-light-teal);
            }
        }
        .Select {
            margin-top: 8px;
            margin-bottom: 5px;
        }
        .Select__placeholder {
            line-height: 44px;
        }
        .Select__control {
            margin-top: 10px;
            border: 1px solid $color-text-light-teal;
            font-size: 14px;
            border-radius: 3px;
            margin: 5px 0px;
            width: 85%;
            outline: none;
            height: 44px;
        }
        .edit-input-area {
            @include calc(width, '100% - 20px');
            margin-top: 10px;
            border: 1px solid var(--theme-color-1, $color-text-light-teal);
            font-size: 14px;
            border-radius: 3px;
            padding: 8px;
            margin: 5px 0px;
            outline: none;
        }
    }
    .edit-input-row.flex {
        display: flex;
        .edit-input-required {
            margin-top: 10px;
        }
    }

    .edit-input-row.phone {
        float: left;
        width: 68%;
        .edit-input {
            width: 94%;
        }
    }

    .edit-input-row.ext {
        float: left;
        clear: right;
    }

    .edit-input-required {
        color: $color-text-red;
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    .edit-required-text {
        font-size: 10px;
        color: $color-text-grey;
        display: inline-block;
        padding-right: 10px;
    }
    .edit-required-label {
        position: absolute;
        bottom: 30px;
        right: 20px;
    }

    .edit-dropdown {
        height: 33px;
        background-color: white;
        color: $color-text-light-grey;
    }

    &.no-margin {
        margin-bottom: 0px;
    }
}

.contact-us-title {
    margin-bottom: 30px !important;
}

.edit-input {
    border: 1px solid var(--wl-input-border, $color-text-light-teal);
    font-size: 14px;
    border-radius: 3px;
    padding: 8px;
    margin: 5px 0px;
    width: 50%;
    outline: none;
}
.edit-dropdown {
    height: 33px;
    background-color: white;
    color: $color-text-light-grey;

    &.selected {
        color: $color-text-black;
    }
}
