.explorer {
    display: flex;
    border: 1px solid $color-background-darker-grey;
    border-radius: 5px;
    width: 100%;

    @include media($breakpoint-small) {
        .side-explorer {
            display: none;
        }
    }

    .side-explorer {
        min-width: 200px;
        max-width: 200px;
        border-right: 1px solid $color-background-darker-grey;
        padding: 10px 5px;
        overflow: auto;
        width: 200px;

        .side-explorer-item {
            cursor: pointer;
            width: max-content;
            height: max-content;

            .side-explorer-item-title {
                display: flex;

                .side-explorer-item-title-icon {
                    width: 20px;
                }

                .side-explorer-item-title-icon,
                .side-explorer-item-title-text {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }

            .side-explorer-item-children {
                padding-left: 20px;
            }
        }
    }

    .explorer-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .explorer-toolbar {
            display: flex;
            padding: 5px;

            .explorer-toolbar-item {
                cursor: pointer;
                border: 1px solid $color-background-white;
                border-radius: 5px;
                padding: 5px;

                &:hover {
                    border-color: $color-background-darker-grey;
                }

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        .folder-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-height: 200px;

            .folder-content-path {
                display: flex;
                padding: 5px;
                border-bottom: 1px solid $color-background-darker-grey;
                border-top: 1px solid $color-background-darker-grey;

                .folder-content-path-item {
                    padding: 5px;
                    cursor: pointer;
                    border: 1px solid $color-background-white;
                    border-radius: 5px;
                    margin: 0px 5px;
                    display: inline-block;

                    &:hover {
                        border-color: $color-background-darker-grey;
                    }
                }
            }

            .folder-content-wrapper {
                max-height: 390px;
                min-height: 390px;
                overflow-y: auto;

                @include media($breakpoint-small) {
                    max-height: 300px;
                    min-height: 300px;
                }

                .explorer-folder,
                .explorer-image {
                    width: 150px;
                    height: 150px;
                    padding: 10px;
                    position: relative;

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;

                        * {
                            margin: auto;
                        }
                    }
                }

                .images,
                .folders {
                    display: flex;
                    flex-wrap: wrap;
                }

                .folders {
                    .explorer-folder {
                        img {
                            width: 110px;
                            height: 110px;
                        }
                    }
                }

                .images {
                    flex-grow: 1;

                    .explorer-image {
                        img {
                            border: 1px solid $color-background-darker-grey;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        .menu {
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            display: none;
                            flex-direction: row;
                            background: $color-background-white;
                            padding: 5px;
                            width: calc(100% - 10px);

                            .menu-item {
                                width: 15px;
                                height: 15px;
                                min-height: 15px;
                            }
                        }

                        &.select-mode,
                        &:hover {
                            .menu {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}
