.printing {
    h1:not(.title) {
        display: none;
    }

    p {
        color: $color-text-off-black !important;
        clear: right;
        margin-bottom: 30px;
    }

    .catprodlist {
        border-top: 1px solid $color-background-dark-grey;
    }

    .top {
        h3 {
            display: none;
        }

        ul {
            @include display(flex);
            @include flex-wrap(wrap);
            padding-left: 0px;
            list-style-type: none;
            @include outer-container();

            li {
                @include span-columns(10, table);
                line-height: 40px;

                a {
                    color: $color-text-off-black !important;
                    text-decoration: none !important;
                    background-color: transparent !important;
                    padding: 0 !important;

                    &:hover {
                        color: $color-highlight-hover-orange !important;
                        text-decoration: underline !important;
                    }
                }

                @include media($breakpoint-small) {
                    @include span-columns(15, table);
                }
            }
        }
    }

    .breadcrumbs {
        display: none;
    }

    .product_selection {
        @include outer-container();
        @include display(flex);
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-wrap(wrap);

        @include media($breakpoint-small) {
            @include flex-direction(column);
        }

        p {
            @include span-columns(20);
            padding-right: 0px;
            @include order(-1);

            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            line-height: 30px;

            @include media($breakpoint-small) {
                @include span-columns(30);
            }
        }

        img {
        }

        .view_selection {
            @include order(3);
            width: 100%;

            a {
                border-radius: 4px;
                padding: 10px 15px;
                border: 1px solid $color-highlight-light-orange;
                color: $color-highlight-light-orange;

                &:hover {
                    background-color: $color-highlight-light-orange;
                    color: $color-text-white;
                    text-decoration: none;
                }
            }
        }
    }

    .productgroup {
        margin-top: 15px;
        tbody {
            @include display(flex);
            @include flex-direction(column);

            tr {
                @include span-columns(25);
                padding: 15px 0px;
                border-bottom: 1px solid $color-background-dark-grey;

                @include media($breakpoint-small) {
                    @include span-columns(30);
                    @include display(flex);
                    @include align-items(center);
                    @include flex-direction(column);
                }

                @include display(flex);
                @include align-items(center);

                td:first-child {
                    @include span-columns(5 of 25);

                    @include media($breakpoint-small) {
                        @include span-columns(30);
                    }

                    img {
                        max-width: 100%;
                    }
                }

                td:not(:first-child) {
                    @include span-columns(20 of 25);

                    @include media($breakpoint-small) {
                        @include span-columns(30);
                    }

                    p {
                        margin-bottom: 10px;
                        margin-top: 0px;
                    }

                    a {
                        border-radius: 2px;
                        background-color: $color-text-teal-white;
                        padding: 5px;
                        margin-bottom: 10px;
                        margin-right: 5px;
                        display: inline-block;
                        color: $color-text-light-teal;

                        &:hover {
                            background-color: $color-text-light-teal;
                            color: $color-text-white;
                        }

                        text-decoration: none !important;
                    }

                    h3 {
                        display: block;
                        margin-bottom: 0px;

                        a {
                            padding: 0px;
                            background-color: transparent;
                            font-size: 24px !important;
                            color: $color-text-teal !important;
                            font-family: 'Open Sans', sans-serif;
                            margin-bottom: 0px;
                            font-weight: lighter !important;

                            &:hover {
                                background-color: transparent !important;
                                color: $color-text-teal !important;
                            }
                        }
                    }

                    div {
                        p {
                            color: $color-text-grey !important;
                        }

                        .result_price {
                            color: $color-text-dark-red;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }

                    .btn_grey_grid {
                        a {
                            padding: 10px 15px;
                            color: $color-text-white;
                            background-color: $color-highlight-light-orange;
                            border-radius: 4px;
                            font-family: 'Open Sans', sans-serif;

                            &:hover {
                                background-color: $color-orange-dark-hover;
                            }

                            &::after {
                                margin-left: 5px;
                                font-size: 10px;
                                content: '▶';
                            }
                        }
                    }
                }
            }
        }
    }

    .printing-map {
        p {
            padding-right: 50%;

            @include media($breakpoint-small) {
                padding-right: 0px;
            }
        }

        h3 {
            display: none !important;
        }

        tr {
            width: 100% !important;

            td:first-child {
                display: none !important;
            }

            td:not(:first-child) {
                width: 100% !important;
            }
        }
    }
}
